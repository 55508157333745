import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {

    return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-20 inset-0 overflow-hidden" onClose={() => props.onClose()}>
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />
  
            <div onClick={() => props.onClose()} className={`fixed inset-y-0 ${props.side === 'left' ? 'left-0 pr-10' : 'right-0 pl-10'} max-w-full flex`}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom={props.side === 'left' ? '-translate-x-full' : 'translate-x-full'}
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo={props.side === 'left' ? '-translate-x-full' : 'translate-x-full'}
              >
                  <div onClick={ev => ev.stopPropagation()} className={`${props.side === 'left' ? 'rounded-r-xl' : 'rounded-l-xl'} bg-white bg-opacity-95 w-96 overflow-y-auto shadow-xlhv`}>
                      <div className="flex flex-col overflow-y-auto h-screen px-6 pt-6 space-y-6">
                          <div className="flex-shrink-0">
                              <div className="flex items-start justify-between">
                                    <Dialog.Title className="text-xl font-medium text-gray-900">{props.title}</Dialog.Title>
                                    <div className="ml-3 h-7 flex items-center">
                                        <button
                                            type="button"
                                            className="pointer-events-auto rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={() => props.onClose()}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <FontAwesomeIcon icon={['fas', 'times']} size="lg" className="h-6 w-6" aria-hidden="true" />                            
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {props.children}
                      </div>
                  </div>
                {/* <div className="w-96">
                    <div onClick={ev => ev.stopPropagation()} className={`${props.side === 'left' ? 'rounded-r-lg' : 'rounded-l-lg'} pointer-events-none h-full flex flex-col py-6 bg-white bg-opacity-95 shadow-xlhv overflow-y-scroll`}>
                        <div className="px-4 sm:px-6">
                            <div className="flex items-start justify-between">
                                <Dialog.Title className="text-lg font-medium text-gray-900">{props.title}</Dialog.Title>
                                <div className="ml-3 h-7 flex items-center">
                                    <button
                                        type="button"
                                        className="pointer-events-auto rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={() => props.onClose()}
                                    >
                                        <span className="sr-only">Close panel</span>
                                        <FontAwesomeIcon icon={['fas', 'times']} size="lg" className="h-6 w-6" aria-hidden="true" />                            
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 relative flex-1 px-4 sm:px-6 pointer-events-auto">
                            {props.children}
                        </div>
                    </div>
                </div> */}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }