import React from 'react';
import Product from './Product';

export default (props) => {
    if (!props.items) return null;

    return (
        <div id="productlist" className="overflow-x-scroll overflow-y-hidden px-4">
            <div className="flex flex-cols">
                <ul role="list" className="flex space-x-4">
                    {props.items.map(item => {
                        return (
                            <li key={item.pid} onClick={() => props.onClick && item !== props.selectedItem && props.onClick(item)}>
                                <div className="flex h-16">
                                    <Product item={item} />
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div className="p-2">
                    {/* Adds space to the right of the last item */}
                </div>
            </div>
        </div>
    );
}

