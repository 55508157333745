import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {

    return (
        <>
            {props.items && props.items.length > 3 &&
                <div className="flex justify-end mb-5">
                    <button
                        type="button"
                        onClick={(ev) => { props.onCreateClicked(); ev.stopPropagation(); }}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} size="lg" className="mr-2" />
                        Add product
                    </button>
                </div>
            }
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul role="list" className="divide-y divide-gray-200">
                    {props.items && [...props.items].sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})).map((product) => (
                        <li key={product.id}>



                            <div onClick={(ev) => { props.onSelected(product); ev.stopPropagation(); }} className={`cursor-pointer block hover:bg-gray-50 px-4 py-4 flex items-center sm:px-6 ${props.selected?.id === product.id ? "bg-blue-50" : ""}`}>
                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div className="flex items-center mr-5">
                                        {product.existingImageUrl && 
                                            <img className="w-12 h-12" src={`/render/image?url=${product.existingImageUrl}&width=300`} alt="" />
                                        }
                                        {!product.existingImageUrl && 
                                            <div className="w-12 h-12 flex items-center justify-center">
                                                <FontAwesomeIcon icon={['fas', 'box-open']} size="lg" className="text-gray-300" />
                                            </div>
                                        }
                                        <div className="">
                                            <div className="text-sm">
                                                <p className="font-medium text-indigo-600 ml-5">{product.name?.length > 0 ? product.name : '(Untitled product)'}</p>
                                                {product.comments && product.comments !== '' &&
                                                    <p className="font-normal text-gray-500">{product.comments}</p>
                                                }
                                            </div>
                                            {/* <div className="mt-2 flex">
                                                <div className="flex items-center text-sm text-gray-500">
                                                    <p>
                                                        {instance.clients.length} Client{instance.clients.length === 1 ? '' : 's'}
                                                    </p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                        <div className="flex overflow-hidden -space-x-1">
                                            <button
                                                type="button"
                                                onClick={(ev) => { props.onEditClicked(product); ev.stopPropagation(); }}
                                                className="inline-flex justify-center py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 hover:text-white hover:bg-indigo-700 focus:outline-none"
                                            >
                                                <FontAwesomeIcon icon={['fas', 'pencil-alt']} size="lg" className="mr-2" />
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="ml-5 flex-shrink-0">
                                    <FontAwesomeIcon icon={['fas', 'chevron-right']} size="lg" className="text-gray-300" />
                                </div>
                            </div>                            
                        
                    </li>
                    ))}

                    {props.items && props.items.length === 0 &&
                        <li>
                            <div className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-center">
                                    <p className="text-sm font-light italic">
                                        No products created yet. Click Add product to create one. 
                                    </p>
                                </div>
                            </div>
                        </li>                    
                    }

                    {!props.items &&
                        <li>
                            <div className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-center">
                                    <p className="text-sm font-light italic">
                                        <FontAwesomeIcon icon={['fas', 'spinner']} spin size="lg" className="mr-2" />                                        
                                        Loading... 
                                    </p>
                                </div>
                            </div>
                        </li>                    
                    }


                </ul>
            </div>
            
            <div className="flex justify-end mt-5">
                {props.items &&
                    <button
                        type="button"
                        onClick={(ev) => { props.onCreateClicked(); ev.stopPropagation(); }}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} size="lg" className="mr-2" />
                        Add product
                    </button>
                }
            </div>
        </>
    )
}
