/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useState, useEffect, useReducer, Fragment } from 'react';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBar from './Navbar';

import InstanceList from './InstanceList';
import InstanceEditForm from './InstanceEditForm';

import ClientList from './ClientList';
import ClientDetails from './ClientDetails';
import ClientEditForm from './ClientEditForm';

import ClientGroupList from './ClientGroupList';
import ClientGroupEditForm from './ClientGroupEditForm';

import ClipList from './ClipList';
import ClipDetails from './ClipDetails';
import ClipEditForm from './ClipEditForm';

import ProductList from './ProductList';
import ProductDetails from './ProductDetails';
import ProductEditForm from './ProductEditForm';

import PlaylistList from './PlaylistList';
import PlaylistDetails from './PlaylistDetails';
import PlaylistEditForm from './PlaylistEditForm';

import Modal from './Modal';

import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import _ from "lodash";

import Utils from '../Utils/Utils';
import PlayerUtils from '../Utils/PlayerUtils';
import { v4 as uuidv4 } from 'uuid';

const dashboardSection = { name: 'Dashboard', icon: ['fas', 'home'] };
const instancesSection = { name: 'Instances', icon: ['fas', 'clone'] };
const clientsSection = { name: 'Clients', icon: ['fas', 'users'] };
const clientGroupsSection = { name: 'Client Groups', icon: ['fas', 'users'] };
const clientDetailsSection = { name: 'Client details', icon: ['fas', 'user'] };
const clipsSection = { name: 'Videos', icon: ['fas', 'video'] };
const playlistsSection = { name: 'Playlists', icon: ['fas', 'list'] };
const playlistDetailsSection = { name: 'Playlist details', icon: ['fas', 'list'] };
const productsSection = { name: 'Products', icon: ['fas', 'box-open'] };
const productDetailsSection = { name: 'Product details', icon: ['fas', 'box-open'] };
const clipDetailsSection = { name: 'Video details', icon: ['fas', 'video'] };

const navigation = [
    dashboardSection,
    instancesSection,
    clientsSection,
    clientGroupsSection,
    clipsSection,
    playlistsSection,
    productsSection
]

const browseView = "browseView";
const createInstanceView = "createInstanceView";
const editInstanceView = "editInstanceView";
const createClientView = "createClientView";
const editClientView = "editClientView";
const createClientGroupView = "createClientGroupView";
const editClientGroupView = "editClientGroupView";
const createPlaylistView = "createPlaylistView";
const editPlaylistView = "editPlaylistView";
const createProductView = "createProductView";
const editProductView = "editProductView";
const createClipView = "createClipView";
const editClipView = "editClipView";

// const userNavigation = [
//   { name: 'Your Profile', href: '#' },
//   { name: 'Settings', href: '#' },
//   { name: 'Sign out', href: '#' },
// ]

const logo = require("../../images/howtofy_logo_white_RGB.svg");

export default (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [view, setView] = useState(browseView);
    const [activeSection, setActiveSection] = useState(navigation[0]);
    const [pageStack, setPageStack] = useState([]);

    const [instances, setInstances] = useState(null);
    const [clients, setClients] = useState(null);
    const [clientGroups, setClientGroups] = useState(null);
    const [playlists, setPlaylists] = useState(null);
    const [products, setProducts] = useState(null);
    const [clips, setClips] = useState(null);
    const [clipDetails, setClipDetails] = useState(null);
    
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [selectedClientData, setSelectedClientData] = useState(null);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedClientGroupId, setSelectedClientGroupId] = useState(null);
    const [selectedClientGroup, setSelectedClientGroup] = useState(null);
    const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedClipId, setSelectedClipId] = useState(null);
    const [selectedClip, setSelectedClip] = useState(null);

    const [editingItem, setEditingItem] = useState(null);
    const [initialLoadIds, setInitialLoadIds] = useState(null);
    const [username, setUsername] = useState("");

    const[errorModalText, setErrorModalText] = useState(null);

    //const [uploader, setUploader] = useState(null);
    const [currentUploads, setCurrentUploads] = useState([]);

    const params = props.match.params;
    const history = useHistory();

    const [, handleWorkerMessage] = useReducer((state, message) => {
        const index = currentUploads.findIndex(x => x.id === message.id);
        if (index !== -1) {
            let copy = [...currentUploads];
            let uploadObject = _.cloneDeep(copy[index]);
            
            uploadObject.progress = message.readBytes;
            uploadObject.status = message.status;
            //console.log(uploadObject);

            copy.splice(index, 1, uploadObject);
            setCurrentUploads(copy);

            if (message.status === Utils.WorkerStatus.Complete) {
                let uploadUrlObject = uploadObject.uploadUrlObject;
                uploadUrlObject.blockIds = message.blockIds;
                uploadUrlObject.blockIdsHash = message.blockIdsHash;

                if (uploadObject.type === 'clip') {
                    fetch(Utils.getFinalizeClipUploadUrl(params.instanceId, params.clientId, message.id), Utils.createRequest('POST', uploadUrlObject)).then(() => {
                    });
                }
                if (uploadObject.type === 'client') {
                    fetch(Utils.getFinalizeClientHeroVideoUploadUrl(params.instanceId, message.id), Utils.createRequest('POST', uploadUrlObject)).then(() => {
                    });
                }
            }
        }
    }, "");
    

    // useEffect(() => {
    //     const uploaderWorker = new Worker('./uploader.js');
    //     uploaderWorker.onmessage = function (e) {
    //         handleWorkerMessage(e.data);
    //     };
    //     setUploader(uploaderWorker);
    // }, []);

    function uploadVideo(type, id, file, uploadUrlObject) {
        let copy = [...currentUploads];
        let index = copy.findIndex(x => x.id === id);
        if (index !== -1) {
            let obj = copy[index];
            obj.worker.postMessage({ cancel: true });
        }

        const uploaderWorker = new Worker('./uploader.js');
        uploaderWorker.onmessage = function (e) {
            handleWorkerMessage(e.data);
        };

        let uploadObject = { 
            worker: uploaderWorker, 
            uploadUrlObject: uploadUrlObject, 
            type: type,
            id: id, 
            filename: file.name, 
            size: file.size, 
            progress: -1, 
            status: Utils.WorkerStatus.Idle 
        };
        copy.push(uploadObject);
        setCurrentUploads(copy);
        uploaderWorker.postMessage({ id: id, file: file, url: uploadUrlObject.url });
    }

    useEffect(() => {
        setUsername(unescape(document.cookie.split('; ').find(x => x.startsWith('is_logged_in=')).split('=')[1]));
        if (params.instanceId) {
            let ids = { 
                instance: params.instanceId,
                client: params.clientId,
                clip: params.ClipId
            }
            setInitialLoadIds(ids);
            loadInstances();
        }
    }, []);

    useEffect(() => {
        if(instances) {
            if (initialLoadIds?.instance) {
                selectInstance(instances.find(x => x.id === initialLoadIds.instance));
                if (initialLoadIds.client) 
                    loadClients(initialLoadIds.instance);
                else 
                    setInitialLoadIds(null);
            }
        }
    }, [instances]);

    useEffect(() => {
        if(clients) {
            if (initialLoadIds?.client) {
                selectClient(clients.find(x => x.id === initialLoadIds.client));
                if (initialLoadIds.clip) 
                    loadClips(initialLoadIds.instance, initialLoadIds.client);
                else 
                    setInitialLoadIds(null);
            }
        }
    }, [clients]);

    useEffect(() => {
        if(clips) {
            if (initialLoadIds?.clip) {
                selectClip(clips.find(x => x.id === initialLoadIds.clip));
                setInitialLoadIds(null);
            }
        }
    }, [clips]);

    useEffect(() => {
        let stack = [];
        if (activeSection === instancesSection) {
            setInstances(null);
            setSelectedInstance(null);
            setClients(null);
            setClientGroups(null);
            setSelectedClientId(null);
            setSelectedClientGroupId(null);
            setClips(null);
            setSelectedClipId(null);
            setClipDetails(null);

            loadInstances();
        }

        if (activeSection === clientsSection) {
            const instanceId = selectedInstance?.id ?? params.instanceId;
            
            setClients(null);
            setClientGroups(null);
            setSelectedClientId(null);
            setSelectedClientGroupId(null);
            setClips(null);
            setSelectedClipId(null);
            setClipDetails(null);

            if(selectedInstance) stack.push(selectedInstance);
    
            loadClients(instanceId);
        }

        if (activeSection === playlistsSection) {
            const instanceId = selectedInstance?.id ?? params.instanceId;
            const clientId = selectedClient?.id ?? params.clientId;

            setPlaylists(null);
            setProducts(null);
            setClips(null);
            setSelectedClipId(null);
            setClipDetails(null);

            loadPlaylists(instanceId, clientId);
        }

        if (activeSection === productsSection) {
            const instanceId = selectedInstance?.id ?? params.instanceId;
            const clientId = selectedClient?.id ?? params.clientId;

            setPlaylists(null);
            setProducts(null);
            setClips(null);
            setSelectedClipId(null);
            setClipDetails(null);

            loadProducts(instanceId, clientId);
        }

        if (activeSection === clipsSection
            || activeSection === clientDetailsSection
            || activeSection === playlistsSection
            || activeSection === productsSection) {
            const instanceId = selectedInstance?.id ?? params.instanceId;
            const clientId = selectedClient?.id ?? params.clientId;
            
            setClips(null);
            setSelectedClipId(null);
            setClipDetails(null);

            if(selectedInstance) stack.push(selectedInstance);
            if(selectedClient) stack.push(selectedClient);
    
            if(activeSection === clipsSection)
                loadClips(instanceId, clientId);
            
            //if(activeSection === clientDetailsSection)
                //loadClips(instanceId, clientId);
        }

        // if (activeSection === clipDetailsSection) {
        //     const instanceId = selectedInstance?.id ?? params.instanceId;
        //     const clientId = selectedClient?.id ?? params.clientId;
        //     const clipId = selectedClip?.id ?? params.clipId;
    
        //     if(selectedInstance) stack.push(selectedInstance);
        //     if(selectedClient) stack.push(selectedClient);
        //     if(selectedClip) stack.push(selectedClip);
    
        //     loadClipDetails(instanceId, clientId, clipId);
        // }

        setPageStack(stack);
    }, [activeSection]);

    useEffect(() => {
        const url = `/admin${pageStack.map(x => `/${x.id}`).join('')}`;
        history.push(url);
    }, [pageStack]);

    useEffect(() => {
        document.getElementsByTagName('main')[0].scrollTo(0, 0);
    }, [view]);

    useEffect(() => {
        if (selectedClientId) {
            loadClientData(params.instanceId, selectedClientId);
        }
    }, [selectedClientId]);

    useEffect(() => {
        if (selectedClientData) {
            setSelectedClientId(selectedClientData.client.id);
            let elem = document.getElementById('howtofystyles');
            if (elem) {
                elem.parentNode.removeChild(elem);
            }
            document.getElementsByTagName('head')[0].appendChild(PlayerUtils.getStyleElement(selectedClientData.client));
        }
    }, [selectedClientData]);

    useEffect(() => {
        if (selectedClientData && selectedClientId) {
            setSelectedClient(selectedClientData.client);
        }
    }, [selectedClientData, selectedClientId]);

    

    function onNavbarItemSelected(item) {
        resetViewToBrowse();
        if (!item) {
            setActiveSection(instancesSection);
            return;
        }

        switch(item.objectType) {
            case "dbClip":
                selectClip(item);
                break;
            case "dbClient":
                selectClient(item);
                break;
            case "dbInstance":
                selectInstance(item);
                break;
        }
    }

    function loadInstances() {
        Utils.loadInstances().then(response => response.json().then(data => {
            setInstances(data);
        }).catch((err) => {
            if (response.status === 403) {
                setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
            } else {
                setErrorModalText(`Could not load data: ${err}`);
            }
        }));
    }

    function loadClientData(instanceId, clientId) {
        Utils.loadClientData(instanceId, clientId).then(response => response.json().then(data => {
            setSelectedClientData(Utils.processClientDataReferences(data));
        }).catch((err) => {
            if (response.status === 403) {
                setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
            } else {
                setErrorModalText(`Could not load data: ${err}`);
            }
        }));
    }

    function loadClients(instanceId) {
        Utils.loadClients(instanceId).then(response => response.json().then(data => {
            setClients(data.clients);
            setClientGroups(data.groups);
        }).catch((err) => {
            if (response.status === 403) {
                setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
            } else {
                setErrorModalText(`Could not load data: ${err}`);
            }
        }));
    }

    function loadPlaylists(instanceId, clientId) {
        if (selectedClient) {
            setPlaylists(selectedClient.__playlists);
        }
        // debugger;
        // Utils.loadPlaylists(instanceId, clientId).then(response => response.json().then(data => {
        //     setPlaylists(data);
        // }).catch((err) => {
        //     if (response.status === 403) {
        //         setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
        //     } else {
        //         setErrorModalText(`Could not load data: ${err}`);
        //     }
        // }));
    }

    function loadProducts(instanceId, clientId) {
        if (selectedClient) {
            setProducts(selectedClient.__products);
        }
        // debugger;
        // Utils.loadProducts(instanceId, clientId).then(response => response.json().then(data => {
        //     setProducts(data);
        // }).catch((err) => {
        //     if (response.status === 403) {
        //         setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
        //     } else {
        //         setErrorModalText(`Could not load data: ${err}`);
        //     }
        // }));
    }

    function loadClips(instanceId, clientId) {
        if (selectedClient) {
            setClips(selectedClient.__clips);
        }
        // Utils.loadClips(instanceId, clientId).then(response => response.json().then(data => {
        //     setClips(data);
        // }).catch((err) => {
        //     if (response.status === 403) {
        //         setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
        //     } else {
        //         setErrorModalText(`Could not load data: ${err}`);
        //     }
        // }));
    }

    // function loadClipDetails(instanceId, clientId, clipId) {
    //     fetch(`/api/instances/${instanceId}/clients/${clientId}/clips/${clipId}`).then(response => response.json().then(data => {
    //         console.log(data);
    //         setClipDetails(data);
    //     }));
    // }

    function isSectionAvailable(section)
    {
        let res = false;
        if (section === dashboardSection) res = true;
        if (section === instancesSection) res = true;
        if (selectedInstance && section === clientsSection) res = true;
        if (selectedInstance && section === clientGroupsSection) res = true;
        if (selectedClient && section === clipsSection) res = true;
        if (selectedClient && section === playlistsSection) res = true;
        if (selectedClient && section === productsSection) res = true;
        if (selectedClip && section === clipDetailsSection) res = true;
        return res;
    }

    function selectInstance(instance) {
        setSelectedInstance(instance);
        setSelectedClient(null);
        setSelectedClip(null);
        setActiveSection(clientsSection);
    }

    function createInstance() {
        setView(createInstanceView);
        setSelectedInstance(null);
        setEditingItem({ name: '', comments: ''});
    }

    function editInstance(instance) {
        setView(editInstanceView);
        setSelectedInstance(instance);
        setEditingItem(_.cloneDeep(instance));
    }

    function saveInstance(instance, saveAndExit) {
        let items = [...instances];
        const idx = items.findIndex(x => x.id === instance.id);
        if (idx !== -1) {
            items.splice(idx, 1);
        }                
        items.push(instance);
        setInstances(items);

        if (saveAndExit)
            resetViewToBrowse();
        else
            setEditingItem(_.cloneDeep(instance));
    }

    function deleteInstance(instance) {
        debugger;
        let items = [...instances];
        const idx = items.findIndex(x => x.id === instance.id);
        if (idx !== -1) {
            items.splice(idx, 1);
        }                
        setInstances(items);

        resetViewToBrowse();
    }



    function selectClient(client) {
        setSelectedClientId(client.id);
        setSelectedPlaylist(null);
        setSelectedProduct(null);
        setSelectedClip(null);
        setActiveSection(clientDetailsSection);
    }

    function createClient() {
        setView(createClientView);
        setSelectedClient(null);
        setEditingItem({ name: '', comments: ''});
    }

    function editClient(client) {
        setView(editClientView);
        setSelectedClientId(client.id);
        setEditingItem(_.cloneDeep(client));
    }

    function clientSaved(client, saveAndExit) {
        let items = [...clients];
        const idx = items.findIndex(x => x.id === client.id);
        if (idx !== -1) {
            items.splice(idx, 1);
        }                
        items.push(client);
        setClients(items);

        if (saveAndExit)
            resetViewToBrowse();
        else
            setEditingItem(_.cloneDeep(client));
    }

    function clientDeleted(client) {
        let items = [...clients];
        const idx = items.findIndex(x => x.id === client.id);
        if (idx !== -1) {
            items.splice(idx, 1);
        }                
        setClients(items);

        resetViewToBrowse();
    }

    function selectClientGroup(clientGroup) {
        setSelectedClientGroupId(clientGroup.id);
        setSelectedClient(null);
        setSelectedProduct(null);
        setSelectedClip(null);
        setActiveSection(clientDetailsSection);
    }

    function createClientGroup() {
        setView(createClientGroupView);
        setSelectedClientGroup(null);
        setEditingItem({ name: '', comments: ''});
    }

    function editClientGroup(clientGroup) {
        setView(editClientGroupView);
        setSelectedClientGroupId(clientGroup.id);
        setEditingItem(_.cloneDeep(clientGroup));
    }

    function clientGroupSaved(clientGroup, saveAndExit) {
        let items = [...clientGroups];
        const idx = items.findIndex(x => x.id === clientGroup.id);
        if (idx !== -1) {
            items.splice(idx, 1);
        }                
        items.push(clientGroup);
        setClientGroups(items);

        let clientsClone = [...clients];
        clientsClone.filter(x => x.clientGroupId == clientGroup.id).map(x => x.clientGroupId = null);
        clientGroup.clientIds.map(clientId => clientsClone.find(x => x.id == clientId).clientGroupId = clientGroup.id);
        setClients(clientsClone);

        if (saveAndExit)
            resetViewToBrowse();
        else
            setEditingItem(_.cloneDeep(clientGroup));
    }

    function clientGroupDeleted(clientGroup) {
        let items = [...clientGroups];
        const idx = items.findIndex(x => x.id === clientGroup.id);
        if (idx !== -1) {
            items.splice(idx, 1);
        }                
        setClientGroups(items);

        let clientsClone = [...clients];
        clientsClone.filter(x => x.clientGroupId == clientGroup.id).map(x => x.clientGroupId = null);
        setClients(clientsClone);

        resetViewToBrowse();
    }

    // function selectPlaylist(playlist) {
    //     setSelectedPlaylist(playlist);
    //     setSelectedProduct(null);
    //     setSelectedClip(null);
    //     setActiveSection();
    // }

    function selectPlaylist(playlist) {
        setSelectedPlaylist(playlist);
        // setSelectedProduct(null);
        // setSelectedClip(null);
        setActiveSection(playlistDetailsSection);
    }

    function createPlaylist() {
        setView(createPlaylistView);
        setSelectedPlaylist(null);
        setEditingItem({ name: '', comments: ''});
    }

    function editPlaylist(playlist) {
        setView(editPlaylistView);
        setSelectedPlaylist(playlist);
        setEditingItem(_.cloneDeep(playlist));
    }


    function playlistSaved(clientData, saveAndExit) {
        // let items = [...playlists];
        // const idx = items.findIndex(x => x.id === playlist.id);
        // if (idx !== -1) {
        //     items.splice(idx, 1);
        // }                
        // items.push(playlist);
        // setPlaylists(items);

        playlistUpdated(clientData);
        if (saveAndExit)
            resetViewToBrowse();
        // else
        //     setEditingItem(_.cloneDeep(playlist));
    }

    function playlistDeleted(clientData) {
        // let items = [...playlists];
        // const idx = items.findIndex(x => x.id === playlist.id);
        // if (idx !== -1) {
        //     items.splice(idx, 1);
        // }                
        // setPlaylists(items);
        playlistUpdated(clientData);
        resetViewToBrowse();
    }

    function playlistOrderChanged(clientData) {
        playlistUpdated(clientData);
    }

    function playlistUpdated(clientData) {
        const processed = Utils.processClientDataReferences(clientData);
        setSelectedClientData(processed);
        setSelectedClient(processed.client);
        setPlaylists(processed.client.__playlists);
    }


    function selectProduct(product) {
        setSelectedProduct(product);
        setActiveSection(productDetailsSection);
    }

    function createProduct() {
        setView(createProductView);
        setSelectedProduct(null);
        setEditingItem({ name: '', comments: ''});
    }

    function editProduct(product) {
        setView(editProductView);
        setSelectedProduct(product);
        setEditingItem(_.cloneDeep(product));
    }


    function productSaved(clientData, saveAndExit) {
        // let items = [...products];
        // const idx = items.findIndex(x => x.id === product.id);
        // if (idx !== -1) {
        //     items.splice(idx, 1);
        // }                
        // items.push(product);
        // setProducts(items);
        productUpdated(clientData);
        if (saveAndExit)
            resetViewToBrowse();
        // else
        //     setEditingItem(_.cloneDeep(product));
    }

    function productDeleted(clientData) {
        // let items = [...products];
        // const idx = items.findIndex(x => x.id === product.id);
        // if (idx !== -1) {
        //     items.splice(idx, 1);
        // }                
        // setProducts(items);
        productUpdated(clientData);
        resetViewToBrowse();
    }

    function productUpdated(clientData) {
        const processed = Utils.processClientDataReferences(clientData);
        setSelectedClientData(processed);
        setSelectedClient(processed.client);
        setProducts(processed.client.__products);
    }



    function selectClip(clip) {
        setSelectedClip(clip);
        setActiveSection(clipDetailsSection);
    }

    function createClip() {
        setView(createClipView);
        setEditingItem({ id: `_new_${uuidv4()}`, name: '', comments: '', __products: [], segments: [], __playlists:[] });
    }

    function editClip(clip) {
        setView(editClipView);
        setEditingItem(_.cloneDeep(clip));
    }

    function clipSaved(clientData, saveAndExit) {
        // let clientDataClone = _.cloneDeep(selectedClientData);

        // let items = clientDataClone.__clips;
        // const idx = items.findIndex(x => x.id === clip.id);
        // if (idx !== -1) {
        //     items.splice(idx, 1);
        // }
        // items.push(clip);
        clipUpdated(clientData);

        if (saveAndExit)
            resetViewToBrowse();
        //else
            //setEditingItem(_.cloneDeep(clip));

        // let items = [...clips];
        // const idx = items.findIndex(x => x.id === clip.id);
        // if (idx !== -1) {
        //     items.splice(idx, 1);
        // }                
        // items.push(clip);
        // setClips(items);

        // if (saveAndExit)
        //     resetViewToBrowse();
        // else
        //     setEditingItem(_.cloneDeep(clip));
    }

    function clipDeleted(clientData) {
        // let items = [...clips];
        // const idx = items.findIndex(x => x.id === clip.id);
        // if (idx !== -1) {
        //     items.splice(idx, 1);
        // }                
        // setClips(items);

        clipUpdated(clientData);
        resetViewToBrowse();
    }

    function clipUpdated(clientData) {
        const processed = Utils.processClientDataReferences(clientData);
        console.log(processed);
        setSelectedClientData(processed);
        setSelectedClient(processed.client);
        setClips(processed.client.__clips);
    }



    function resetViewToBrowse() {
        setEditingItem(null);
        setView(browseView);
    }

    function getActiveUploads() {
        return currentUploads.filter(x => x.status === Utils.WorkerStatus.Working);
    }
    
    function getProgressingUploads() {
        return getActiveUploads().filter(x => x.progress !== -1);
    }
    
    function hasActiveUploads() {
        return getActiveUploads().length > 0;
    }

    function getTotalUploadProgress() {
        const active = getProgressingUploads();
        const progress = _.sumBy(active, 'progress');
        const total = _.sumBy(active, 'size');
        return progress / total;
    }

  return (
      <>
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-gray-800">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img
                                className="h-8 w-auto"
                                src={logo}
                                alt="Howtofy"
                                />
                            </div>
                            <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                <nav className="px-2 space-y-1">
                                {navigation.map((section) => (
                                    <a
                                    key={section.name}
                                    onClick={(ev) => { if(isSectionAvailable(section)) { resetViewToBrowse(); setActiveSection(section); } ev.stopPropagation(); }}
                                    className={Utils.classNames(
                                        section === activeSection ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white cursor-pointer',
                                        isSectionAvailable(section) ? '' : 'opacity-3',
                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                    )}
                                    >
                                        <FontAwesomeIcon icon={section.icon} size="lg" className="mr-3 text-gray-500" />
                                        {section.name}
                                    </a>
                                ))}
                                </nav>
                            </div>
                            <div className="flex flex-col flex-shrink-0 px-4 bg-gray-700 text-gray-400 border-gray-600 border-t p-3 items-center text-xs">
                                <p>Logged in as {username}</p>
                                <p><a href="/logout" className="text-gray-300 underline">Log out</a></p>
                            </div>

                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:flex-shrink-0">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex-1 flex flex-col min-h-0">
                        <div className="flex items-center flex-shrink-0 px-4 bg-gray-700">
                            <img
                                className="h-12 w-auto mx-auto mt-8"
                                src={logo}
                                alt="Howtofy"
                            />
                        </div>
                        <div className="flex-1 flex flex-col overflow-y-auto">
                            <nav className="flex-1 px-2 py-4 bg-gray-700 space-y-1">
                                {navigation.map((section) => (
                                <a
                                    key={section.name}
                                    onClick={(ev) => { if(isSectionAvailable(section)) { resetViewToBrowse(); setActiveSection(section); } ev.stopPropagation(); }}
                                    className={Utils.classNames(
                                    section === activeSection ? 'bg-gray-600 text-white' : 'text-gray-300 bg-gray-900 hover:bg-gray-700 hover:text-white cursor-pointer',
                                    isSectionAvailable(section) ? '' : 'opacity-30',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                    <FontAwesomeIcon icon={section.icon} size="lg" className={`mr-3 ${section === activeSection ? 'text-white': 'text-gray-500'}`} />
                                    {section.name}
                                </a>
                                ))}
                            </nav>
                        </div>
                        <div className="flex flex-col flex-shrink-0 px-4 bg-gray-700 text-gray-400 border-gray-600 border-t p-3 items-center text-xs">
                            <p>Logged in as {username}</p>
                            <p><a href="/logout" className="text-gray-300 underline">Log out</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
                <button
                    type="button"
                    className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none md:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                {/* <div className="flex-1 px-4 flex justify-between"> */}
                    {/* <div className="flex-1 flex">
                    <form className="w-full flex md:ml-0" action="#" method="GET">
                        <label htmlFor="search-field" className="sr-only">
                        Search
                        </label>
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                            <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                            id="search-field"
                            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-transparent sm:text-sm"
                            placeholder="Search"
                            type="search"
                            name="search"
                        />
                        </div>
                    </form>
                    </div> */}
                    {/* <div className="ml-4 flex items-center md:ml-6"> */}
                    {/* <button
                        type="button"
                        className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    {/* <Menu as="div" className="ml-3 relative">
                        <div>
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
                            <span className="sr-only">Open user menu</span>
                            <img
                            className="h-8 w-8 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                            />
                        </Menu.Button>
                        </div>
                        <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                        >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                                {({ active }) => (
                                <a
                                    href={item.href}
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                    {item.name}
                                </a>
                                )}
                            </Menu.Item>
                            ))}
                        </Menu.Items>
                        </Transition>
                    </Menu> */}
                    {/* </div> */}
                {/* </div> */}
                </div>

                {pageStack.length > 0 &&
                    <NavBar pages={pageStack} onItemSelected={(item) => onNavbarItemSelected(item)} />
                // <nav className="bg-white border-b border-gray-200 flex" aria-label="Breadcrumb">
                //     <ol role="list" className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
                //         <li className="flex">
                //         <div className="flex items-center">
                //             <a href="#" className="text-gray-400 hover:text-gray-500">
                //             <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                //             <span className="sr-only">Home</span>
                //             </a>
                //         </div>
                //         </li>
                //         {pageStack.map((page) => (
                //         <li key={page.name} className="flex">
                //             <div className="flex items-center">
                //             <svg
                //                 className="flex-shrink-0 w-6 h-full text-gray-200"
                //                 viewBox="0 0 24 44"
                //                 preserveAspectRatio="none"
                //                 fill="currentColor"
                //                 xmlns="http://www.w3.org/2000/svg"
                //                 aria-hidden="true"
                //             >
                //                 <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                //             </svg>
                //             <a
                //                 href={page.href}
                //                 className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                //                 aria-current={page.current ? 'page' : undefined}
                //             >
                //                 {page.name}
                //             </a>
                //             </div>
                //         </li>
                //         ))}
                //     </ol>
                // </nav>
                }

                {currentUploads.length > 0 &&
                    <div className={`border-b px-3 py-2 flex flex-cols items-center space-x-3 ${hasActiveUploads() ? 'bg-yellow-100 text-yellow-800 border-yellow-400' : 'bg-green-100 text-green-800 border-green-400'}`}>
                        <div>
                            <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} className={`text-3xl ${hasActiveUploads() ? 'text-yellow-800' : 'text-green-800'}`} />
                        </div>
                        {!hasActiveUploads() &&
                            <div>
                                All uploads have finished!
                            </div>
                        }

                        {hasActiveUploads() &&
                            <div className="flex flex-cols items-center space-x-3">
                                <div>
                                    <FontAwesomeIcon icon={['fas', 'spinner']} spin className={`text-2xl text-yellow-800`} />
                                </div>
                                <div>
                                    Uploading {getActiveUploads().length} file{getActiveUploads().length === 1 ? '' : 's'}
                                    <span className="pl-1">
                                        ({Math.round(getTotalUploadProgress() * 100)}%...)
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                }

                

                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    {selectedClient &&
                        <div className="howtofy-banner-background-color howtofy-text-color p-8 flex justify-center items-center">
                            {selectedClient.existingLogoUrl &&
                                <img className="h-24" src={`/render/image?url=${selectedClient.existingLogoUrl}&width=300`} alt={selectedClient.name} />
                            }
                            {!selectedClient.existingLogoUrl &&
                                <p className="text-3xl font-extrabold">{selectedClient.name}</p>
                            }
                        </div>
                    }
                    {view === browseView &&
                        <div className="py-6">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                <h1 className="text-2xl font-semibold text-gray-900">{activeSection?.name}</h1>
                            </div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-5">
                                {activeSection === dashboardSection &&
                                <>
                                    {/* Replace with your content */}
                                    <div className="py-4">
                                        <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
                                        </div>
                                    {/* /End replace */}
                                </>
                                }

                                {activeSection === instancesSection &&
                                    <InstanceList items={instances} selected={selectedInstance} onSelected={(instance) => selectInstance(instance)} onEditClicked={(instance) => editInstance(instance)} onCreateClicked={() => createInstance()} />
                                }

                                {activeSection === clientsSection &&
                                    <ClientList items={clients} selected={selectedClient} onSelected={(client) => selectClient(client)} onEditClicked={(client) => editClient(client)} onCreateClicked={() => createClient()} />
                                }

                                {activeSection === clientGroupsSection &&
                                    <ClientGroupList items={clientGroups} clients={clients} selected={selectedClientGroup} onSelected={(clientGroup) => selectClientGroup(clientGroup)} onEditClicked={(clientGroup) => editClientGroup(clientGroup)} onCreateClicked={() => createClientGroup()} />
                                }

                                {activeSection === clientDetailsSection && selectedClient &&
                                    <ClientDetails item={selectedClient} links={[playlistsSection, productsSection, clipsSection]} onSectionSelected={(section) => { resetViewToBrowse(); setActiveSection(section); }} onEditClicked={(client) => editClient(client)} />
                                }

                                {activeSection === playlistsSection &&
                                    <PlaylistList instance={selectedInstance} client={selectedClient} items={playlists} selected={selectedPlaylist} onSelected={(playlist) => selectPlaylist(playlist)} onOrderChanged={(item) => playlistOrderChanged(item)} onEditClicked={(playlist) => editPlaylist(playlist)} onCreateClicked={() => createPlaylist()} />
                                }

                                {activeSection === playlistDetailsSection &&
                                    <PlaylistDetails instance={selectedInstance} client={selectedClient} item={selectedPlaylist} onEditClicked={(playlist) => editPlaylist(playlist)} />
                                }

                                {activeSection === productsSection &&
                                    <ProductList items={products} selected={selectedProduct} onSelected={(product) => selectProduct(product)} onEditClicked={(product) => editProduct(product)} onCreateClicked={() => createProduct()} />
                                }

                                {activeSection === productDetailsSection &&
                                    <ProductDetails instance={selectedInstance} client={selectedClient} item={selectedProduct} onEditClicked={(product) => editProduct(product)} />
                                }

                                {activeSection === clipsSection &&
                                    <ClipList items={clips} selected={selectedClip} onSelected={(clip) => selectClip(clip)} onEditClicked={(clip) => editClip(clip)} onCreateClicked={() => createClip()} />
                                }

                                {activeSection === clipDetailsSection &&
                                    <ClipDetails instance={selectedInstance} client={selectedClient} item={selectedClip} onEditClicked={(clip) => editClip(clip)} />
                                }
                            </div>
                        </div>
                    }

                    {(view === createInstanceView || view === editInstanceView) &&
                        <InstanceEditForm item={editingItem} view={view} onSaved={(item, saveAndExit) => saveInstance(item, saveAndExit)} onDeleted={(item) => deleteInstance(item)} onDone={() => resetViewToBrowse()} />
                    }

                    {(view === createClientView || view === editClientView) &&
                        <ClientEditForm 
                            instance={selectedInstance} item={editingItem} view={view} 
                            onUploadVideo={(id, file, uploadUrlObject) => uploadVideo('client', id, file, uploadUrlObject)}
                            onSaved={(item, saveAndExit) => clientSaved(item, saveAndExit)} 
                            onDeleted={(item) => clientDeleted(item)} 
                            onDone={() => resetViewToBrowse()} 
                        />
                    }

                    {(view === createClientGroupView || view === editClientGroupView) &&
                        <ClientGroupEditForm 
                            instance={selectedInstance} item={editingItem} view={view} 
                            groups={clientGroups}
                            clients={clients}
                            onSaved={(item, saveAndExit) => clientGroupSaved(item, saveAndExit)} 
                            onDeleted={(item) => clientGroupDeleted(item)} 
                            onDone={() => resetViewToBrowse()} 
                        />
                    }

                    {(view === createPlaylistView || view === editPlaylistView) &&
                        <PlaylistEditForm 
                            instance={selectedInstance} client={selectedClient} item={editingItem} view={view} 
                            onSaved={(item, saveAndExit) => playlistSaved(item, saveAndExit)} 
                            onDeleted={(item) => playlistDeleted(item)} 
                            onDone={() => resetViewToBrowse()} />
                    }

                    {(view === createProductView || view === editProductView) &&
                        <ProductEditForm 
                            instance={selectedInstance} client={selectedClient} item={editingItem} view={view} 
                            onSaved={(item, saveAndExit) => productSaved(item, saveAndExit)} 
                            onDeleted={(item) => productDeleted(item)} 
                            onDone={() => resetViewToBrowse()} />
                    }

                    {(view === createClipView || view === editClipView) &&
                        <ClipEditForm 
                            instance={selectedInstance} client={selectedClient} item={editingItem} view={view} 
                            onUploadVideo={(id, file, uploadUrlObject) => uploadVideo('clip', id, file, uploadUrlObject)}
                            onPlaylistCreated={(item) => playlistSaved(item, false)}
                            onSaved={(item, saveAndExit) => clipSaved(item, saveAndExit)} 
                            onDeleted={(item) => clipDeleted(item)} 
                            onDone={() => resetViewToBrowse()} />
                    }

                </main>
            </div>
        </div>
        <Modal className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" isOpen={errorModalText !== null} onClosed={() => alert('onClosed')}>
            <div className="">
                <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="text-lg text-red-500" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {errorModalText?.split(':')[0]}
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                {errorModalText?.split(':')[1]}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setErrorModalText(null)}
                    >
                        OK
                    </button>
                </div>
            </div>
        </Modal>
    </>
  )
}
