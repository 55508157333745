import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {DraggableAreasGroup} from 'react-draggable-tags'; //https://ygyooo.github.io/react-draggable-tags/
import _ from "lodash";
import Tabs from './Tabs';
import Utils from '../Utils/Utils';

const group = new DraggableAreasGroup();
const ClipsDraggableAreaFrom = group.addArea(); 
const ClipsDraggableAreaTo = group.addArea(); 

const tabGeneral = { name: 'General' };
const tabClips = { name: 'Videos' };
const tabTranslations = { name: 'Translations' };
const tabs = [tabGeneral, tabClips, tabTranslations];

export default (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [editingItem, setEditingItem] = useState(null);
    const [activeTab, setActiveTab] = useState(tabGeneral);
    const [initialClips, setInitialClips] = useState([]);
    const [clientClips, setClientClips] = useState([]);
    const [availableClips, setAvailableClips] = useState([]);
    const [selectedClips, setSelectedClips] = useState([]);
    const [isBusy, setIsBusy] = useState(false);

    const [clientGroupPlaylists, setClientGroupPlaylists] = useState([]);
    const [selectedClientGroupClient, setSelectedClientGroupClient] = useState();
    const [translatedClientPlaylists, setTranslatedClientPlaylists] = useState([]);


    useEffect(() => {
        if (props?.item) {
            setEditingItem(props.item);
            // setInitialClips(props.item.clips);
            //loadPlaylist(props.instanceId, props.clientId, props.item.id);
            setInitialClips(props.item.__clips);
            setClientClips(props.client.__clips);
            //loadClientClips(props.instanceId, props.clientId);
        }

        if (props.instance?.id && props.client?.clientGroupId) {
            Utils.loadClientGroupPlaylists(props.instance.id, props.client.clientGroupId, props.client.id).then(response => response.json().then(data => {
                //setInstances(data);
                data.clients.map(client => {
                    client.__playlists = data.playlists.filter(playlist => playlist.clientId == client.id && !playlist.playlistGroupId);
                });
                setClientGroupPlaylists(data.clients);
                console.log(data.clients);
                if (props.item.playlistGroupId) {
                    let translated = [];
                    data.playlists.filter(x => x.playlistGroupId == props.item.playlistGroupId && x.id != props.item.id).map(x => {
                        let clientClone = {...data.clients.find(client => client.id == x.clientId)};
                        let playlistClone = {...x};
                        clientClone.playlist = playlistClone;
                        translated.push(clientClone);
                    });
                    setTranslatedClientPlaylists(translated);
                }
            }).catch((err) => {
                if (response.status === 403) {
                    //setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
                } else {
                    //setErrorModalText(`Could not load data: ${err}`);
                }
            }));
        }

    }, []);

    useEffect(() => {
        if(clientClips) {
            if (initialClips && initialClips.length > 0) {
                setAvailableClips(clientClips.filter(x => !initialClips.find(selClip => selClip.id === x.id)));
                // let selClips = clientClips.filter(x => initialClips.find(selClip => selClip.id === x.id));
                // _.forEach(selClips, function(o) { 
                //     o.order = initialClips.find(x => x.id === o.id).order;
                // });
                // selClips.sort(function(a, b) { return a.order < b.order ? -1 : 1})
                setSelectedClips(initialClips);
            } else {
                setAvailableClips(clientClips);
                setSelectedClips([]);
            }
        }
    }, [clientClips, initialClips]);

    useEffect(() => {
        if(editingItem && selectedClips) {
            let item = _.cloneDeep(editingItem);
            item.clips = selectedClips;
            selectedClips.map(x => { console.log(x.name)});
            setEditingItem(item);
        }
    }, [selectedClips]);

    // function loadClientClips(instanceId, clientId) {
    //     Utils.loadClips(instanceId, clientId).then(response => response.json().then(data => {
    //         setClientClips(data);
    //     }));
    // }

    // function loadPlaylist(instanceId, clientId, playlistId) {
    //     Utils.loadPlaylist(instanceId, clientId, playlistId).then(response => response.json().then(data => {
    //         setEditingItem(data);
    //         setInitialClips(data.clips);
    //     }));
    // }

    function savePlaylist(playlist, saveAndExit = false) {
        if (playlist.name.trim() == '') {
            alert("Please provide a name for the playlist.");
            return;
        }

        const isCreating = playlist?.id == null;
        const url = Utils.getPlaylistsUrl(props.instance.id, props.client.id) + (!isCreating ? `/${playlist.id}` : '');

        let model = Utils.sanitizeObject(playlist);
        model.clipDTOs = selectedClips.map(clip => { 
            return {
                id: clip.id
            }
        });
        model.clipIds = selectedClips.map(x => x.id);

        if (translatedClientPlaylists.length > 0) {
            model.playlistGroups = translatedClientPlaylists.map(x => {
                return { 
                    clientId: x.id, 
                    itemId: x.playlist.id 
                };
            });
            model.playlistGroups.push({ 
                clientId: props.client.id, 
                itemId: props.item.id 
            });
        }


        const request = {
            method: isCreating ? 'POST' : 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        }
        setIsBusy(true);
        fetch(url, request).then(response => response.json().then(data => {
            setIsBusy(false);
            props.onSaved(data, saveAndExit);
        }));

    }

    function deletePlaylist(playlist) {
        if (window.confirm("Are you sure?")) {
            setIsBusy(true);
            fetch(Utils.getPlaylistUrl(props.instance.id, props.client.id, playlist.id), Utils.createRequest('DELETE')).then(response => response.json().then((data) => {
                setIsBusy(false);
                props.onDeleted(data);
            }));
        }
    }

    function resetViewToBrowse() {
        props.onDone();
    }

    function updateEditingItem(field, value) {
        let obj = editingItem;
        if (field.indexOf('.') === -1) {
            editingItem[field] = value;
        } else {
            let fields = field.split('.');
            const lastField = fields[fields.length - 1];
            fields = _.take(fields, fields.length - 1);
            fields.map(x => obj = obj[x]);
            obj[lastField] = value;
        }
        forceUpdate();
    }

    function toggleTranslatedClientPlaylist(client, playlist) {
        let clone = _.cloneDeep(translatedClientPlaylists);
        const idx = clone.findIndex(x => x.id == client.id);

        if (idx != -1) {
            clone.splice(idx, 1);
        }
        if (playlist) {
            let clientClone = {...client};
            clientClone.playlist = {...playlist};
            clone.push(clientClone);
        }

        setTranslatedClientPlaylists(clone);
    }

    function isClientGroupPlaylistSelected(client, playlist) {
        const pl = translatedClientPlaylists.find(x => x.id == client.id);
        return pl?.playlist?.id == playlist.id;
    }



    return (
        <div className={`${!isBusy ? '' : 'opacity-30 pointer-events-none'} py-6 bg-gray-100`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">{props.view === 'createPlaylistView' ? "Create playlist" : "Edit playlist"}</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-5">

                <Tabs tabs={tabs} activeTab={activeTab} onSetActiveTab={(tab) => setActiveTab(tab)} />      


                <form className="space-y-8 sm:space-y-5 divide-y divide-gray-200" onSubmit={(ev) => { ev.preventDefault(); return false; }}>
                    {activeTab === tabGeneral &&
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="playlistname" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Playlist name
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="playlistname"
                                            id="playlistname"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.name ?? ''}
                                            onChange={e => updateEditingItem('name', e.target.value) }

                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Description
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        id="description"
                                        name="description"
                                        rows={3}
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.description ?? ''}
                                        onChange={e => updateEditingItem('description', e.target.value) }
                                    />
                                </div>
                            </div>                                

                            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="title" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Title
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="title"
                                            id="title"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.title ?? ''}
                                            onChange={e => updateEditingItem('title', e.target.value) }

                                        />
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    }

                    {activeTab === tabClips && 



                        <div className="grid grid-flow-row grid-cols-2 gap-4 mt-8">
                            <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                    Available
                                </div>
                                <ClipsDraggableAreaFrom
                                    isList
                                    tags={availableClips}
                                    className="p-3 min-h-32"
                                    render={({tag, index}) => (
                                    <div className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 cursor-drag">
                                        {tag.name}
                                    </div>
                                    )}
                                    onChange={clips => setAvailableClips(clips)}
                                />
                            </div>
                            <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                    Selected
                                </div>
                                <ClipsDraggableAreaTo
                                    isList
                                    tags={selectedClips}
                                    className="p-3 min-h-32"
                                    render={({tag, index}) => (
                                    <div className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 text-cursor-drag">
                                        {index + 1}. {tag.name}
                                    </div>
                                    )}
                                    onChange={clips => setSelectedClips(clips)}
                                />
                            </div>

                        </div>



                        // <ul role="list" className="divide-y divide-gray-200">
                        //     {editingItem?.clips && [...editingItem?.clips].sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})).map((clip) => (
                        //     <li key={clip.id}>
                        //         <a className={`cursor-pointer block hover:bg-gray-50 ${props.selected?.id === clip.id ? "bg-blue-50" : ""}`}>
                        //             <div className="px-4 py-4 sm:px-6">
                        //                 <div className="flex items-center justify-between">
                        //                     <div className="flex">
                        //                         <FontAwesomeIcon icon={['fas', 'square']} size="lg" className="mr-5 text-gray-300" />
                        //                         <div>
                        //                             <p className="text-sm font-medium text-indigo-600 truncate">
                        //                                 {clip.name}
                        //                                 {!clip.isPublished &&
                        //                                         <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                        //                                             Not published
                        //                                         </span>
                        //                                     }
                        //                             </p>
                        //                             <p className="text-sm text-gray-500">
                        //                                 {clip.comments}
                        //                             </p>
                        //                         </div>
                        //                     </div>
                        //                     <div>
                        //                         <div onClick={(ev) => { props.onEditClicked(clip); ev.stopPropagation(); }}>
                        //                             <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="text-indigo-600" />
                        //                         </div>
                        //                     </div>
                        //                 </div>
                        //                 <div className="mt-2 sm:flex sm:justify-between">
                        //                 </div>
                        //             </div>
                        //         </a>
                        //     </li>
                        //     ))}

                        //     {editingItem?.clips && editingItem?.clips.length === 0 &&
                        //         <li>
                        //             <div className="px-4 py-4 sm:px-6">
                        //                 <div className="flex items-center justify-center">
                        //                     <p className="text-sm font-light italic">
                        //                         No clips added yet. Click Add clip to add one. 
                        //                     </p>
                        //                 </div>
                        //             </div>
                        //         </li>                    
                        //     }
                        // </ul>



                    }




                    {(activeTab === tabTranslations) && props.client.clientGroupId && clientGroupPlaylists.length > 0 &&
                        <div>
                            <h3 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">{tabTranslations.name}</h3>
                            <div className="grid grid-flow-row grid-cols-3 gap-4 mt-8">

                                <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                    <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                        Other clients in group
                                    </div>
                                    <ul className="h-64 overflow-y-scroll">
                                        {clientGroupPlaylists.map(client => {
                                            return (
                                                <li key={client.id} className={`${client.id === selectedClientGroupClient?.id ? 'bg-blue-200 border-blue-300 divide-blue-300' : 'bg-gray-100 border-gray-200 divide-gray-200'} text-gray-600 border rounded m-1 flex flex-cols items-center cursor-pointer divide-x`}>
                                                    <div className={`${client.id === selectedClientGroupClient?.id ? 'bg-blue-100' : 'bg-gray-50'} flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center`}>
                                                        <FontAwesomeIcon icon={['fas', 'users']} />
                                                        <span className="truncate">{client.name} ({client.culture})</span>
                                                    </div>
                                                    <div onClick={() => setSelectedClientGroupClient(client)} className={`${client.id === selectedClientGroupClient?.id ? 'text-blue-400' : 'text-gray-400'} px-3 py-1`}>
                                                        <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} size="lg" />
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>

                                <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                    <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                        Playlists in client
                                    </div>
                                    <ul className="h-64 overflow-y-scroll">
                                        {selectedClientGroupClient && [...selectedClientGroupClient.__playlists].sort((a, b) => a.name < b.name ? -1 : 1).map(playlist => {
                                            return (
                                                <li key={playlist.id} className={`${isClientGroupPlaylistSelected(selectedClientGroupClient, playlist) ? 'bg-blue-200 border-blue-300 divide-blue-300' : 'bg-gray-100 border-gray-200 divide-gray-200'} text-gray-600 border rounded m-1 flex flex-cols items-center cursor-pointer divide-x`}>
                                                    <div className={`${isClientGroupPlaylistSelected(selectedClientGroupClient, playlist) ? 'bg-blue-100' : 'bg-gray-50'} flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center`}>
                                                        <FontAwesomeIcon icon={['fas', 'list']} />
                                                        <span className="truncate">{playlist.name}</span>
                                                    </div>
                                                    <div onClick={() => toggleTranslatedClientPlaylist(selectedClientGroupClient, playlist)} className={`${isClientGroupPlaylistSelected(selectedClientGroupClient, playlist) ? 'text-blue-400' : 'text-gray-400'} px-3 py-1`}>
                                                        <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} size="lg" />
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>                                    
                                
                                <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                    <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                        Selected translations
                                    </div>
                                    <ul className="h-64 overflow-y-scroll">
                                        {translatedClientPlaylists.map(client => {
                                            return (
                                                <li key={client.id} className="border border-gray-200 text-gray-600 rounded m-1 flex flex-cols items-center cursor-pointer divide-x divide-gray-200">
                                                    <div onClick={() => toggleTranslatedClientPlaylist(client, null)} className="bg-gray-100 px-3 py-1">
                                                        <FontAwesomeIcon icon={['fas', 'arrow-circle-left']} size="lg" className="text-gray-400" />
                                                    </div>
                                                    <div className="bg-gray-50 flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center">
                                                        <FontAwesomeIcon icon={['fas', 'list']} />
                                                        <span className="truncate">({client.culture}) {client.playlist.name}</span>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>                                    
                            </div>
                        </div>
                    }




                    <div className="pt-5">
                        <div className={`flex ${props.view === 'createPlaylistView' ? "justify-end" : "justify-between"}`}>
                            {props.view === 'editPlaylistView' &&
                                <button
                                    type="button"
                                    onClick={() => !isBusy && deletePlaylist(editingItem)}
                                    className="bg-red-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-700 focus:outline-none"
                                    disabled={isBusy}                                    
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'trash-alt' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Delete
                                </button>
                            }
                            <div className="flex content-start">
                                <button
                                    type="button"
                                    onClick={() => !isBusy && resetViewToBrowse()}
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                                    disabled={isBusy}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={() => savePlaylist(editingItem)}
                                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && savePlaylist(editingItem, true)}
                                    className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save and exit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
