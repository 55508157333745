import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Utils from '../Utils/Utils';

export default (props) => {

    return (
        <>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    value={props.tabs.find((x) => x.name === props.activeTab.name).name}
                    onChange={(ev) => props.onSetActiveTab(props.tabs.find(x => x.name === ev.target.value))}
                >
                {props.tabs.map((tab) => (
                    <option key={tab.name} value={tab.name}>{tab.name}</option>
                ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {props.tabs.map((tab) => (
                    <a
                        key={tab.name}
                        onClick={() => props.onSetActiveTab(tab)}
                        className={Utils.classNames(
                        tab === props.activeTab
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer'
                        )}
                        aria-current={props.activeTab ? 'page' : undefined}
                    >
                        {tab.name}
                    </a>
                    ))}
                </nav>
            </div>
        </>
    )
}
