import React, { useEffect, useState } from 'react';
import {DraggableArea} from 'react-draggable-tags'; //https://ygyooo.github.io/react-draggable-tags/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Utils from '../Utils/Utils';

export default (props) => {
    const[playlists, setPlaylists] = useState([]);

    useEffect(() => {
        if (props?.items) {
            setPlaylists(props.items);
        }
    }, [props]);

    function setPlaylistsOrder(playlists) {
        console.log(playlists);
        setPlaylists(playlists);
        savePlaylistsOrder(playlists);
    }

    function savePlaylistsOrder(playlists) {
        const data = {
            playlistIds: playlists.map(x => x.id)
        };

        const request = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
        fetch(Utils.getSetPlaylistsOrderUrl(props.instance.id, props.client.id), request)
            .then(response => response.json().then(data => {
                props.onOrderChanged(data);
            }
        ));        
        // fetch(Utils.getSetPlaylistsOrderUrl(props.instance.id, props.client.id), {
        //     method: 'POST',
        //     body: JSON.stringify({ playlists: playlists } )
        // }).then(response => response.json()).then(response => {
        //     console.log("klart");
        // });
    }



    return (
        <>
            {playlists.length > 3 &&
                <div className="flex justify-end mb-5">
                    <button
                        type="button"
                        onClick={(ev) => { props.onCreateClicked(); ev.stopPropagation(); }}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} size="lg" className="mr-2" />
                        Add playlist
                    </button>
                </div>
            }
            <div className="bg-white shadow overflow-hidden sm:rounded-md">

            <DraggableArea
                className="divide-y divide-gray-200"
                isList
                tags={playlists}
                render={({tag}) => (
                    <div onClick={(ev) => { props.onSelected(tag); ev.stopPropagation(); }} className={`cursor-pointer block hover:bg-gray-50 px-4 py-4 flex items-center sm:px-6 ${props.selected?.id === tag.id ? "bg-blue-50" : ""}`}>
                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="flex items-center mr-5">
                                <FontAwesomeIcon icon={['fas', 'list']} size="lg" className="mr-5 text-gray-300" />
                                <div className="">
                                    <div className="text-sm">
                                        <p className="font-medium text-indigo-600">{tag.name?.length > 0 ? tag.name : '(Untitled playlist)'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                <div className="flex overflow-hidden -space-x-1">
                                    <button
                                        type="button"
                                        onClick={(ev) => { props.onEditClicked(tag); ev.stopPropagation(); }}
                                        className="inline-flex justify-center py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 hover:text-white hover:bg-indigo-700 focus:outline-none"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'pencil-alt']} size="lg" className="mr-2" />
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="ml-5 flex-shrink-0">
                            <FontAwesomeIcon icon={['fas', 'chevron-right']} size="lg" className="text-gray-300" />
                        </div>
                    </div>
                )}
                onChange={(tags) => setPlaylistsOrder(tags)}
            />                

                {/* <ul role="list" className="divide-y divide-gray-200">
                    {playlists.map((playlist) => (
                        <li key={playlist.id}>
                        
                        
                        
                            <div onClick={(ev) => { props.onSelected(playlist); ev.stopPropagation(); }} className={`cursor-pointer block hover:bg-gray-50 px-4 py-4 flex items-center sm:px-6 ${props.selected?.id === playlist.id ? "bg-blue-50" : ""}`}>
                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div className="flex items-center mr-5">
                                        <FontAwesomeIcon icon={['fas', 'list']} size="lg" className="mr-5 text-gray-300" />
                                        <div className="">
                                            <div className="text-sm">
                                                <p className="font-medium text-indigo-600">{playlist.name?.length > 0 ? playlist.name : '(Untitled playlist)'}</p>
                                                {playlist.comments && playlist.comments !== '' &&
                                                    <p className="font-normal text-gray-500">{playlist.comments}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                        <div className="flex overflow-hidden -space-x-1">
                                            <button
                                                type="button"
                                                onClick={(ev) => { props.onEditClicked(playlist); ev.stopPropagation(); }}
                                                className="inline-flex justify-center py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 hover:text-white hover:bg-indigo-700 focus:outline-none"
                                            >
                                                <FontAwesomeIcon icon={['fas', 'pencil-alt']} size="lg" className="mr-2" />
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="ml-5 flex-shrink-0">
                                    <FontAwesomeIcon icon={['fas', 'chevron-right']} size="lg" className="text-gray-300" />
                                </div>
                            </div>
                        
                        
                        
                            
                        </li>
                    ))}

                    {playlists.length === 0 &&
                        <li>
                            <div className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-center">
                                    <p className="text-sm font-light italic">
                                        No playlists created yet. Click Add playlist to create one. 
                                    </p>
                                </div>
                            </div>
                        </li>                    
                    }

                    {!props.items &&
                        <li>
                            <div className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-center">
                                    <p className="text-sm font-light italic">
                                        <FontAwesomeIcon icon={['fas', 'spinner']} spin size="lg" className="mr-2" />                                        
                                        Loading... 
                                    </p>
                                </div>
                            </div>
                        </li>                    
                    }


                </ul> */}
            </div>
            
            <div className="flex justify-end mt-5">
                {props.items &&
                    <button
                        type="button"
                        onClick={(ev) => { props.onCreateClicked(); ev.stopPropagation(); }}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} size="lg" className="mr-2" />
                        Add playlist
                    </button>
                }
            </div>
        </>
    )
}
