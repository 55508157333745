import React, { useState, useEffect, useReducer } from 'react';
import SegmentList from './SegmentList';
import ProductList from './ProductList';
import _ from "lodash";
import videojs from 'video.js';
import "video.js/dist/video-js.css";
const playbutton = require("../../images/play_white.svg");


export default (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);

    const [videoCoverVisible, setVideoCoverVisible] = useState(true);
    const [videoVisible, setVideoVisible] = useState(false);
    const [segments, setSegments] = useState(null);

    const [currentSegment, setCurrentSegment] = useState(null);
    const [player, setPlayer] = useState(null);
    const [canPlay, setCanPlay] = useState(true);
    const [descClamped, setDescClamped] = useState(true);

    const [, handlePlayerTimeUpdate] = useReducer((state, message) => {
        if (!segments?.length > 0) return;

        let segmentsCopy = _.cloneDeep(segments);
        segmentsCopy.filter(x => message < x.startTime).map(x => x._playheadPosition = 0);
        segmentsCopy.filter(x => message > x._endTime).map(x => x._playheadPosition = 1);
        let activeSegment = segmentsCopy.find(x => x.startTime < message && x._endTime > message);

        if (activeSegment) {
            activeSegment._playheadPosition = (message - activeSegment.startTime) / activeSegment._duration;
            if (currentSegment?.startTime !== activeSegment?.startTime) {
                setCurrentSegment(activeSegment);
                const element = document.getElementById(`segment_${activeSegment.startTime}`);
                element && element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center'} );
            }
        } else {
            setCurrentSegment(null);
        }

        setSegments(segmentsCopy);
    });

    // const [, handleCanPlayUpdate] = useReducer((state, message) => {
    //     debugger;
    //     setCanPlay(message);
    // });

    function jumpTo(time) {
        // az storage account blob-service-properties update --default-service-version 2020-04-08 -n howtofycontent
        // above required to enable range requests on azure blob storage
        player && player.currentTime(time);
        playVideo();
    }
    
    function playVideo() {
        setVideoCoverVisible(false);
        setVideoVisible(true);
        player && player.play();
    }

    useEffect(() => {
        if(props.clip) {
            if (videoRef?.current) {
                const videoElement = videoRef.current;
                if (!videoElement) return;

                const videoJsOptions = {
                    autoplay: false,
                    playsinline: true,
                    controls: true,
                    responsive: true,
                    //fluid: true,
                    aspectRatio: "16:9",
                    //poster: `/render/image?url=${props.clip?.landscapeThumbUrl}&width=1920`,
                    sources: [{
                        src: props?.clip.streamUrls[0],
                        type: 'application/x-mpegURL'
                    }],
                    preload: false
                }

                if (playerRef.current) {
                    playerRef.current.src(videoJsOptions.sources);
                    //playerRef.current.poster(videoJsOptions.poster);
                    //setVideoCoverVisible(true);
                } else {
                    playerRef.current = videojs(videoElement, videoJsOptions, function() {
                        this.on('timeupdate', function() { handlePlayerTimeUpdate(this.currentTime()); });
                        //this.on('canplay', function(e) { console.log(e.canplay); debugger; handleCanPlayUpdate(e.canplay);});
                        //this.on('click', function(evt) { debugger; if (this.paused()) this.play(); else this.pause();});
                    });
                    setPlayer(playerRef.current);
                }
            }            
        }
    }, [props.clip, videoRef]);

    useEffect(() => {
        if (props.clip) {
            setVideoCoverVisible(true);
            const segmentListElement = document.getElementById(`segmentlist`);
            segmentListElement && segmentListElement.scrollTo(0, 0);
            const productListElement = document.getElementById(`productlist`);
            productListElement && productListElement.scrollTo(0, 0);

            if (props.clip.segments && props.clip.segments.length > 0) {
                let segmentsCopy = [...props.clip.segments].sort(function(a, b) { return a.startTime < b.startTime ? -1 : 1});
                segmentsCopy.map((x, index) => {
                    if (index < segmentsCopy.length - 1) {
                        x._endTime = segmentsCopy[index + 1].startTime;
                        x._duration = x._endTime - x.startTime;
                        x._playheadPosition = 0;
                    } else {
                        x._endTime = props.clip.duration;
                        x._duration = x._endTime - x.startTime;
                        x._playheadPosition = 0;

                    }
                });
                setSegments(segmentsCopy);
            } else {
                setSegments(null);
            }                    
        }
    }, [props.clip]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    function segmentsOrProductsVisible() {
        if (props.hideSegments && props.hideProducts) return false;
        if ((!props.clip?.segments || props.clip?.segments.length === 0) && (!props.clip?.products || props.clip?.products.length === 0)) return false;
        return true;
    }

    return (
        <>
            {props.clip &&
                <div>
                    <div className="aspect-w-16 aspect-h-9">
                        <div className="w-full h-full overflow-hidden">
                            {/* <div className="w-full h-full absolute" style={{
                                background: `url(/render/image?url=${props.clip.landscapeThumbUrl}&width=1920)`,
                                backgroundSize: 'cover'
                            }} /> */}

                            <div className={`${videoVisible ? "" : "opacity-0"} w-full h-full absolute`}>
                                <video ref={videoRef} className="video-js vjs-big-play-centered" />
                            </div>
                            <div onClick={() => canPlay && playVideo()} className={`${videoCoverVisible ? '' : 'opacity-0 pointer-events-none' } opacity-fade absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-30`}>
                                {/* <img className="absolute" src={`/render/image?url=${props.clip?.landscapeThumbUrl}&width=1920`} alt="" /> */}
                                <img className="w-full" src={`/render/image?url=${props.clip.landscapeThumbUrl}&width=1920`} alt="" />
                                <div className="absolute w-20 h-20 lg:w-32 lg:h-32 backdrop-filter backdrop-blur-md bg-white bg-opacity-20 rounded-full border-white flex items-center justify-center">
                                    <img className="absolute w-1/3 ml-0.5" src={playbutton} alt="" />                        
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 lg:p-8 pb-0 lg:pb-0 bg-black bg-opacity-20" onClick={() => setDescClamped(!descClamped)}>
                        <p className="text-lg md:text-xl font-bold">{props.clip.name}</p>
                        <p className={`text-sm lg:text-base cursor-pointer ${descClamped ? 'line-clamp-1' : ''}`}>{props.clip.description}</p>
                    </div>

                    {segmentsOrProductsVisible() && 
                        <div className="py-4 space-y-4 bg-black bg-opacity-20">
                            {!props.hideSegments && props.clip.segments.length > 0 &&
                                <SegmentList items={segments} currentSegment={currentSegment} onSegmentSelected={(segment) => jumpTo(segment.startTime)} />
                            }

                            {!props.hideProducts && props.clip.products.length > 0 &&
                                <>
                                    <p className="px-4 text-sm uppercase">Produkter i filmen</p>
                                    <ProductList client={props.client} items={props.clip.products} linkPrefix={`/prod/${props.client.pid}`} />
                                </>
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
}