import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import PageHeader from './PageHeader';
import ClipPlayer from './ClipPlayer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CookieConsentBar from './CookieConsentBar';
// import PoweredByBar from './PoweredByBar';
import PlayerUtils from '../Utils/PlayerUtils';
import ItemList from './ItemList';
import Playlists from './Playlists';
import gtag, { install } from 'ga-gtag';

const logo = require("../../images/howtofy_logo_white_RGB.svg");

const useQuery= () => {
    return new URLSearchParams(useLocation().search);
}

export default (props) => {
    const history = useHistory();

    const [channel, setChannel] = useState(null);
    const [playlist, setPlaylist] = useState(null);
    const [selectedClip, setSelectedClip] = useState(null);
    const [selectedClipPlaylists, setSelectedClipPlaylists] = useState(null);
    const [otherPlaylists, setOtherPlaylists] = useState(null);
    const [hideHeader, setHideHeader] = useState(false);
    const [hideLogo, setHideLogo] = useState(false);
    const [hideSegments, setHideSegments] = useState(false);
    const [hideProducts, setHideProducts] = useState(false);
    const [hideCookieConsent, setHideCookieConsent] = useState(false);

    const params = props.match.params;
    const query = useQuery();
    const prevSelectedClipRef = useRef();
    const selectedClipRef = useRef();
    const channelRef = useRef();

    useEffect(() => {
        install(window.__gaId);
        if (getChannelUrl() && params.playlistUrl) { 
            loadData(getChannelUrl());
        }
        setHideHeader(query.get('header') === '0');
        setHideLogo(query.get('logo') === '0');
        setHideSegments(query.get('segments') === '0');
        setHideProducts(query.get('products') === '0');
        setHideCookieConsent(query.get('cookieconsent') === '0');
    }, []);

    useEffect(() => {
        channelRef.current = channel;
        if (channel) {
            const pl = channel.playlists.find(x => x.pid == params.playlistUrl);
            setPlaylist(pl);
            const cl = pl.clips.find(x => x.pid === (params.clipUrl ?? pl.clips[0].pid));
            setSelectedClip(cl);
            const selClipPlaylists = [...cl.playlists].filter(x => x.pid !== pl.pid);
            setSelectedClipPlaylists(selClipPlaylists);
            //const otherPlaylists = [...channel.playlists].filter(cp => !selClipPlaylists.find(x => x.id === cp.id) && cp.id !== pl.id);
            const otherPlaylists = [...channel.playlists].filter(cp => !selClipPlaylists.find(x => x.pid === cp.pid) && cp.pid !== pl.pid);
            setOtherPlaylists(otherPlaylists);

            document.getElementsByTagName('head')[0].appendChild(PlayerUtils.getStyleElement(channel));

            let data = {
                channelId: channel.pid,
                channelName: channel.name,
                playlistId: pl.pid,
                playlistName: pl.name,
                videoId: cl.pid,
                videoName: cl.name
            };
            const qr = new URLSearchParams(window.location.search).get('qr');
            if (qr) {
                data.qrCode = qr;
                gtag('event', 'Code Scan', { 
                    qrCode: qr,
                    codeType: 'Playlist',
                    channelId: channel.pid,
                    channelName: channel.name,
                    playlistId: pl.pid,
                    playlistName: pl.name,
                    videoId: cl.pid,
                    videoName: cl.name
                });
            }

            gtag('event', 'Playlist Page View', data);
        }
    }, [channel]);

    useEffect(() => {
        // selectedClipRef.current = selectedClip;
        window.scrollTo({top: 0, behavior: 'smooth'});
        // if (selectedClip && prevSelectedClipRef) {
        //     if (prevSelectedClipRef.current) {
        //         debugger;
        //         let url = window.location.href;
        //         url = url.substring(url.indexOf('://') + 3);
        //         url = url.substring(url.indexOf('/p/'));
        //         url = url.replace(prevSelectedClipRef.current.pid, selectedClip.pid);
        //         history.push(url);
        //     }
        //     prevSelectedClipRef.current = selectedClip;
        // }
    }, [selectedClip]);

    const setSelectedClipAndNavigate = (clip) => {
        if(selectedClip) {
            let url = window.location.href;
            url = url.substring(url.indexOf('://') + 3);
            url = url.substring(url.indexOf('/p/'));
            url = url.replace(selectedClip.pid, clip.pid);
            history.push(url);
        }
        setSelectedClip(clip);
    }

    // useEffect(() => {
    //     const unlisten = history.listen((location) => {
    //         const clipPid = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    //         if (selectedClipRef && selectedClipRef.current?.pid !== clipPid && channelRef) {
    //             const pl = channelRef.current.playlists.find(x => x.pid == params.playlistUrl);
    //             const cl = pl.clips.find(x => x.pid === clipPid);
    //             setSelectedClip(cl);
    //         }
    //     });
    //     return function cleanup() {
    //         unlisten();
    //     }
    // }, []);

    useEffect(() => {
        const func = (ev) => {
            const clipPid = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
            const pl = channelRef.current.playlists.find(x => x.pid == params.playlistUrl);
            const cl = pl.clips.find(x => x.pid === clipPid);
            setSelectedClip(cl);

            setTimeout(() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }, 100);
        }
        window.addEventListener('popstate', func);
        return () => {
            window.removeEventListener('popstate', func);
        }
    }, []);
      
    function getChannelUrl() {
        return props.customChannelUrl ?? params.channelUrl;
    }

    function loadData(channelUrl) {
        PlayerUtils.loadPublicDataChannel(channelUrl).then(response => response.json().then(data => {
            setChannel(PlayerUtils.processReferences(data));
        }));
    }

    function getPlaylistLinkPrefix() {
        if (props.customChannelUrl) return '/p';
        return `/p/${channel?.pid}`;
    }

    return (
        <>
            <div className={`absolute w-screen h-screen bg-black z-30 opacity-fade flex flex-col items-center justify-center pointer-events-none ${playlist ? 'opacity-0' : ''}`}>
                {!hideLogo &&
                    <>
                        {/* <img className="px-12 sm:px-0 sm:max-w-lg" src={logo} alt="Howtofy" /> */}
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin={true} size="lg" className="text-gray-800" />
                    </>
                }
            </div>
            <div className="flex flex-col min-h-screen howtofy-text-color howtofy-background-color pb-16">
                {!hideHeader &&
                    <PageHeader client={channel} clip={selectedClip} customChannelUrl={props.customChannelUrl} />
                }
                <div className={`${!hideHeader ? 'mt-16' : 'mt-0'} w-full max-w-full sm:max-w-6xl mx-auto min-h-96 flex flex-col justify-center`}>
                    <ClipPlayer client={channel} clip={selectedClip} hideSegments={hideSegments} />
                </div>

                <div className="w-full max-w-6xl mx-auto">
                    {/* <div className="px-4 xl:px-0 py-12 lg:p-20 space-y-5">
                        <p className="text-2xl md:text-4xl font-bold">{selectedClip?.name ?? playlist?.name}</p>
                        <p className="text-xl">{selectedClip ? selectedClip.description : playlist?.description}</p>
                    </div>                    
 */}
                    <div className="p-4 flex items-center space-x-2">
                        <FontAwesomeIcon icon={['fas', 'clipboard-list' ]} size="lg" className="howtofy-text-color opacity-50" />
                        <p className="text-2xl font-bold howtofy-text-color">{playlist?.name}</p>
                    </div>

                    <div className="">
                        <ItemList 
                            direction="vertical" 
                            hideTitle={true} 
                            name={playlist?.name} 
                            items={playlist?.clips} 
                            selectedItem={selectedClip} 
                            onClick={(item) => { setSelectedClipAndNavigate(item); } }
                        />

                        {selectedClip && selectedClipPlaylists && selectedClipPlaylists.length > 0 &&
                            <>
                                <div className="opacity-50">
                                    <p className="px-3 mt-5 mb-2 text-sm uppercase bg-white bg-opacity-20 border-b howtofy-border-color">Relaterade spellistor</p>
                                </div>
                                <Playlists items={selectedClipPlaylists} linkPrefix={getPlaylistLinkPrefix()} />
                            </>
                        }
                        {channel && otherPlaylists && otherPlaylists.length > 0 &&
                            <>
                                <div className="opacity-50">
                                    <p className="px-3 mt-5 mb-2 text-sm uppercase bg-white bg-opacity-20 border-b howtofy-border-color">Övriga spellistor</p>
                                </div>
                                <Playlists items={otherPlaylists} linkPrefix={getPlaylistLinkPrefix()} />
                            </>
                        }
                    </div>
                </div>

                {/* <PoweredByBar /> */}
            </div>

            {!hideCookieConsent &&
                <CookieConsentBar />
            }
        </>
    )
}
