export class Utils {
    
    getBaseUrl() { return '/api'; }
    getInstancesUrl() { return `${this.getBaseUrl()}/instances`; }
    getInstanceUrl(instanceId) { return `${this.getInstancesUrl()}/${instanceId}`; }
    
    getClientsUrl(instanceId) { return `${this.getInstancesUrl()}/${instanceId}/clients`; }
    getClientUrl(instanceId, clientId) { return `${this.getClientsUrl(instanceId)}/${clientId}`; }
    getClientDataUrl(instanceId, clientId) { return `${this.getInstanceUrl(instanceId)}/admindata/${clientId}`; }
    getNewClientTemplateUrl(instanceId) { return `${this.getClientsUrl(instanceId)}/get-new-client-template`; }
    getEncodeClientHeroVideoUrl(instanceId, clientId) { return `${this.getClientUrl(instanceId, clientId)}/encode`; }
    getUploadClientHeroVideoUrl(instanceId, clientId) { return `${this.getClientUrl(instanceId, clientId)}/get-upload-url`; }
    getFinalizeClientHeroVideoUploadUrl(instanceId, clientId) { return `${this.getClientUrl(instanceId, clientId)}/finalize-upload`; }

    getClientGroupsUrl(instanceId) { return `${this.getInstancesUrl()}/${instanceId}/client-groups`; }
    getClientGroupUrl(instanceId, clientGroupId) { return `${this.getClientGroupsUrl(instanceId)}/${clientGroupId}`; }

    getClipsUrl(instanceId, clientId) { return `${this.getClientUrl(instanceId, clientId)}/videos`; }
    getClipUrl(instanceId, clientId, clipId) { return `${this.getClipsUrl(instanceId, clientId)}/${clipId}`; }
    getEncodeClipUrl(instanceId, clientId, clipId) { return `${this.getClipUrl(instanceId, clientId, clipId)}/encode`; }
    getUploadClipUrl(instanceId, clientId, clipId) { return `${this.getClipUrl(instanceId, clientId, clipId)}/get-upload-url`; }
    getFinalizeClipUploadUrl(instanceId, clientId, clipId) { return `${this.getClipUrl(instanceId, clientId, clipId)}/finalize-upload`; }

    getPlaylistsUrl(instanceId, clientId) { return `${this.getClientUrl(instanceId, clientId)}/playlists`; }
    getPlaylistUrl(instanceId, clientId, playlistId) { return `${this.getPlaylistsUrl(instanceId, clientId)}/${playlistId}`; }
    getSetPlaylistsOrderUrl(instanceId, clientId) { return `${this.getPlaylistsUrl(instanceId, clientId)}/set-order`; }
    
    getProductsUrl(instanceId, clientId) { return `${this.getClientUrl(instanceId, clientId)}/products`; }
    getProductUrl(instanceId, clientId, productId) { return `${this.getProductsUrl(instanceId, clientId)}/${productId}`; }
    
    getClientGroupClipsUrl(instanceId, clientGroupId, clientId) { return `${this.getClientGroupUrl(instanceId, clientGroupId)}/${clientId}/clips`}
    getClientGroupPlaylistsUrl(instanceId, clientGroupId, clientId) { return `${this.getClientGroupUrl(instanceId, clientGroupId)}/${clientId}/playlists`}
    getClientGroupProductsUrl(instanceId, clientGroupId, clientId) { return `${this.getClientGroupUrl(instanceId, clientGroupId)}/${clientId}/products`}

    loadInstances() {
        return fetch(this.getInstancesUrl());
    }

    loadClients(instanceId) {
        return fetch(this.getClientsUrl(instanceId));
    }

    loadClientData(instanceId, clientId) {
        return fetch(this.getClientDataUrl(instanceId, clientId));
    }

    loadNewClientTemplate(instanceId) {
        return fetch(this.getNewClientTemplateUrl(instanceId));
    }

    loadPlaylists(instanceId, clientId) {
        return fetch(this.getPlaylistsUrl(instanceId, clientId));
    }
    
    loadPlaylist(instanceId, clientId, playlistId) {
        return fetch(this.getPlaylistUrl(instanceId, clientId, playlistId));
    }
    
    loadProducts(instanceId, clientId) {
        return fetch(this.getProductsUrl(instanceId, clientId));
    }
    
    loadClips(instanceId, clientId) {
        return fetch(this.getClipsUrl(instanceId, clientId));
    }

    loadClip(instanceId, clientId, clipId) {
        return fetch(this.getClipUrl(instanceId, clientId, clipId));
    }

    encodeClip(instanceId, clientId, clipId) {
        return fetch(this.getEncodeClipUrl(instanceId, clientId, clipId));
    }

    getClientHeroVideoUploadUrl(instanceId, clientId) {
        return fetch(this.getUploadClientHeroVideoUrl(instanceId, clientId));
    }

    getClipUploadUrl(instanceId, clientId, clipId) {
        return fetch(this.getUploadClipUrl(instanceId, clientId, clipId));
    }

    loadClientGroupClips(instanceId, clientGroupId, clientId) {
        return fetch(this.getClientGroupClipsUrl(instanceId, clientGroupId, clientId));
    }

    loadClientGroupPlaylists(instanceId, clientGroupId, clientId) {
        return fetch(this.getClientGroupPlaylistsUrl(instanceId, clientGroupId, clientId));
    }

    loadClientGroupProducts(instanceId, clientGroupId, clientId) {
        return fetch(this.getClientGroupProductsUrl(instanceId, clientGroupId, clientId));
    }

    createRequest(method, data) {
        let request = {
            method: method
        }
        if (data) {
            request.headers = {
              'Content-Type': 'application/json'
            };
            request.body = JSON.stringify(data);
        }
        return request;
    }


    classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    WorkerStatus = {
        Idle: 0,
        Working: 1,
        Complete: 2,
        Cancelled: 3,
        Failed: 4
    }    

    // updateObj(item, field, value) {
    //     let obj = item;
    //     if (field.indexOf('.') === -1) {
    //         editingItem[field] = value;
    //     } else {
    //         let fields = field.split('.');
    //         let existTest = obj;
    //         fields.map(x => {
    //             if (existTest[x] === undefined) existTest[x] = {};
    //             existTest = existTest[x];
    //         });
    //         const lastField = fields[fields.length - 1];
    //         fields = _.take(fields, fields.length - 1);
    //         fields.map(x => obj = obj[x]);
    //         obj[lastField] = value;
    //     }
    //     forceUpdate();
    // }

    processClientDataReferences(clientData) {
        clientData.playlists.map(playlist => {
            playlist = this.processPlaylist(playlist, clientData.client, clientData.clips);
            // playlist.__clips = playlist.clipIds.map(id => clientData.clips.find(x => x.id === id));
            // playlist.__client = clientData.client;
        });

        clientData.products.map(product => {
            product = this.processProduct(product, clientData.client, clientData.clips);
            // product.__clips = clientData.clips.filter(x => x.productIds.indexOf(product.id) !== -1);
            // product.__client = clientData.client;
        });

        clientData.clips.map(clip => {
            clip = this.processClip(clip, clientData.client, clientData.playlists, clientData.products);
            // clip.__playlists = clientData.playlists.filter(x => x.clipIds.indexOf(clip.id) !== -1);
            // clip.__products = clip.productIds.map(id => clientData.products.find(x => x.id === id));
            // clip.__client = clientData.client;
        });

        clientData.client.__playlists = clientData.playlists;
        clientData.client.__clips = clientData.clips;
        clientData.client.__products = clientData.products;

        return clientData;
    }

    processPlaylist(playlist, client, clientClips) {
        playlist.clipIds = playlist.clipIds ?? [];
        playlist.__clips = playlist.clipIds.map(id => clientClips.find(x => x.id === id));
        playlist.__client = client;
        return playlist;
    }

    processProduct(product, client, clientClips) {
        product.__clips = clientClips.filter(x => x.productIds.indexOf(product.id) !== -1);
        product.__client = client;
        return product;
    }

    processClip(clip, client, clientPlaylists, clientProducts) {
        clip.__playlists = clientPlaylists.filter(x => x.clipIds.indexOf(clip.id) !== -1);
        clip.__products = clip.productIds.map(id => clientProducts.find(x => x.id === id));
        clip.__client = client;
        return clip;
    }

    sanitizeObject(obj) {
        let res = {};
        for (const prop in obj) {
            let skip = false;
            if (prop.indexOf('__') === 0) skip = true;
            if (Array.isArray(obj[prop]) && prop !== 'socMediaChannels') skip = true;

            if (!skip)
                res[prop] = obj[prop];
        }
        return res;
    }

    
}

const utils = new Utils();

export default utils;
