import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone'; //https://github.com/react-dropzone/react-dropzone
export default (props) => {
    return (

        <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white flex justify-evenly p-3">
            <div className="w-48">
                <img src={props.image} className="object-cover mx-auto max-w-48 max-h-48" alt="" />
                {props.image &&
                    <button
                        type="button"
                        onClick={() => { props.onSetImage(null); props.onSetImageFileRemoved(true); }}
                        className="block mt-2 mx-auto bg-white py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                        Remove file
                    </button>
                }

            </div>

            <Dropzone onDrop={(files) => props.onSetImageFile(files[0])}>
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md w-48 h-48">
                        <div className="space-y-1 text-center">
                            <FontAwesomeIcon icon={['fas', 'image']} size="2x" className="text-gray-400" />
                            <div className="flex text-sm text-gray-600">
                                <label
                                    className="relative bg-white rounded-md"
                                >
                                    <span className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload an image</span>
                                    <span className="pl-1">or drag and drop</span>
                                </label>
                                
                            </div>
                            <p className="text-xs text-gray-500">PNG, JPG, GIF or SVG</p>
                        </div>                                                            
                        <input {...getInputProps()} id="landscape-thumb-upload" name="landscape-thumb-upload" type="file" accept=".gif,.jpg,.jpeg,.png,.svg" className="sr-only" />
                    </div>                                                    
                )}                                            
            </Dropzone>
        </div>
    )
}
