import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRSticker from "./QRSticker";
import _ from "lodash";

export default (props) => {
    const [stickers, setStickers] = useState([]);

    useEffect(() => {
        if (props.client.properties?.qr_sticker_backdrops) {
            const backdrops = props.client.properties.qr_sticker_backdrops?.split(',');
            const overlays = props.client.properties.qr_sticker_overlays?.split(',');
            const colors = props.client.properties.qr_sticker_colors?.split(',');
            const widths = props.client.properties.qr_sticker_widths?.split(',');
            const heights = props.client.properties.qr_sticker_heights?.split(',');
            const sizes = props.client.properties.qr_sticker_sizes?.split(',');
            const transforms = props.client.properties.qr_sticker_transforms?.split(',');
            let stickerArray = [];
            for(let i = 0; i < backdrops.length; i++) {
                stickerArray.push({ 
                    backdrop: backdrops[i], 
                    overlay: overlays[i], 
                    color: colors[i],
                    width: widths[i],
                    height: heights[i],
                    size: sizes[i],
                    transform: transforms[i]
                });
            }
            setStickers(stickerArray);
        } else {
            setStickers([
                { 
                    backdrop: '/qrlogos/generic_qrsticker_bg.svg', 
                    overlay: '/qrlogos/generic_qrsticker_overlay.svg', 
                    color: '#000000',
                    width: 475,
                    height: 259,
                    size: 173,
                    transform: 't255 43'
                }
            ]);
        }
    }, []);

    function getServerUrl() {
        return window.location.protocol + '//' + window.location.host;
    }

    function getFullUrl(itemUrl) {
        const url = window.location.protocol + '//' + window.location.host;
        return `${url}/${getRelativeUrl(itemUrl)}`;
    }
    
    function getRelativeUrl(itemUrl) {
        return `prod/${props.client.customUrl}/${itemUrl}`;
    }

    function getQrLinkUrl(qrLink) {
        return `qr/r/${qrLink}`;
    }
    
    function getQrLinkFullUrl(qrLink) {
        return `${getServerUrl()}/qr/r/${qrLink}`;
    }

    function getQrValue(client, qrLink) {
        if (client.customDomain) {
            return `https://${client.customDomain}/${getQrLinkUrl(qrLink)}`;
        }
        return getQrLinkFullUrl(qrLink);
    }

    function getItemUrl(client, item) {
        if (item.moreInfoUrl) return item.moreInfoUrl;
        if (item.shopUrl) return item.shopUrl;

        if (client.customDomain) {
            return `https://${client.customDomain}/prod/${item.url}`;
        }
        
        return getFullUrl(item.url);
    }

    return (
        <div className="py-6 bg-white max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex justify-between">
                <h1 className="text-2xl font-semibold text-gray-900">{props.item?.name}</h1>
                <button
                    type="button"
                    onClick={(ev) => { props.onEditClicked(props.item); ev.stopPropagation(); }}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                >
                    <FontAwesomeIcon icon={['fas', 'pencil-alt']} size="lg" className="mr-2" />
                    Edit
                </button>
            </div>
            <div className="bg-blue-50 shadow sm:rounded-lg my-8">
                <div className="px-4 py-5 sm:p-6">
                    <div className="mt-2 text-sm text-gray-500">
                        <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">

                            <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Product URL</dt>
                                <dd className="text-gray-900"><a href={getItemUrl(props.client, props.item)} target="_blank" className="text-indigo-700">{getItemUrl(props.client, props.item)}</a></dd>
                            </div>

                            <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Product QR Link</dt>
                                <dd className="text-gray-900"><a href={getQrValue(props.client, props.item.qrLink)} target="_blank" className="text-indigo-700">{getQrValue(props.client, props.item.qrLink)}</a></dd>
                            </div>

                            {stickers && stickers.map((sticker, index) => {
                                return (
                                    <div key={index} className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">QR Code</dt>
                                        <dd className="text-gray-900">
                                            <QRSticker 
                                                value={getQrValue(props.client, props.item.qrLink)} 
                                                // width={400 + 25 * 3} 
                                                // height={209 + 25 * 2} 
                                                // qrsize={173}
                                                width={sticker.width}
                                                height={sticker.height} 
                                                qrsize={sticker.size}
                                                transform={sticker.transform}
                                                color={sticker.color}
                                                backdrop={sticker.backdrop} 
                                                overlay={sticker.overlay} 
                                                filename={`${props.item.name} Playlist Page QR Code.svg`}
                                            />
                                        </dd>
                                    </div>
    
                                );
                            })}


                            {/* 
                            <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">QR Code</dt>
                                <dd className="text-gray-900">
                                    <QRSticker 
                                        value={props.item.qrLink} 
                                        width={400 + 25 * 3} 
                                        height={209 + 25 * 2} 
                                        qrsize={173}
                                        color="#000000"
                                        backdrop="/qrlogos/plannja_qrsticker_v1_bg.svg" 
                                        overlay="/qrlogos/plannja_qrsticker_v1_overlay.svg" 
                                        filename={`${props.item.name} Product Page QR Code.svg`}
                                    />

                                </dd>
                            </div> */}
                        </dl>
                    </div>
                </div>
            </div>            
            
            <div className="flex">
                {/* {props.links.map((section) => (
                    <span
                        key={section.name}
                        onClick={(ev) => { props.onSectionSelected(section); ev.stopPropagation(); }}
                        className='text-indigo-700 hover:text-indigo-900 group flex items-center py-2 text-base font-medium mr-5 cursor-pointer'
                    >
                        <FontAwesomeIcon icon={section.icon} size="lg" className="mr-3 text-gray-400" />
                        {section.name}
                    </span>
                ))} */}
            </div>
        </div>
    )
}
