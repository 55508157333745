/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from 'react';
import CookieConsent from "react-cookie-consent";

export default () => {

    return (
        <CookieConsent
            location="bottom"
            buttonText="I accept"
            cookieName="cookieConsent"
            style={{ background: "rgba(255,255,255,0.85)" }}
            disableButtonStyles={true}
            buttonClasses="inline-flex mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            expires={350}
            >
                <p className="text-gray-600">
                    <span className="font-bold">This website uses cookies. </span>
                    <span>Cookies are used to personalize content on the website and to analyze traffic. </span>
                    {/* <a className="underline" href="">Read more about our cookie policy here.</a> */}
                </p>
        </CookieConsent>            
    )
}
