import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerUtils from '../Utils/PlayerUtils';

export default (props) => {
    const [playheadWidth, setPlayheadWidth] = useState(0);

    useEffect(() => {
        if(props.item?._playheadPosition) {
            setPlayheadWidth(props.item._playheadPosition * 100);
        } else {
            setPlayheadWidth(0);
        }
    }, [props.item]);

    return (
        <div id={`segment_${props.item.startTime}`} onClick={() => props.onSegmentSelected(props.item)} className="relative flex flex-cols items-center space-x-2 cursor-pointer whitespace-nowrap bg-white bg-opacity-10 rounded-md pl-1 pr-4 py-3 w-52 md:w-60 text-xs md:text-sm">
            <div className="absolute left-0 top-0 bottom-0 rounded-md bg-white bg-opacity-10 animate-width" style={{width: `${playheadWidth}%`}}></div>
            <div className="flex flex-cols w-full space-x-2">
                <p className="flex-shrink-0 border border-white border-opacity-50 w-6 h-6 rounded-full p-2 flex items-center justify-center">
                    <span>{props.index}</span>
                </p>
                <p className="truncate flex-1 flex items-center">{props.item.title}</p>
                {/* <p className="whitespace-nowrap text-xs opacity-70 mt-0.5">{PlayerUtils.formatDuration(props.item.startTime)}</p> */}
                <div className="flex-shrink-0 flex items-center">
                    <FontAwesomeIcon icon={['fas', 'step-forward']} className="" />
                </div>
            </div>
        </div>
    )
}

