import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerUtils from '../Utils/PlayerUtils';

export default (props) => {
    let aspect = "aspect-w-4 aspect-h-5 md:aspect-w-16 md:aspect-h-9";
    switch(props.aspect) {
        case "portrait":
            aspect = "aspect-w-4 aspect-h-5";
            break;
        case "landscape":
            aspect = "aspect-w-16 aspect-h-9";
            break;
        case "square":
            aspect = "aspect-w-1 aspect-h-1";
            break;
        }

    return (
        <div className={`relative overflow-hidden rounded-xl ${aspect}`}>
            <div>
                <img className="rounded-xl object-cover w-full h-full" src={`/render/image?url=${props.thumbUrl}&width=300`} alt={props.title} />
                <div className="absolute left-0 right-0 bottom-0">
                    {(props.title || props.duration) &&
                        <div className="absolute left-0 right-0 bottom-0 bg-gradient-to-t from-black p-2 space-y-2">
                            {props.title &&
                            <p className="pt-8 text-white text-sm line-clamp-2">
                                {props.title}
                            </p>
                            }
                            {props.duration &&
                                <p className="text-white text-xs opacity-70">
                                    {PlayerUtils.formatDuration(props.duration)}
                                </p>
                            }
                        </div>
                    }
                </div>                    
                {props.showPlayIcon &&
                    <div className="absolute left-0 top-0 p-2 text-white text-xs">
                        <FontAwesomeIcon icon={['fas', 'play']} />
                    </div>
                }
            </div>
        </div>
    )
}

