import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';
import App from './App';

const container = document.getElementById('root');
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>
);

//import registerServiceWorker from './registerServiceWorker';
//import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const rootElement = document.getElementById('root');

//throw "Logging an error...";


// ReactDOM.render(
//   <BrowserRouter basename={baseUrl}>
//     <App />
//   </BrowserRouter>,
//   rootElement);

//registerServiceWorker();

