import React from 'react';
import { Route } from 'react-router-dom';
//import { useRecoilValue } from 'recoil';

//import { authAtom } from '_state';

export { PrivateRoute };

function PrivateRoute({ component: Component, ...rest }) {
    //const auth = useRecoilValue(authAtom);
    const val = document.cookie.split('; ').find(x => x.startsWith('is_logged_in='));
    const auth = val !== undefined;

    return (
        <Route {...rest} render={props => {
            if (!auth) {
                // not logged in so redirect to login page with the return url
                //return <Redirect to={{ pathname: '/identity/account/login', state: { from: props.location } }} />
                window.location.href = `/login?returnUrl=${props.location.pathname}`;
                return;
            }

            // authorized so return component
            return <Component {...props} />
        }} />
    );
}