import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from './PageHeader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CookieConsentBar from './CookieConsentBar';
import PoweredByBar from './PoweredByBar';
import PlayerUtils from '../Utils/PlayerUtils';
import Playlists from './Playlists';
import gtag, { install } from 'ga-gtag';

const logo = require("../../images/howtofy_logo_white_RGB.svg");

const useQuery= () => {
    return new URLSearchParams(useLocation().search);
}

export default (props) => {
    const [channel, setChannel] = useState(null);
    const [product, setProduct] = useState(null);
    const [hideHeader, setHideHeader] = useState(false);
    const [hideLogo, setHideLogo] = useState(false);
    const [hideCookieConsent, setHideCookieConsent] = useState(false);

    const params = props.match.params;
    const query = useQuery();

    useEffect(() => {
        install(window.__gaId);

        if (getChannelUrl() && params.productUrl) loadData(getChannelUrl());
        setHideHeader(query.get('header') === '0');
        setHideLogo(query.get('logo') === '0');
        setHideCookieConsent(query.get('cookieconsent') === '0');
    }, []);

    useEffect(() => {
        if (channel) {
            const pr = channel.products.find(x => x.pid == params.productUrl);
            setProduct(pr);
            document.getElementsByTagName('head')[0].appendChild(PlayerUtils.getStyleElement(channel));  
            
            let data = {
                channelId: channel.pid,
                channelName: channel.name,
                productId: pr.pid,
                productName: pr.name
            };
            const qr = new URLSearchParams(window.location.search).get('qr');
            if (qr) {
                data.qrCode = qr;
                gtag('event', 'Code Scan', { 
                    qrCode: qr,
                    codeType: 'Product',
                    channelId: channel.pid,
                    channelName: channel.name,
                    productId: pr.pid,
                    productName: pr.name
                });
            }
            gtag('event', 'Product Page View', data);
            
        }
    }, [channel]);

    function getChannelUrl() {
        return props.customChannelUrl ?? params.channelUrl;
    }

    function loadData(channelUrl) {
        PlayerUtils.loadPublicDataChannel(channelUrl).then(response => response.json().then(data => {
            setChannel(PlayerUtils.processReferences(data));
        }));
    }

    return (
        <>
            <div className={`absolute w-screen h-screen bg-black z-30 opacity-fade flex flex-col items-center justify-center pointer-events-none ${product ? 'opacity-0' : ''}`}>
                {!hideLogo &&
                    <>
                        <img className="px-12 sm:px-0 sm:max-w-lg" src={logo} alt="Howtofy" />
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin={true} size="lg" className="text-gray-300" />
                    </>
                }
            </div>
            <div className="flex flex-col min-h-screen howtofy-text-color howtofy-background-color pb-16">
                {!hideHeader &&
                    <PageHeader client={channel} customChannelUrl={props.customChannelUrl} />
                }
                <div className={`${!hideHeader ? 'mt-20' : 'mt-0'} w-full max-w-full sm:max-w-6xl mx-auto min-h-96 flex flex-col justify-center`}>
                    <div className="md:grid md:grid-cols md:grid-cols-2 md:gap-8">
                        <div className="flex justify-center">
                            <img className="object-contain w-full" src={`/render/image?url=${product?.thumbnailUrl}&width=300`} alt={product?.name}></img>
                        </div>
                        <div className="space-y-2 p-8 md:p-0">
                            <p className="text-2xl font-bold">{product?.name}</p>
                            <p className="text-xl">{product?.description}</p>
                            {product?.shopUrl &&                                                    
                                <a href={product?.shopUrl} target="_blank" className="flex items-center space-x-2">
                                    <FontAwesomeIcon icon={['fas', 'shopping-cart']} className="opacity-70" />
                                    <p>{product?.shopUrl}</p>
                                </a>
                            }
                            {product?.moreInfoUrl &&
                                <a href={product?.moreInfoUrl} target="_blank" className="flex items-center space-x-2">
                                    <FontAwesomeIcon icon={['fas', 'info-circle']} className="opacity-70" />
                                    <p>{product?.moreInfoUrl}</p>
                                </a>
                            }
                        </div>
                    </div>
                </div>

                <PoweredByBar />                
            </div>

            {!hideCookieConsent &&
                <CookieConsentBar />
            }
        </>
    )
}
