import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerUtils from '../Utils/PlayerUtils';
import PoweredByBar from "./PoweredByBar";

export default (props) => {
    function ensureStartsWith(str, value) {
        if (value.indexOf(str) === 0) return value;
        return str + value;
    }

    return (

        <div className="flex flex-col h-full">
            <div className="flex-1 overflow-y-auto space-y-6 pb-6">
                <div>
                    {props.client?.contactInfo?.address &&
                        <div>
                            <pre className="font-sans">{props.client.contactInfo.address}</pre>
                        </div>
                    }
                </div>

                <div>
                    {props.client?.contactInfo?.email &&
                        <div className="flex items-center space-x-2">
                            <FontAwesomeIcon icon={['fas', 'envelope']} size="lg" className="opacity-30" />
                            <a target="_blank" href={ensureStartsWith('mailto:', props.client.contactInfo.email)}>
                                <pre className="font-sans">{props.client.contactInfo.email}</pre>
                            </a>
                        </div>
                    }
                    {props.client?.contactInfo?.phone &&
                        <div className="flex items-center space-x-2">
                            <FontAwesomeIcon icon={['fas', 'phone']} size="lg" className="opacity-30" />
                            <pre className="font-sans">{props.client.contactInfo.phone}</pre>
                        </div>
                    }
                    {props.client?.contactInfo?.url &&
                        <div className="flex items-center space-x-2">
                            <FontAwesomeIcon icon={['fas', 'link']} size="lg" className="opacity-30" />
                            <a target="_blank" href={ensureStartsWith('https://', props.client.contactInfo.url)}>
                                <pre className="font-sans">{props.client.contactInfo.url}</pre>
                            </a>
                        </div>
                    }
                </div>

                <div>
                    {props.client?.socialMediaChannels &&
                        <ul>
                            {props.client.socialMediaChannels.map(channel => {
                                return(
                                    <li className="flex items-center space-x-2">
                                        <FontAwesomeIcon icon={PlayerUtils.getSocMediaTypes().find(x => x.name === channel.type).icon} size="lg" className="opacity-30" />
                                        <a target="_blank" href={channel.url}>
                                            <pre className="font-sans">{channel.name}</pre>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    }
                </div>

                <div>
                    <ul>
                    {props.client?.legalTerms.termsAndConditions &&
                            <li className="flex items-center space-x-2">
                                <a target="_blank" href={`/legal/terms-and-conditions/${props.client?.pid}`}>
                                    <pre className="font-sans">Terms & Conditions</pre>
                                </a>
                            </li>
                        }
                        {props.client?.legalTerms.cookieTerms &&
                            <li className="flex items-center space-x-2">
                                <a target="_blank" href={`/legal/cookie-policy/${props.client?.pid}`}>
                                    <pre className="font-sans">Cookie Policy</pre>
                                </a>
                            </li>
                        }
                        {props.client?.legalTerms.privacyTerms &&
                            <li className="flex items-center space-x-2">
                                <a target="_blank" href={`/legal/privacy-policy/${props.client?.pid}`}>
                                    <pre className="font-sans">Privacy Policy</pre>
                                </a>
                            </li>
                        }
                    </ul>
                </div>                
            </div>            
            
            <div>
                <PoweredByBar />
            </div>
        </div>
    )
}

