import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone'; //https://github.com/react-dropzone/react-dropzone
import _ from "lodash";
import Utils from '../Utils/Utils';
import Tabs from './Tabs';
import ImageUpload from './ImageUpload';
import PropertiesEditor from "./PropertiesEditor";
import {DraggableAreasGroup} from 'react-draggable-tags'; //https://ygyooo.github.io/react-draggable-tags/

export default (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [editingItem, setEditingItem] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [availableClients, setAvailableClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    
    const group = new DraggableAreasGroup();
    const ClientGroupsDraggableAreaFrom = group.addArea(); 
    const ClientGroupsDraggableAreaTo = group.addArea(); 
        
    
    useEffect(() => {
        if (props?.item) {
            if (props.item.id) {
                setEditingItem(props.item);
            }
        }
        //Available clients are any clients that are not already members of any group
        setAvailableClients([...props.clients.filter(x => !x.clientGroupId)].sort(function(a, b) { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1}));
        if (props.item?.id)
            setSelectedClients([...props.clients.filter(x => x.clientGroupId == props.item.id)].sort(function(a, b) { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1}));
    }, []);


    function saveClientGroup(clientGroup, saveAndExit = false) {
        const isCreating = !clientGroup?.id || clientGroup?.id.indexOf("_new_") === 0;
        const url = !isCreating ? Utils.getClientGroupUrl(props.instance.id, clientGroup.id) : Utils.getClientGroupsUrl(props.instance.id);

        let model = Utils.sanitizeObject(clientGroup);

        if (!model) {
            model = {}
        }
        model.itemIds = selectedClients.map(x => x.id);

        let data = new FormData();
        data.append("json", JSON.stringify(model));

        setIsBusy(true);
        fetch(url, {
            method: !isCreating ? 'PUT' : 'POST',
            body: data
        }).then(response => response.json()).then(response => {
            setIsBusy(false);
            if (response.clientIds)
                props.onSaved(response, saveAndExit);
            else
                props.onDeleted(response);
        });
    }

    function deleteClientGroup(clientGroup) {
        if (window.confirm("Are you sure?")) {
            fetch(Utils.getClientGroupUrl(props.instance.id, clientGroup.id), Utils.createRequest('DELETE')).then(() => {
                props.onDeleted(clientGroup);
            });
        }
    }

    function resetViewToBrowse() {
        props.onDone();
    }

    function updateEditingItem(field, value) {
        let obj = editingItem;
        if (field.indexOf('.') === -1) {
            editingItem[field] = value;
        } else {
            let fields = field.split('.');
            let existTest = obj;
            fields.map(x => {
                if (existTest[x] === undefined) existTest[x] = {};
                existTest = existTest[x];
            });
            const lastField = fields[fields.length - 1];
            fields = _.take(fields, fields.length - 1);
            fields.map(x => obj = obj[x]);
            obj[lastField] = value;
        }
        forceUpdate();
    }



    return (
        <div className="py-6 bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">{props.view === 'createClientGroupView' ? "Create client group" : "Edit client group"}</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-5">

                <form className="space-y-8  sm:space-y-5 divide-y divide-gray-200" onSubmit={(ev) => { ev.preventDefault(); return false; }}>



                    <div className="grid grid-flow-row grid-cols-2 gap-4 mt-8">
                        <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                            <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                Available clients
                            </div>
                            <ClientGroupsDraggableAreaFrom
                                isList
                                tags={availableClients}
                                className="p-3 min-h-32 h-64 overflow-y-auto"
                                render={({tag}) => (
                                <div className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 cursor-drag">
                                    {tag.name}
                                </div>
                                )}
                                onChange={clients => setAvailableClients(clients)}
                            />
                        </div>
                        <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                            <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                Clients in group
                            </div>
                            <ClientGroupsDraggableAreaTo
                                isList
                                tags={selectedClients}
                                className="p-3 min-h-32 h-64 overflow-y-auto"
                                render={({tag}) => (
                                <div className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 text-cursor-drag">
                                    {tag.name}
                                </div>
                                )}
                                onChange={clients => setSelectedClients(clients)}
                            />
                        </div>

                    </div>


                    <div className="pt-5">
                        <div className={`flex ${props.view === 'createClientGroupView' ? "justify-end" : "justify-between"}`}>
                            {props.view === 'editClientGroupView' &&
                                <button
                                    type="button"
                                    onClick={() => deleteClientGroup(editingItem)}
                                    className="bg-red-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-700 focus:outline-none"
                                >
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                    Delete
                                </button>
                            }
                            <div className="flex content-start">
                                <button
                                    type="button"
                                    onClick={() => !isBusy && resetViewToBrowse()}
                                    className={`${isBusy ? 'opacity-50' : ''} bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && saveClientGroup(editingItem)}
                                    className={`${isBusy ? 'opacity-50' : ''} ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && saveClientGroup(editingItem, true)}
                                    className={`${isBusy ? 'opacity-50' : ''} ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save and exit
                                </button>
                            </div>
                        </div>
                    </div>


                    
                </form>
            </div>
        </div>
    )
}
