export class PlayerUtils {
    
    getBaseUrl() { return '/api'; }

    getChannelsUrl() { return `${this.getBaseUrl()}/channels`; }
    getPlaylistsUrl() { return `${this.getBaseUrl()}/playlists`; }
    getClipsUrl() { return `${this.getBaseUrl()}/videos`; }
    
    getChannelUrl(channelUrl) { return `${this.getChannelsUrl()}/${channelUrl}`; }
    getSearchUrl(channelUrl) { return `${this.getChannelUrl(channelUrl)}/search`; }
    getPlaylistUrl(playlistUrl) { return `${this.getPlaylistsUrl()}/${playlistUrl}`; }
    getClipUrl(clipUrl) { return `${this.getClipsUrl()}/${clipUrl}`; }

    getPublicDataBaseUrl() { return '/api/public-data'; }
    getPublicDataChannelUrl(channelPid) { return `${this.getPublicDataBaseUrl()}/${channelPid}/channel`; }
    getPublicDataPlaylistUrl(channelPid, playlistPid) { return `${this.getPublicDataBaseUrl()}/${channelPid}/playlist/${playlistPid}`; }

    loadPublicDataChannel(channelPid) {
        return fetch(this.getPublicDataChannelUrl(channelPid));
    }




    loadChannel(channelUrl) {
        return fetch(this.getChannelUrl(channelUrl));
    }
    
    search(channelUrl, query) {
        return fetch(`${this.getSearchUrl(channelUrl)}?q=${query}`);
    }
    
    loadPlaylist(playlistUrl) {
        return fetch(this.getPlaylistUrl(playlistUrl));
    }
    
    loadClip(clipUrl) {
        return fetch(this.getClipUrl(clipUrl));
    }
    
    classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    getStyleElement(source) {
        var style = document.createElement('style');
        style.id = 'howtofystyles';
        style.innerHTML = 
            `.howtofy-text-color { color: ${source.textColor} } `
            + `.howtofy-secondary-text-color { color: ${source.secondaryTextColor} } `
            + `.howtofy-border-color { border-color: ${source.textColor} } `
            + `.howtofy-secondary-border-color { border-color: ${source.secondaryTextColor} } `
            + `.howtofy-background-color { background-color: ${source.backgroundColor} } `
            + `.howtofy-banner-background-color { background-color: ${source.bannerBackgroundColor ?? source.backgroundColor} } `
            
            + `.howtofy-text-color-inverse { color: ${source.backgroundColor} } `
            + `.howtofy-border-color-inverse { border-color: ${source.backgroundColor} } `
            + `.howtofy-background-color-inverse { background-color: ${source.textColor} } `
            + '.opacity-fade { transition: opacity .5s ease-in-out; }'
            + '.opacity-fade-1500 { transition: opacity 1.5s ease-in-out; }'
            + '.animate-width { transition: width .25s linear; }'
            //+ '.animate-top { transition: top .5s linear; }'
            + '.dark-text-shadow { text-shadow: 1px 1px 1px rgb(0 0 0 / 50%) }'
            + '.aspect-16-9 { aspect-ratio: 16 / 9 }'
            + '.aspect-3-4 { aspect-ratio: 3 / 4 }'
            + '.aspect-square { aspect-ratio: 1 / 1 }'
            + '.vjs-big-play-button { display:none !important; }'
            //+ '.amp-flush-skin.amp-size-s .vjs-play-control { display:block !important; }'
        ;
        return style;
    }

    getFixedHeaderMenuItems() {
        return [
            [
                //{ title: "About Howtofy", href: "https://www.howtofy.com/about", target: "_blank" }
            ]
        ];
    }

    getSocMediaTypes() {
        return [
            { name: 'Instagram', icon: ['fab', 'instagram'] },
            { name: 'LinkedIn', icon: ['fab', 'linkedin-in'] },
            { name: 'Facebook', icon: ['fab', 'facebook'] },
            { name: 'Twitter', icon: ['fab', 'twitter'] },
            { name: 'Pinterest', icon: ['fab', 'pinterest'] }
        ];
    }

    getSocialMediaHeaderMenuItems(channels) {
        return [channels.map(channel => {
            return ({ 
                title: channel.name, 
                href: channel.url, 
                icon: this.getSocMediaTypes().find(x => x.name === channel.type).icon,
                target: '_blank'
            });
        })];
    }
    
    getLegalHeaderMenuItems(clientUrl, sections) {
        let res = [];
        if (sections.termsAndConditions) {
            res.push({ 
                title: "Terms and Conditions", 
                href: `/legal/terms-and-conditions/${clientUrl}`, 
                target: '_blank'
            });
        }
        if (sections.cookieTerms) {
            res.push({ 
                title: "Cookie Policy", 
                href: `/legal/cookie-policy/${clientUrl}`, 
                target: '_blank'
            });
        }
        if (sections.privacyTerms) {
            res.push({ 
                title: "Privacy Policy", 
                href: `/legal/privacy-policy/${clientUrl}`, 
                target: '_blank'
            });
        }
        return [res];
    }

    formatDuration(duration) {
        const min = Math.trunc(duration / 60);
        const sec = duration % 60;
        return `${min}:${Math.round(sec).toString().padStart(2, '0')}`;
    }

    processReferences(clientData) {
        clientData.playlists.map(playlist => {
            playlist.clips = playlist.clipPids.map(pid => clientData.clips.find(x => x.pid === pid));
        });

        clientData.products.map(product => {
            product.clips = product.clipPids.map(pid => clientData.clips.find(x => x.pid === pid));
        });

        clientData.clips.map(clip => {
            clip.playlists = clip.playlistPids.map(pid => clientData.playlists.find(x => x.pid === pid));
            clip.products = clip.productPids.map(pid => clientData.products.find(x => x.pid === pid));
        });

        return clientData;
    }

}

const playerutils = new PlayerUtils();

export default playerutils;
