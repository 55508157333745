import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Snap from "snapsvg-cjs";

const QRCode = require("qrcode-svg");

export default (props) => {
    //const [serverAddrTemp, setServerAddrTemp] = useState('');
    // const [serverAddr, setServerAddr] = useState(props.serverurl ?? getServerUrl());
    const qrStickerElement = useRef(null);

    // function getServerUrl() {
    //     return window.location.protocol + '//' + window.location.host;
    // }

    // function getQrLinkFullUrl(qrLink) {
    //     return `${getServerUrl()}/qr/r/${qrLink}`;
    // }

    // function getQrLinkFullUrl(serverUrl, qrLink) {
    //     return `${serverUrl}/qr/r/${qrLink}`;
    // }

    // useEffect(() => {
    //     //setServerAddrTemp(getServerUrl());
    //     setServerAddr(getServerUrl());
    // }, []);

    useEffect(() => {
        const s = Snap(qrStickerElement.current);
        s.clear();

        Snap.load(props.backdrop, function(sticker_backdrop) {
            s.append(sticker_backdrop);

            //QR code
            const qrSvg = new QRCode({
                //content: getQrLinkFullUrl(serverAddr, props.value),
                content: props.value,
                join: false,
                xmlDeclaration: false,
                container: 'none',
                padding: 0,
                width: props.qrsize,
                height: props.qrsize,
                color: props.color,
                background: "transparent",
                ecl: "H",
            }).svg();

            const qrFragment = Snap.fragment(qrSvg);
            var qrGroup = s.g();
            qrGroup.append(qrFragment);
            qrGroup.transform(props.transform);
            s.append(qrGroup);

            //Play button overlay
            Snap.load(props.overlay, function(sticker_overlay) {
                s.append(sticker_overlay);
                // playbutton.select("path").attr({fill: props.color});
                // var pbGroup = s.g();
                // pbGroup.append(playbutton);
                // pbGroup.transform("t315 95");
                // s.append(pbGroup);
            });
        });
    }, [props.value]);

    function saveSvgFile() {
        var svgEl = qrStickerElement.current;
        svgEl.setAttribute('version', '1.1');
        svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        var markup = svgEl.outerHTML;
        var b64 = btoa(markup);
        var aEl = document.createElement('a');
        aEl.setAttribute('download', props.filename);
        aEl.href = 'data:image/svg+xml;base64,\n' + b64;
        document.body.appendChild(aEl);
        aEl.click();
    }    

    return (
        <div className="space-y-1 flex flex-col justify-end">
            {/* <input
                type="text"
                className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                value={serverAddrTemp}
                onChange={e => setServerAddrTemp(e.target.value) }
                onBlur={(e) => setServerAddr(e.target.value)}
            />
 */}
            <svg ref={qrStickerElement} width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg" version="1.1" />

            <button
                type="button"
                onClick={() => saveSvgFile()}
                className={`flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
            >
                Save as SVG file
            </button>

        </div>
    )
}
