import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from './PageHeader';
import ClipPlayer from './ClipPlayer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CookieConsentBar from './CookieConsentBar';
// import PoweredByBar from './PoweredByBar';
import PlayerUtils from '../Utils/PlayerUtils';
import Playlists from './Playlists';
import gtag, { install } from 'ga-gtag';
import Geocode from 'react-geocode';

const logo = require("../../images/howtofy_logo_white_RGB.svg");

const useQuery= () => {
    return new URLSearchParams(useLocation().search);
}

export default (props) => {
    const [channel, setChannel] = useState(null);
    const [clip, setClip] = useState(null);
    const [hideHeader, setHideHeader] = useState(false);
    const [hideLogo, setHideLogo] = useState(false);
    const [hideSegments, setHideSegments] = useState(false);
    const [hideProducts, setHideProducts] = useState(false);
    const [hideCookieConsent, setHideCookieConsent] = useState(false);
    const [otherPlaylists, setOtherPlaylists] = useState(null);

    const params = props.match.params;
    const query = useQuery();

    //const gmapApiKey = 'AIzaSyCABeBqip6pqR7VxJq0kLfXWHUVCpf1ztY';

    // const getPositionSuccess = (pos) => {
    //     Geocode.setApiKey(gmapApiKey);
    //     Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
    //         (response) => {
    //           const country = response.results[response.results.length - 1].address_components[0].short_name;
    //           console.log(country);
    //           debugger;
    //         },
    //         (error) => {
    //           console.error(error);
    //         }
    //       );        
    // }

    useEffect(() => {
        install(window.__gaId);
        if (getChannelUrl() && params.clipUrl) {
            loadData(getChannelUrl());
        }
        setHideHeader(query.get('header') === '0');
        setHideLogo(query.get('logo') === '0');
        setHideSegments(query.get('segments') === '0');
        setHideProducts(query.get('products') === '0');
        setHideCookieConsent(query.get('cookieconsent') === '0');

        //navigator.geolocation && navigator.geolocation.getCurrentPosition(getPositionSuccess);
    }, []);

    useEffect(() => {
        if (channel) {
            const cl = channel.clips.find(x => x.pid == params.clipUrl);
            setClip(cl);
            document.getElementsByTagName('head')[0].appendChild(PlayerUtils.getStyleElement(channel));            

            let data = {
                channelId: channel.pid,
                channelName: channel.name,
                videoId: cl.pid,
                videoName: cl.name
            };
            const qr = new URLSearchParams(window.location.search).get('qr');
            if (qr) {
                data.qrCode = qr;
                gtag('event', 'Code Scan', { 
                    qrCode: qr,
                    codeType: 'Video',
                    channelId: channel.pid,
                    channelName: channel.name,
                    videoId: cl.pid,
                    videoName: cl.name
                });
            }
            gtag('event', 'Video Page View', data);
        }
    }, [channel]);

    useEffect(() => {
        if(clip) {
            const otherPlaylists = [...channel.playlists].filter(cp => !clip.playlists.find(x => x.pid === cp.pid));
            setOtherPlaylists(otherPlaylists);
        }
    }, [clip]);

    function getChannelUrl() {
        return props.customChannelUrl ?? params.channelUrl;
    }

    function loadData(channelUrl) {
        PlayerUtils.loadPublicDataChannel(channelUrl).then(response => response.json().then(data => {
            setChannel(PlayerUtils.processReferences(data));
        }));
    }

    function getPlaylistLinkPrefix() {
        if (props.customChannelUrl) return '/p';
        return `/p/${channel?.pid}`;
    }

    return (
        <>
            <div className={`absolute w-screen h-screen bg-black z-30 opacity-fade flex flex-col items-center justify-center pointer-events-none ${clip ? 'opacity-0' : ''}`}>
                {!hideLogo &&
                    <>
                        {/* <img className="px-12 sm:px-0 sm:max-w-lg" src={logo} alt="Howtofy" /> */}
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin={true} size="lg" className="text-gray-800" />
                    </>
                }
            </div>
            <div className="flex flex-col min-h-screen howtofy-text-color howtofy-background-color pb-16">
                {!hideHeader &&
                    <PageHeader client={channel} clip={clip} customChannelUrl={props.customChannelUrl} />
                }
                <div className={`${!hideHeader ? 'mt-16' : 'mt-0'} w-full max-w-full sm:max-w-6xl mx-auto min-h-96 flex flex-col justify-center`}>
                    <ClipPlayer client={channel} clip={clip} hideSegments={hideSegments} />
                </div>

                <div className="w-full max-w-6xl mx-auto">
                    <div className="px-4 xl:px-0 py-12 lg:p-20 space-y-5">
                        <p className="text-2xl md:text-4xl font-bold">{clip?.name}</p>
                        <p className="text-xl">{clip?.description}</p>
                    </div>                    

                    {clip?.playlists && clip.playlists.length > 0 &&
                        <>
                            <p className="px-3 mt-5 mb-2 text-sm uppercase bg-white bg-opacity-20 border-b howtofy-border-color opacity-50">Relaterade spellistor</p>
                            <Playlists items={clip?.playlists} linkPrefix={getPlaylistLinkPrefix()} />
                        </>
                    }
                    {channel && otherPlaylists && otherPlaylists.length > 0 &&
                        <>
                            <p className="px-3 mt-5 mb-2 text-sm uppercase bg-white bg-opacity-20 border-b howtofy-border-color opacity-50">Övriga spellistor</p>
                            <Playlists items={otherPlaylists} linkPrefix={getPlaylistLinkPrefix()} />
                        </>
                    }

                </div>

                {/* <PoweredByBar /> */}
            </div>

            {!hideCookieConsent &&
                <CookieConsentBar />
            }
        </>
    )
}
