import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'

export default (props) => {
    const [keyvalues, setKeyvalues] = useState(null);
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
    
    useEffect(() => {
        if (!keyvalues) {
            setKeyvalues(props.keyvalues);
            // setKeyvalues({
            //     kalle: 'olle',
            //     pelle: 'tjena'
            // });
        }
    }, [props.keyvalues]);

    useEffect(() => {
        props.onUpdated && props.onUpdated(keyvalues);
    }, [keyvalues]);

    function setKeyName(key, name) {
        let copy = {...keyvalues};
        let val = copy[key];
        delete copy[key];
        if (name || val) {
            copy[name] = val;
        }
        setKeyvalues(copy);
    }

    function setKeyValue(key, value) {
        let copy = {...keyvalues};
        if (key || value) {
            copy[key] = value;
        } else {
            delete copy[key];
        }
        setKeyvalues(copy);
    }

    function addNewKeyValue() {
        if (newKey && newValue && !keyvalues[newKey]) {
            let copy = {...keyvalues};
            copy[newKey] = newValue;
            setKeyvalues(copy);
            setNewKey('');
            setNewValue('');
        }
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Key
                                    </th>
                                    <th colSpan="2" scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {keyvalues && Object.keys(keyvalues).sort(function(a, b) { return a < b ? -1 : 1}).map(key => {
                                    return (
                                        <tr key={key}>
                                            <td className="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <input
                                                    type="text"
                                                    className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                    defaultValue={key}
                                                    onBlur={e => setKeyName(key, e.target.value)}
                                                />
                                            </td>
                                            <td colSpan="2" className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                                                <input
                                                    type="text"
                                                    className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                    defaultValue={keyvalues[key]}
                                                    onBlur={e => setKeyValue(key, e.target.value)}
                                                />
                                            </td>
                                        </tr>    
                                    );
                                })}
                                <tr>
                                    <td className="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                                        <input
                                            type="text"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={newKey}
                                            onChange={e => setNewKey(e.target.value)}
                                        />
                                    </td>
                                    <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                                        <input
                                            type="text"
                                            name="customurl"
                                            id="customurl"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={newValue}
                                            onChange={e => setNewValue(e.target.value)}
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <button onClick={() => addNewKeyValue()}>Add</button>
                                    </td>
                                </tr>    
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}