import React, { useState, useEffect, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerUtils from '../Utils/PlayerUtils';
import Drawer from './Drawer';
import Search from './Search';
import ContactDetails from './ContactDetails';

export default (props) => {
    const [searchTerms, setSearchTerms] = useState('');
    const [searchVisible, setSearchVisible] = useState(false);
    const [currentSearchResultsSection, setCurrentSearchResultsSection] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    const [leftDrawerVisible, setLeftDrawerVisible] = useState(false);
    const [rightDrawerVisible, setRightDrawerVisible] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    const headerRef = React.useRef();

    const [, handleScrollEvent] = useReducer((state, message) => {
        let scrollTop = window.scrollY || window.document.documentElement.scrollTop;
        console.log(scrollTop);
        if (headerRef?.current) {
            const scrollDelta = lastScrollTop - Math.max(0, scrollTop);
            const topVal = Math.min(0, Math.max(-80, headerTop + scrollDelta));
            setHeaderTop(topVal);
            let topStyle = `${topVal}px`;
            headerRef.current.style.top = topStyle;
            setLastScrollTop(scrollTop);
        }
    });

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScrollEvent);
    //     return () => {
    //         window.removeEventListener('scroll', handleScrollEvent);
    //     };
    // }, []);

    function searchTermsChanged(terms) {
        setSearchTerms(terms);
        setSearchResults(null);
        if (terms.length > 2) {
            setIsSearching(true);
            PlayerUtils.search(props.client.customUrl, terms).then(response => response.json().then(data => {
                setIsSearching(false);
                const results = [
                    { name: 'Playlists', items: data.playlists },
                    { name: 'Videos', items: data.clips },
                    { name: 'Products', items: data.products },
                ];
                setCurrentSearchResultsSection(results[0]);
                setSearchResults(results);
            }));
        }
    }

    function getThumbUrl(item) {
        switch (item.objectType) { 
            case "dbPlaylist": 
                return item.thumbUrl;
            case "dbClip":
                return item.existingCustomLandscapeThumbUrl ?? item.existingVideoThumbUrl;
            case "dbProduct":
                return item.existingImageUrl;
        }
    }

    function getUrl(item) {
        switch (item.objectType) { 
            case "dbPlaylist": 
                return item.playlistUrl;
            case "dbClip":
                return item.playerUrl;
            case "dbProduct":
                return item.shop;
        }
    }

    function getChannelUrl() {
        return props.customChannelUrl ? '/' : props.client.url;
    }

    return (
        <>
            <div ref={headerRef} className="fixed flex flex-col w-full z-10 howtofy-background-color howtofy-banner-background-color">
                <div className="flex justify-between h-16 items-center w-full px-6 text-left text-xs font-medium text-white border-b border-black border-opacity-20">
                    <div className="w-20 lg:w-56">
                        {/* <DropdownMenu menuItems={props.menuItems} menuIconClassName="howtofy-text-color opacity-60" /> */}
                        <FontAwesomeIcon onClick={() => setLeftDrawerVisible(!leftDrawerVisible)} icon={['fas', 'bars' ]} size="2x" className="cursor-pointer" />
                    </div>
                    
                    {props.client?.logoUrl &&
                        <div className="flex items-center justify-center h-full py-2">
                            <a href={getChannelUrl()} className="h-full">
                                <img src={`/render/image?url=${props.client.logoUrl}&width=300`} className="h-full object-contain" alt={props.client?.name} />
                            </a>
                        </div>
                    }
                    
                    <div className="w-20 lg:w-56 flex justify-end">
                        {/* <FontAwesomeIcon onClick={() => setSearchVisible(!searchVisible)} icon={['fas', 'search' ]} size="2x" className="howtofy-text-color opacity-60 cursor-pointer" /> */}
                        <FontAwesomeIcon onClick={() => setRightDrawerVisible(!rightDrawerVisible)} icon={['fas', 'search' ]} size="2x" className="cursor-pointer" />
                    </div>
                </div>
                {searchVisible &&
                    <>
                        <div className="flex items-center w-full px-6 py-3 text-left text-xs font-medium howtofy-background-color bg-gray-50 text-gray-500 border-b border-black border-opacity-20">
                            <div className="relative rounded-md shadow-sm w-full">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-white opacity-50 sm:text-sm">
                                        <FontAwesomeIcon icon={['fas', isSearching ? 'spinner' : 'search' ]} spin={isSearching} size="lg" />                            
                                    </span>
                                </div>
                                <input autoFocus type="text" value={searchTerms} onChange={(ev) => searchTermsChanged(ev.target.value)} 
                                    className="focus:outline-none focus:border-transparent block w-full pl-10 pr-3 sm:text-sm rounded-full bg-white text-white bg-opacity-30 placeholder-white placeholder-opacity-50" 
                                />
                            </div>            
                        </div>
                        {searchResults &&
                            <div className="w-full p-6 xl:px-0 bg-gray-100 flex flex-col items-center">


                                <div className="w-full max-w-6xl">
                                    <div className="sm:hidden">
                                        <label htmlFor="tabs" className="sr-only">
                                            Select a tab
                                        </label>
                                        <select
                                            id="tabs"
                                            name="tabs"
                                            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            onChange={(ev) => setCurrentSearchResultsSection(searchResults.find(x => x.name === ev.target.value))}
                                            defaultValue={currentSearchResultsSection}
                                        >
                                        {searchResults.map((tab) => (
                                            <option key={tab.name} value={tab.name}>{tab.name}</option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className="hidden sm:block">
                                        <div className="border-b border-gray-200">
                                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                                {searchResults.map((tab) => (
                                                <a
                                                    key={tab.name}
                                                    onClick={() => setCurrentSearchResultsSection(tab)}
                                                    className={PlayerUtils.classNames(
                                                    tab === currentSearchResultsSection
                                                        ? 'border-indigo-500 text-indigo-600'
                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                                    )}
                                                    aria-current={tab === currentSearchResultsSection ? 'page' : undefined}
                                                >
                                                    {tab.name}
                                                </a>
                                                ))}
                                            </nav>
                                        </div>
                                    </div>
                                </div>


                                {currentSearchResultsSection &&
                                    <div className="w-full h-96 overflow-y-scroll max-w-6xl mt-5">
                                        <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                                            {currentSearchResultsSection
                                                .items
                                                .sort(function(a, b) { return a.name < b.name ? -1 : 1 })
                                                .map((item, index) => {
                                                return(
                                                    <li key={index}>
                                                        <a href={getUrl(item)}>
                                                            <div className="relative bg-gray-50 rounded-md overflow-hidden" style={{'aspectRatio': '16 / 9'}}>
                                                                {getThumbUrl(item) &&
                                                                    <img src={`/render/image?url=${getThumbUrl(item)}&width=300`} className="object-cover w-full" alt="" />
                                                                }
                                                                <div className="p-2 left-0 right-0 absolute bottom-0 bg-black bg-opacity-30 text-white text-sm uppercase rounded-b-md truncate">
                                                                    {item.name}
                                                                </div>                                                            
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                }


                            </div>
                        }
                    </>
                }
            </div>
            <div className="">
                <Drawer title="Contact" side="left" open={leftDrawerVisible} onClose={() => setLeftDrawerVisible(false)}>
                    <ContactDetails client={props.client} />
                </Drawer>
                <Drawer title="Search" side="right" open={rightDrawerVisible} onClose={() => setRightDrawerVisible(false)}>
                    <Search client={props.client} customChannelUrl={props.customChannelUrl} />
                </Drawer>
            </div>
        </>
   )
}