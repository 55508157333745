
import React from 'react';

export default () => {
    function getYear() {
        return new Date().getFullYear();
    }

    return (
        <div className="block text-center p-3 text-xs uppercase opacity-30">
            Powered by <a className="underline" target="_blank" href="https://www.howtofy.com">Howtofy</a>. Copyright &copy; 2021-{getYear()} Howtofy AB. All rights reserved.
        </div>
    )
}
