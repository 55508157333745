import React from 'react';
import ItemList from './ItemList';

export default (props) => {
    if (!props.items) return null;
    return (
        <ul className="divide-y divide-white divide-opacity-10">
            {props.items.map(item => {
                return (
                    <li key={item.pid} className="pb-8 pt-4 space-y-2">
                        <ItemList name={item.name} items={item.clips} linkPrefix={`${props.linkPrefix}/${item.pid}`} />
                    </li>
                );
            })}
        </ul>
    )
}

