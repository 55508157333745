import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        //instrumentationKey: 'InstrumentationKey=d3ae4ea3-2634-42a4-b94d-bc127d9533da;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
        connectionString: window.__appInsightsConnection,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        },
        enableAutoRouteTracking: true
    }
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };