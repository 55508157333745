import React, { useState, useEffect } from 'react';

export default(props) => {
    const [isOn, setIsOn] = useState();

    useEffect(() => {
        if (props?.value) setIsOn(props.value);
    }, [props]);

    const toggle = () => {
        props.onChange && props.onChange(!isOn);
        setIsOn(!isOn);
    }

    return (
        <button type="button" onClick={() => toggle()} aria-pressed={isOn} className={`${isOn ? "bg-purple-600" : "bg-gray-200"} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto`}>
            <span aria-hidden="true" className={`${isOn ? "translate-x-5" : "translate-x-0"}  inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}></span>
        </button>
   )
}