import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone'; //https://github.com/react-dropzone/react-dropzone
import {DraggableAreasGroup} from 'react-draggable-tags'; //https://ygyooo.github.io/react-draggable-tags/
import _ from "lodash";
import Utils from '../Utils/Utils';
import Tabs from './Tabs';
import ImageUpload from './ImageUpload';

const group = new DraggableAreasGroup();
const ClipsDraggableAreaFrom = group.addArea(); 
const ClipsDraggableAreaTo = group.addArea(); 

const tabGeneral = { name: 'General' };
const tabClips = { name: 'Clips' };
const tabEcommerce = { name: 'Ecommerce' };
const tabTranslations = { name: 'Translations' };
//const tabs = [tabGeneral, tabClips, tabEcommerce, tabTranslations];

export default (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [editingItem, setEditingItem] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [image, setImage] = useState(null);
    const [imageFileRemoved, setImageFileRemoved] = useState(null);
    const [activeTab, setActiveTab] = useState(tabGeneral);
    const [initialClips, setInitialClips] = useState([]);
    const [clientClips, setClientClips] = useState([]);
    const [availableClips, setAvailableClips] = useState([]);
    const [selectedClips, setSelectedClips] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [tabs, setTabs] = useState([tabGeneral, tabClips, tabEcommerce, tabTranslations]);

    const [clientGroupProducts, setClientGroupProducts] = useState([]);
    const [selectedClientGroupClient, setSelectedClientGroupClient] = useState();
    const [translatedClientProducts, setTranslatedClientProducts] = useState([]);


    useEffect(() => {
        if (props?.item) {
            setEditingItem(props.item);
            setInitialClips(props.item.__clips);
            //loadClientClips(props.instanceId, props.clientId);
            setClientClips(props.client.__clips);

            async function loadExistingImage() {
                setImage(await loadAsDataUrl(props.item.existingImageUrl));
            }
            if (props.item.existingImageUrl) {
                loadExistingImage();
            }
        }
        if (props.hideClips) {
            setTabs([tabGeneral, tabEcommerce]);
        }

        if (props.instance?.id && props.client?.clientGroupId) {
            Utils.loadClientGroupProducts(props.instance.id, props.client.clientGroupId, props.client.id).then(response => response.json().then(data => {
                //setInstances(data);
                data.clients.map(client => {
                    client.__products = data.products.filter(product => product.clientId == client.id && !product.productGroupId);
                });
                setClientGroupProducts(data.clients);
                console.log(data.clients);
                if (props.item.productGroupId) {
                    let translated = [];
                    data.products.filter(x => x.productGroupId == props.item.productGroupId && x.id != props.item.id).map(x => {
                        let clientClone = {...data.clients.find(client => client.id == x.clientId)};
                        let productClone = {...x};
                        clientClone.product = productClone;
                        translated.push(clientClone);
                    });
                    setTranslatedClientProducts(translated);
                }
            }).catch((err) => {
                if (response.status === 403) {
                    //setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
                } else {
                    //setErrorModalText(`Could not load data: ${err}`);
                }
            }));
        }


    }, []);

    async function loadAsDataUrl(url) {
        let blob = await fetch(url).then(r => r.blob());
        let dataUrl = await new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
        return dataUrl;
    }

    useEffect(() => {
        if(clientClips) {
            if (initialClips && initialClips.length > 0) {
                setAvailableClips(clientClips.filter(x => !initialClips.find(selClip => selClip.id === x.id)));
                setSelectedClips(clientClips.filter(x => initialClips.find(selClip => selClip.id === x.id)));
            } else {
                setAvailableClips(clientClips);
                setSelectedClips([]);
            }
        }
    }, [clientClips]);

    useEffect(() => {
        if(editingItem && selectedClips) {
            let item = _.cloneDeep(editingItem);
            item.clips = selectedClips;
            selectedClips.map(x => { console.log(x.name)});
            setEditingItem(item);
        }
    }, [selectedClips]);

    useEffect(() => {
        if(imageFile) {
            const reader = new FileReader();
            reader.addEventListener("load", function() {
                setImage(reader.result);
            });
            reader.readAsDataURL(imageFile);
        } else {
            setImage(null);
        }
    }, [imageFile]);

    useEffect(() => {
        if(editingItem) {
            editingItem.imageFileRemoved = imageFileRemoved;
        }
    }, [imageFileRemoved]);


    // function loadClientClips(instanceId, clientId) {
    //     Utils.loadClips(instanceId, clientId).then(response => response.json().then(data => {
    //         setClientClips(data);
    //     }));
    // }

    function saveProduct(product, saveAndExit = false) {
        if (product.name.trim() == '') {
            alert("Please provide a name for the product.");
            return;
        }

        const isCreating = product?.id == null;
        const url = Utils.getProductsUrl(props.instance.id, props.client.id) + (!isCreating ? `/${product.id}` : '');

        let model = Utils.sanitizeObject(product);
        model.clipDTOs = selectedClips.map(clip => { 
            return {
                id: clip.id
            }
        });

        if (translatedClientProducts.length > 0) {
            model.productGroups = translatedClientProducts.map(x => {
                return { 
                    clientId: x.id, 
                    itemId: x.product.id 
                };
            });
            model.productGroups.push({ 
                clientId: props.client.id, 
                itemId: props.item.id 
            });
        }

        // const request = {
        //     method: isCreating ? 'POST' : 'PUT',
        //     headers: {
        //       'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(product)
        // }
        // fetch(url, request).then(response => response.json().then(data => {
        //     props.onSaved(data);
        // }));
        let data = new FormData();
        data.append("json", JSON.stringify(model));
        if (imageFile) data.append("image", imageFile); 

        setIsBusy(true);
        fetch(url, {
            method: product.id ? 'PUT' : 'POST',
            body: data
        }).then(response => response.json()).then(response => {
            setIsBusy(false);
            props.onSaved(response, saveAndExit);
        });

    }

    function deleteProduct(product) {
        if (window.confirm("Are you sure?")) {
            fetch(Utils.getProductUrl(props.instanceId, props.clientId, product.id), Utils.createRequest('DELETE')).then(() => {
                props.onDeleted(product);
            });
        }
    }

    function resetViewToBrowse() {
        props.onDone();
    }

    function updateEditingItem(field, value) {
        let obj = editingItem;
        if (field.indexOf('.') === -1) {
            editingItem[field] = value;
        } else {
            let fields = field.split('.');
            const lastField = fields[fields.length - 1];
            fields = _.take(fields, fields.length - 1);
            fields.map(x => obj = obj[x]);
            obj[lastField] = value;
        }
        forceUpdate();
    }

    function toggleTranslatedClientProduct(client, product) {
        let clone = _.cloneDeep(translatedClientProducts);
        const idx = clone.findIndex(x => x.id == client.id);

        if (idx != -1) {
            clone.splice(idx, 1);
        }
        if (product) {
            let clientClone = {...client};
            clientClone.product = {...product};
            clone.push(clientClone);
        }

        setTranslatedClientProducts(clone);
    }

    function isClientGroupProductSelected(client, product) {
        const pl = translatedClientProducts.find(x => x.id == client.id);
        return pl?.product?.id == product.id;
    }




    return (
        <div className="py-6 bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">{props.view === 'createProductView' ? "Create product" : "Edit product"}</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-5">

                <Tabs tabs={tabs} activeTab={activeTab} onSetActiveTab={(tab) => setActiveTab(tab)} />      


                <form className="space-y-8 sm:space-y-5 divide-y divide-gray-200" onSubmit={(ev) => { ev.preventDefault(); return false; }}>
                    
                    
                    
                    {activeTab === tabGeneral &&
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="productname" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Product name
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="productname"
                                            id="productname"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.name ?? ''}
                                            onChange={e => updateEditingItem('name', e.target.value) }

                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Description
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        id="description"
                                        name="description"
                                        rows={3}
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.description ?? ''}
                                        onChange={e => updateEditingItem('description', e.target.value) }
                                    />
                                </div>
                            </div>                                

                            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="title" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Title
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="title"
                                            id="title"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.title ?? ''}
                                            onChange={e => updateEditingItem('title', e.target.value) }

                                        />
                                    </div>
                                </div>
                            </div>
 */}
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="image" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Image
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <ImageUpload image={image} 
                                        onSetImage={(img) => setImage(img)} 
                                        onSetImageFileRemoved={() => setImageFileRemoved()} 
                                        onSetImageFile={(file) => setImageFile(file)}
                                    />
                                    {/* <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white flex justify-evenly p-3">
                                        <div className="">
                                            <img src={image} className="object-cover mx-auto max-w-48 max-h-48" alt="" />
                                            {image &&
                                                <button
                                                    type="button"
                                                    onClick={() => { setImage(null); setImageFileRemoved(true); }}
                                                    className="block mt-2 mx-auto bg-white py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none"
                                                >
                                                    <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                                    Remove file
                                                </button>
                                            }

                                        </div>

                                        <Dropzone onDrop={(files) => setImageFile(files[0])}>
                                            {({getRootProps, getInputProps}) => (
                                                <div {...getRootProps()} className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md w-48 h-48">
                                                    <div className="space-y-1 text-center">
                                                        <FontAwesomeIcon icon={['fas', 'image']} size="2x" className="text-gray-400" />
                                                        <div className="flex text-sm text-gray-600">
                                                            <label
                                                                className="relative bg-white rounded-md"
                                                            >
                                                                <span className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload an image file</span>
                                                                <span className="pl-1">or drag and drop</span>
                                                            </label>
                                                            
                                                        </div>
                                                        <p className="text-xs text-gray-500">PNG, JPG, GIF or SVG</p>
                                                    </div>                                                            
                                                    <input {...getInputProps()} id="landscape-thumb-upload" name="landscape-thumb-upload" type="file" accept=".gif,.jpg,.jpeg,.png,.svg" className="sr-only" />
                                                </div>                                                    
                                            )}                                            
                                        </Dropzone>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    }

                    
                    
                    
                    {activeTab === tabClips && 
                        <div className="grid grid-flow-row grid-cols-2 gap-4 mt-8">
                            <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                    Available
                                </div>
                                <ClipsDraggableAreaFrom
                                    isList
                                    tags={availableClips}
                                    className="p-3 min-h-32"
                                    render={({tag, index}) => (
                                    <div className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 cursor-drag">
                                        {tag.name}
                                    </div>
                                    )}
                                    onChange={clips => setAvailableClips(clips)}
                                />
                            </div>
                            <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                    Selected
                                </div>
                                <ClipsDraggableAreaTo
                                    isList
                                    tags={selectedClips}
                                    className="p-3 min-h-32"
                                    render={({tag, index}) => (
                                    <div className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 text-cursor-drag">
                                        {tag.name}
                                    </div>
                                    )}
                                    onChange={clips => setSelectedClips(clips)}
                                />
                            </div>

                        </div>
                    }




                    {activeTab === tabEcommerce && 
                        <>
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="shopurl" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Shop link
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="shopurl"
                                            id="shopurl"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.shopUrl ?? ''}
                                            onChange={e => updateEditingItem('shopUrl', e.target.value) }

                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="moreinfourl" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    More info link
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="moreinfourl"
                                            id="moreinfourl"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.moreInfoUrl ?? ''}
                                            onChange={e => updateEditingItem('moreInfoUrl', e.target.value) }

                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="articleno" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Article no
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="articleno"
                                            id="articleno"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.articleNumber ?? ''}
                                            onChange={e => updateEditingItem('articleNumber', e.target.value) }

                                        />
                                    </div>
                                </div>
                            </div>
                        </>

                    }


                    {(activeTab === tabTranslations) && props.client.clientGroupId && clientGroupProducts.length > 0 &&
                        <div>
                            <h3 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">{tabTranslations.name}</h3>
                            <div className="grid grid-flow-row grid-cols-3 gap-4 mt-8">

                                <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                    <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                        Other clients in group
                                    </div>
                                    <ul className="h-64 overflow-y-scroll">
                                        {clientGroupProducts.map(client => {
                                            return (
                                                <li key={client.id} className={`${client.id === selectedClientGroupClient?.id ? 'bg-blue-200 border-blue-300 divide-blue-300' : 'bg-gray-100 border-gray-200 divide-gray-200'} text-gray-600 border rounded m-1 flex flex-cols items-center cursor-pointer divide-x`}>
                                                    <div className={`${client.id === selectedClientGroupClient?.id ? 'bg-blue-100' : 'bg-gray-50'} flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center`}>
                                                        <FontAwesomeIcon icon={['fas', 'users']} />
                                                        <span className="truncate">{client.name} ({client.culture})</span>
                                                    </div>
                                                    <div onClick={() => setSelectedClientGroupClient(client)} className={`${client.id === selectedClientGroupClient?.id ? 'text-blue-400' : 'text-gray-400'} px-3 py-1`}>
                                                        <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} size="lg" />
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>

                                <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                    <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                        Products in client
                                    </div>
                                    <ul className="h-64 overflow-y-scroll">
                                        {selectedClientGroupClient && [...selectedClientGroupClient.__products].sort((a, b) => a.name < b.name ? -1 : 1).map(product => {
                                            return (
                                                <li key={product.id} className={`${isClientGroupProductSelected(selectedClientGroupClient, product) ? 'bg-blue-200 border-blue-300 divide-blue-300' : 'bg-gray-100 border-gray-200 divide-gray-200'} text-gray-600 border rounded m-1 flex flex-cols items-center cursor-pointer divide-x`}>
                                                    <div className={`${isClientGroupProductSelected(selectedClientGroupClient, product) ? 'bg-blue-100' : 'bg-gray-50'} flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center`}>
                                                        <FontAwesomeIcon icon={['fas', 'list']} />
                                                        <span className="truncate">{product.name}</span>
                                                    </div>
                                                    <div onClick={() => toggleTranslatedClientProduct(selectedClientGroupClient, product)} className={`${isClientGroupProductSelected(selectedClientGroupClient, product) ? 'text-blue-400' : 'text-gray-400'} px-3 py-1`}>
                                                        <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} size="lg" />
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>                                    
                                
                                <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                    <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                        Selected translations
                                    </div>
                                    <ul className="h-64 overflow-y-scroll">
                                        {translatedClientProducts.map(client => {
                                            return (
                                                <li key={client.id} className="border border-gray-200 text-gray-600 rounded m-1 flex flex-cols items-center cursor-pointer divide-x divide-gray-200">
                                                    <div onClick={() => toggleTranslatedClientProduct(client, null)} className="bg-gray-100 px-3 py-1">
                                                        <FontAwesomeIcon icon={['fas', 'arrow-circle-left']} size="lg" className="text-gray-400" />
                                                    </div>
                                                    <div className="bg-gray-50 flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center">
                                                        <FontAwesomeIcon icon={['fas', 'list']} />
                                                        <span className="truncate">({client.culture}) {client.product.name}</span>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>                                    
                            </div>
                        </div>
                    }




                    <div className="pt-5">
                        <div className={`flex ${props.view === 'createProductView' ? "justify-end" : "justify-between"}`}>
                            {props.view === 'editProductView' &&
                                <button
                                    type="button"
                                    onClick={() => deleteProduct(editingItem)}
                                    className="bg-red-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-700 focus:outline-none"
                                >
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                    Delete
                                </button>
                            }
                            <div className="flex content-start">
                                <button
                                    type="button"
                                    onClick={() => !isBusy && resetViewToBrowse()}
                                    className={`${isBusy ? 'opacity-50' : ''} bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && saveProduct(editingItem)}
                                    className={`${isBusy ? 'opacity-50' : ''} ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && saveProduct(editingItem, true)}
                                    className={`${isBusy ? 'opacity-50' : ''} ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save and exit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
