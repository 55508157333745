/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useState, useEffect, useReducer, useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import PublicPageHeader from './PageHeader';
import HeroVideoPlayer from './HeroVideoPlayer';
import PlaylistItem from '../_PlaylistItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CookieConsentBar from './CookieConsentBar';
import PlayerUtils from '../Utils/PlayerUtils';
import PoweredByBar from './PoweredByBar';
import Drawer from './Drawer';
import Search from './Search';
import ContactDetails from './ContactDetails';
import Playlists from './Playlists';

import _, { map } from "lodash";
import moment from "moment";
import gtag, { install } from 'ga-gtag';

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

//const videoRef = React.createRef();
const logo = require("../../images/howtofy_logo_white_RGB.svg");

const useQuery= () => {
    return new URLSearchParams(useLocation().search);
}

export default (props) => {
    const appInsights = useAppInsightsContext();
    //const trackTestEvent = useTrackEvent(appInsights, "Test Event", { somedata: "kalle" })
    //appInsights.trackEvent({ name: 'Component Init', properties: { mydata: "datatat" } });

    const [channel, setChannel] = useState(null);
    // const [playlists, setPlaylists] = useState([]);
    // const [clips, setClips] = useState([]);
    // const [products, setProducts] = useState([]);
    // const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    //const [selectedClip, setSelectedClip] = useState(null);
    const [hideHeader, setHideHeader] = useState(false);
    const [hideLogo, setHideLogo] = useState(false);
    const [hideCookieConsent, setHideCookieConsent] = useState(false);
    //const [heroVideo, setHeroVideo] = useState(null);
    //const [headerMenuItems, setHeaderMenuItems] = useState(null);
    const { customChannelUrl } = useParams();

    const [leftDrawerVisible, setLeftDrawerVisible] = useState(false);
    const [rightDrawerVisible, setRightDrawerVisible] = useState(false);

    const params = props.match.params;
    const query = useQuery();
    const history = useHistory();

    
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    const [windowScrollX, setWindowScrollX] = useState(0);
    const [windowScrollY, setWindowScrollY] = useState(0);
    const [, handleSizeAndScrollUpdate] = useReducer((state, message) => {
        setWindowWidth(message[0]);
        setWindowHeight(message[1]);
        setWindowScrollX(message[2]);
        setWindowScrollY(message[3]);
    });

    function useWindowSizeAndScroll() {
        const [sizeAndScroll, setSizeAndScroll] = useState([0, 0, 0, 0]);
        useLayoutEffect(() => {
            function updateSizeAndScroll() {
                const obj = [window.innerWidth, window.innerHeight, window.scrollX, window.scrollY];
                //console.log(obj);
                setSizeAndScroll(obj);
                handleSizeAndScrollUpdate(obj);
            }
            window.addEventListener('resize', updateSizeAndScroll);
            window.addEventListener('scroll', updateSizeAndScroll);
            updateSizeAndScroll();
            return () => {
                window.removeEventListener('resize', updateSizeAndScroll);
                window.removeEventListener('scroll', updateSizeAndScroll);
            }
        }, []);
        return sizeAndScroll;
    }
    const [width, height, scrollX, scrollY] = useWindowSizeAndScroll();    
      
    function getChannelUrl() {
        return props.customChannelUrl ?? params.channelUrl;
    }

    useEffect(() => {
        install(window.__gaId);
        if (getChannelUrl()) {
            loadData(getChannelUrl());
        }
        //setHideHeader(query.get('header') === '0');
        setHideLogo(query.get('logo') === '0');
        setHideCookieConsent(query.get('cookieconsent') === '0');
    }, []);

    useEffect(() => {
        if (channel) {
            document.getElementsByTagName('head')[0].appendChild(PlayerUtils.getStyleElement(channel));            

            let data = {
                channelId: channel.pid,
                channelName: channel.name
            };
            const qr = new URLSearchParams(window.location.search).get('qr');
            if (qr) {
                data.qrCode = qr;
                gtag('event', 'Code Scan', { 
                    qrCode: qr,
                    codeType: 'Channel',
                    channelId: channel.pid,
                    channelName: channel.name
                });
            }

            gtag('event', 'Channel Page View', data);
        }
    }, [channel]);

    function loadData(channelUrl) {
        PlayerUtils.loadPublicDataChannel(channelUrl).then(response => response.json().then(data => {
            setChannel(PlayerUtils.processReferences(data));
        }));
    }

    function getHeight() {
        return Math.max((windowWidth > 640 ? 64 : 58), Math.min(windowWidth, windowHeight / 2) - scrollY);
    }

    function getIsFaded() {
        return getHeight() === (windowWidth > 640 ? 64 : 58);
    }

    function getPlaylistLinkPrefix() {
        if (props.customChannelUrl) return '/p';
        return `/p/${channel?.pid}`;
    }

    // const appInsights = useAppInsightsContext();
    // appInsights.trackEvent({ name: 'Component Init', properties: { mydata: "datatat" } });

    return (
        <>
            <div className="fixed z-20 hidden">{windowScrollY}</div>
            <div className={`absolute w-screen h-screen bg-black z-30 opacity-fade flex flex-col items-center justify-center pointer-events-none ${channel ? 'opacity-0' : ''}`}>
                {!hideLogo && 
                    <>
                        {/* <img className="px-12 sm:px-0 sm:max-w-lg" src={logo} alt="Howtofy" /> */}
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin={true} size="lg" className="text-gray-800" />
                    </>
                }
            </div>
            <div className="flex flex-col howtofy-text-color howtofy-background-color min-h-screen relative">
                {/* {!hideHeader &&
                    <div className="flex flex-col">
                        <PublicPageHeader 
                            client={channel}
                            menuItems={headerMenuItems} 
                        />
                    </div>
                } */}
                <div className="w-full mx-auto">
                    <div className="fixed z-10 w-full" style={{ height: `${getHeight()}px`} }>
                        <div className="absolute inset-0 border-b border-black border-opacity-20">
                            <HeroVideoPlayer client={channel} videoUrl={channel?.heroVideoUrl} faded={getIsFaded()} />
                            <div className="absolute inset-y-2 inset-x-24 sm:inset-x-5">
                                {channel &&
                                <img className="w-full h-full max-w-xs object-contain mx-auto" src={`/render/image?url=${channel.logoUrl}&width=300`} alt={channel.name} />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="fixed z-20 w-full mx-auto">
                        <div className={`absolute inset-0 bg-gradient-to-b from-black h-16 opacity-fade-1500 ${getIsFaded() ? 'opacity-0' : 'opacity-30'}`}></div>
                        <div className="relative flex flex-cols space-x-3 p-5 text-2xl">
                            <div onClick={() => setLeftDrawerVisible(!leftDrawerVisible)} className="relative cursor-pointer">
                                <FontAwesomeIcon icon={['fas', 'bars']} className="absolute text-black opacity-30" style={{ left: '1px', top: '1px'}} />
                                <FontAwesomeIcon icon={['fas', 'bars']} className="absolute text-white" />
                            </div>
                            <div className="flex-1">
                            </div>
                            <div onClick={() => setRightDrawerVisible(!rightDrawerVisible)} className="relative cursor-pointer pr-5">
                                <FontAwesomeIcon icon={['fas', 'search']} className="absolute text-black opacity-30" style={{ left: '1px', top: '1px'}} />
                                <FontAwesomeIcon icon={['fas', 'search']} className="absolute text-white" />
                            </div>
                        </div>
                    </div>                    
                    <div className="w-full max-w-6xl mx-auto">
                        <div style={{ marginTop: `${Math.min(windowWidth, windowHeight / 2)}px` }}>
                            <div className="px-4 xl:px-0 py-12 space-y-5">
                                <p className="text-2xl md:text-4xl font-bold howtofy-secondary-text-color">{channel?.channelPageHeadline}</p>
                                <p className="text-xl">{channel?.channelPageSubhead}</p>
                            </div>                    
                        </div>
                        <div className="">
                            <Playlists items={channel?.playlists} linkPrefix={getPlaylistLinkPrefix()} />                        
                        </div>
                    </div>
                </div>

                <PoweredByBar />
            </div>

            {!hideCookieConsent &&
                <CookieConsentBar />
            }

            <Drawer title="Contact" side="left" open={leftDrawerVisible} onClose={() => setLeftDrawerVisible(false)}>
                <ContactDetails client={channel} />
            </Drawer>
            <Drawer title="Search" side="right" open={rightDrawerVisible} onClose={() => setRightDrawerVisible(false)}>
                <Search client={channel} customChannelUrl={props.customChannelUrl} />
            </Drawer>

        </>
    )
}
