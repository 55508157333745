import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {

    function getUrl(item) {
        if(item.shopUrl != null && item.shopUrl.length > 0) return item.shopUrl;
        if(item.moreInfoUrl != null && item.moreInfoUrl.length > 0) return item.moreInfoUrl;
    }

    function item() {
        return(
            <div className="relative flex flex-cols items-center bg-white bg-opacity-10 rounded-md py-3 w-52 md:w-60 text-xs md:text-sm">
                <div className="flex-shrink-0 flex items-center justify-center w-16 h-16 font-medium rounded-l-md bg-white bg-opacity-20">
                    <img className="w-16 h-16 object-center object-cover rounded-l-md" src={`/render/image?url=${props.item.thumbnailUrl}&width=300`} alt={props.item.name} />
                </div>
                
                <div className="flex-1 px-3 flex items-center line-clamp-3">
                    {props.item.name}
                </div>
                {getUrl(props.item) &&
                    <div className="flex-shrink-0 w-12 grid grid-cols-1 gap-1 rounded-r-md">
                        {props.item.shopUrl &&
                            <div className="flex items-center justify-center">
                                <div className="border border-white border-opacity-50 w-6 h-6 rounded-full p-2 flex items-center justify-center">
                                    <FontAwesomeIcon icon={['fas', 'shopping-cart']} className="text-sm" />
                                </div>
                            </div>
                        }
                        {props.item.moreInfoUrl &&
                            <div className="flex items-center justify-center">
                                <div className="border border-white border-opacity-50 w-6 h-6 rounded-full p-2 flex items-center justify-center">
                                    <FontAwesomeIcon icon={['fas', 'info']} className="text-sm" />
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>            
        );
    }

    if (getUrl(props.item)) {
        return (
            <a target="_blank" className="flex" href={getUrl(props.item)}>
                {item()}
            </a>        
        )
    } else {
        return (
            item()
        )
    }
}

