import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Item from './Item';
import Playerutils from '../Utils/PlayerUtils';

export default (props) => {
    if (!props.items) return null;
    let direction = props.direction ?? "horizontal";

    return (
        <div className="space-y-4">
            {!props.hideTitle &&
                <div className="flex items-center space-x-2 px-4 lg:px-0 relative">
                    <FontAwesomeIcon icon={['fas', 'clipboard-list' ]} size="lg" className="howtofy-text-color relative -top-0.5 opacity-50" />
                    <p className="text-sm howtofy-text-color uppercase">
                        {props.name}
                    </p>
                </div>
            }
            <div className="overflow-x-scroll px-4 xl:px-0">
                <div className="flex flex-cols">
                    {direction === 'horizontal' &&
                        <>
                            <ul role="list" className="flex space-x-2">
                                {props.items.map(item => {
                                    return (
                                        <li key={item.pid} onClick={() => props.onClick && item !== props.selectedItem && props.onClick(item)} className="flex">
                                            <div className="w-40 md:w-64 overflow-hidden">
                                                {props.linkPrefix && 
                                                    <a href={`${props.linkPrefix}/${item.pid}`}>
                                                        <Item 
                                                            title={item.name}
                                                            thumbUrl={item.landscapeThumbUrl}
                                                            duration={item.duration}
                                                            showPlayIcon={true}
                                                            aspect="auto"
                                                        />                                                
                                                    </a>
                                                }
                                                {!props.linkPrefix &&
                                                    <Item 
                                                        title={item.name}
                                                        thumbUrl={item.landscapeThumbUrl}
                                                        duration={item.duration}
                                                        showPlayIcon={true}
                                                        aspect="auto"
                                                    />                                                
                                                }
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="p-2 xl:hidden">
                                {/* Adds space to the right of the last item */}
                            </div>
                        </>
                    }
                    {direction === 'vertical' &&
                        <ul role="list" className="w-full divide-y divide-white divide-opacity-10">
                            {props.items.map(item => {
                                return (
                                    <li key={item.pid} onClick={() => props.onClick && item !== props.selectedItem && props.onClick(item)}>
                                        <div className={`relative ${item !== props.selectedItem ? 'cursor-pointer' : ''}`}>
                                            <div className={`absolute inset-0 ${item === props.selectedItem ? 'howtofy-background-color-inverse opacity-10' : ''}`} />
                                            <div className={`px-2 py-4 lg:p-5 flex flex-cols space-x-2`}>
                                                <div className="w-24 md:w-32 overflow-hidden flex-shrink-0">
                                                    {props.linkPrefix &&
                                                        <a href={`${props.linkPrefix}/${item.pid}`} className="">
                                                            <Item 
                                                                thumbUrl={item.landscapeThumbUrl}
                                                                showPlayIcon={true}
                                                                aspect="auto"
                                                            />                                                
                                                        </a>
                                                    }
                                                    {!props.linkPrefix &&
                                                        <Item 
                                                            thumbUrl={item.landscapeThumbUrl}
                                                            showPlayIcon={true}
                                                            aspect="auto"
                                                        />                                                
                                                    }
                                                </div>
                                                <div className="space-y-1 relative -top-1.5">
                                                    <p className="text-xl">{item.name}</p>
                                                    <p className={`${item === props.selectedItem ? '' : 'line-clamp-2'} text-sm`}>{item.description}</p>
                                                    <p className="text-xs opacity-50 space-x-1">
                                                        <span>
                                                            <FontAwesomeIcon icon={['fas', 'clock' ]} size="lg" />
                                                        </span>
                                                        <span>
                                                            {Playerutils.formatDuration(item.duration)}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    }
                </div>
            </div>
        </div>
    );
}

