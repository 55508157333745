import React, { Component } from 'react';

export class Layout extends Component {
    constructor(props) {
        super(props);
    }
    //static displayName = Layout.name;

    render () {
        const isHomepage = window.location.pathname === "/";
        return (
            <div className={`${isHomepage ? "bg-white" : "bg-gray-100"}`}>
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
