import React from 'react';
import Segment from './Segment';

export default (props) => {
    if (!props.items) return null;

    return (
        <div id="segmentlist" className="overflow-x-scroll overflow-y-hidden px-4">
            <div className="flex flex-cols">
                <ul role="list" className="flex space-x-4">
                    {props.items.map((item, index) => {
                        return (
                            <li key={index}>
                                <div className={`${props.currentSegment === item ? ' bg-white bg-opacity-20' : ''} flex`}>
                                    <Segment item={item} index={index + 1} onSegmentSelected={(segment) => props.onSegmentSelected(segment)} />
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div className="px-2">
                    {/* Adds space to the right of the last item */}
                </div>
            </div>
        </div>
    );
}

