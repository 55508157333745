import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {

    return (
        <nav className="bg-white border-b border-gray-200 flex" aria-label="Breadcrumb">
            <ol role="list" className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
                <li className="flex">
                <div className="flex items-center">
                    <a onClick={() => props.onItemSelected(null)} className="text-gray-400 hover:text-gray-500">
                    <FontAwesomeIcon icon={['fas', 'home']} size="lg" className="mr-2" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
                {props.pages?.map((item, index) => (
                <li key={index} className="flex">
                    <div className="flex items-center">
                        <svg
                            className="flex-shrink-0 w-6 h-full text-gray-200"
                            viewBox="0 0 24 44"
                            preserveAspectRatio="none"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                        >
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a
                            onClick={() => props.onItemSelected(item)}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                        >
                            {item.name.length > 0 ? item.name : '(Untitled)'}
                        </a>
                    </div>
                </li>
                ))}
            </ol>
        </nav>
    )
}
