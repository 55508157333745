import React, { Component, useEffect, useState } from 'react';
import { Route } from 'react-router';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { Layout } from './components/Layout';
import { PrivateRoute } from "./PrivateRoute";
import { Home } from './components/Home';
import SysAdmin from './components/AdminStuff/SysAdmin';
import ChannelPage from './components/PublicStuff/ChannelPage';
import PlaylistPage from './components/PublicStuff/PlaylistPage';
import PlayerPage from './components/PublicStuff/PlayerPage';
import ProductPage from './components/PublicStuff/ProductPage';
import {Helmet} from 'react-helmet';
import './style.css';
import './style_customizations.css';

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from './AppInsights';

library.add(fab, far, fas);

const App = (props) => {
    const [customChannelUrl, setCustomChannelUrl] = useState();

    useEffect(() => {
        setCustomChannelUrl(window.__customChannelUrl);
        document.title = window.__title;
    }, []);

    const constructProps = (props) => {
        let myProps = {...props};

        myProps.customChannelUrl = customChannelUrl;

        return myProps;
    }

    if (customChannelUrl) {
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                <Layout>
                    {window.__favIconUrl &&
                        <Helmet>
                            <link rel="shortcut icon" href={window.__favIconUrl}></link>
                        </Helmet>
                    }

                    <Route exact path='/:clipUrl?' render={(props) => <ChannelPage {...constructProps(props)} />} />
                    <Route path={`/p/:playlistUrl?/:clipUrl?`} render={(props) => <PlaylistPage {...constructProps(props)} />} />
                    <Route path={`/v/:clipUrl?`} render={(props) => <PlayerPage {...constructProps(props)} />} />
                    <Route path={`/prod/:productUrl?`} render={(props) => <ProductPage {...constructProps(props)} />} />
                </Layout>
            </AppInsightsContext.Provider>
        );
    }
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Layout>
                {window.__favIconUrl &&
                    <Helmet>
                        <link rel="shortcut icon" href={window.__favIconUrl}></link>
                    </Helmet>
                }
                <Route exact path='/' component={Home} />
                <PrivateRoute path='/admin/:instanceId?/:clientId?' component={SysAdmin} />
                <Route path='/c/:channelUrl?/:clipUrl?' component={ChannelPage} />
                <Route path='/p/:channelUrl?/:playlistUrl?/:clipUrl?' component={PlaylistPage} />
                <Route path='/v/:channelUrl?/:clipUrl?' component={PlayerPage} />
                <Route path='/prod/:channelUrl?/:productUrl?' component={ProductPage} />
            </Layout>
        </AppInsightsContext.Provider>
    );
}

export default withAITracking(reactPlugin, App);

// import React, { Component } from 'react';
// import { Route } from 'react-router';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'

// import { Layout } from './components/Layout';
// import { Home } from './components/Home';
// import SysAdmin from './components/AdminStuff/SysAdmin';
// import ChannelPage from './components/PublicStuff/ChannelPage';
// import PlaylistPage from './components/PublicStuff/PlaylistPage';
// import PlayerPage from './components/PublicStuff/PlayerPage';
// import ProductPage from './components/PublicStuff/ProductPage';

// import './style.css';

// library.add(fab, far, fas);

// export default class App extends Component {
//   static displayName = App.name;

//   render () {
//     return (
//       <Layout>
//         <Route exact path='/' component={Home} />
//         <Route path='/admin/:instanceId?/:clientId?' component={SysAdmin} />
//         <Route path='/c/:channelUrl?/:clipUrl?' component={ChannelPage} />
//         <Route path='/p/:channelUrl?/:playlistUrl?/:clipUrl?' component={PlaylistPage} />
//         <Route path='/v/:channelUrl?/:clipUrl?' component={PlayerPage} />
//         <Route path='/prod/:channelUrl?/:productUrl?' component={ProductPage} />
//       </Layout>
//     );
//   }
// }
