//https://usefulangle.com/post/46/javascript-get-video-thumbnail-image-jpeg-png


import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {DraggableArea, DraggableAreasGroup} from 'react-draggable-tags'; //https://ygyooo.github.io/react-draggable-tags/
import Dropzone from 'react-dropzone'; //https://github.com/react-dropzone/react-dropzone
import Tabs from './Tabs';
import Modal from './Modal';
import ProductEditForm from './ProductEditForm';
import Toggle from '../Toggle';
// import _DragDropList from './_DragDropList';
// import SortableDragList from './_SortableDragList';

import _, { map } from "lodash";
import Utils from '../Utils/Utils';

const productsGroup = new DraggableAreasGroup();
const ProductsDraggableAreaFrom = productsGroup.addArea(); 
const ProductsDraggableAreaTo = productsGroup.addArea(); 

const playlistsGroup = new DraggableAreasGroup();
const PlaylistsDraggableAreaFrom = playlistsGroup.addArea(); 
const PlaylistsDraggableAreaTo = playlistsGroup.addArea(); 

const minutes = Array.from(Array(60), (_, x) => x);
const seconds = Array.from(Array(60), (_, x) => String(x).padStart(2, '0'));

const videoRef = React.createRef();

const tabGeneral = { name: 'General' };
const tabVideo = { name: 'Video' };
const tabPublish = { name: 'Publishing' };
const tabProducts = { name: 'Products' };
const tabPlaylists = { name: 'Playlists' };
const tabTranslations = { name: 'Translations' };
const tabs = [tabVideo, tabGeneral, tabPublish, tabProducts, tabPlaylists, tabTranslations];

export default (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [editingItem, setEditingItem] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    //const [videoUploadUrl, setVideoUploadUrl] = useState(null);
    const [videoWidth, setVideoWidth] = useState(null);
    const [videoHeight, setVideoHeight] = useState(null);
    const [videoDuration, setVideoDuration] = useState(null);
    const [videoThumb, setVideoThumb] = useState(null);
    const [videoThumbTime, setVideoThumbTime] = useState(null);
    const [videoThumbFile, setVideoThumbFile] = useState(null);
    const [landscapeThumbFile, setLandscapeThumbFile] = useState(null);
    const [portraitThumbFile, setPortraitThumbFile] = useState(null);
    const [landscapeThumb, setLandscapeThumb] = useState(null);
    const [portraitThumb, setPortraitThumb] = useState(null);
    const [playlists, setPlaylists] = useState();
    const [availablePlaylists, setAvailablePlaylists] = useState();
    const [selectedPlaylists, setSelectedPlaylists] = useState();
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [selectedPlaylistClips, setSelectedPlaylistClips] = useState([]);
    //const [memberOfPlaylists, setMemberOfPlaylists] = useState([]);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [availableProducts, setAvailableProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [newProductName, setNewProductName] = useState('');
    const [segments, setSegments] = useState([]);
    const [newSegmentName, setNewSegmentName] = useState('');
    const [newSegmentMinutes, setNewSegmentMinutes] = useState('');
    const [newSegmentSeconds, setNewSegmentSeconds] = useState('');

    const [videoFileRemoved, setVideoFileRemoved] = useState(false);
    const [videoThumbFileRemoved, setVideoThumbFileRemoved] = useState(false);
    const [landscapeThumbFileRemoved, setLandscapeThumbFileRemoved] = useState(false);
    const [portraitThumbFileRemoved, setPortraitThumbFileRemoved] = useState(false);
    const [hasExistingVideo, setHasExistingVideo] = useState(false);
    const [activeTab, setActiveTab] = useState(tabVideo);
    const [isBusy, setIsBusy] = useState(false);
    const [useTabs, setUseTabs] = useState(false);

    const [clientGroupClips, setClientGroupClips] = useState([]);
    const [selectedClientGroupClient, setSelectedClientGroupClient] = useState();
    const [translatedClientClips, setTranslatedClientClips] = useState([]);

    const [addProductModalOpen, setAddProductModalOpen] = useState(false);

    const [videoSource, setVideoSource] = useState('');
    const [newProductEditingItem, setNewProductEditingItem] = useState(null);

    const [, handleLoadedData] = useReducer((state, event) => {
        const videoElement = videoRef.current;
        setVideoWidth(videoElement.videoWidth);
        setVideoHeight(videoElement.videoHeight);
        setVideoDuration(videoElement.duration);
        videoElement.currentTime = videoThumbTime ?? videoElement.duration / 2;
    }, "");
    
    const [, handleTimeupdate] = useReducer((state, event) => {
        const videoElement = videoRef.current;
        if (videoElement.videoWidth > 0 && videoElement.videoHeight > 0 && (!hasExistingVideo || (hasExistingVideo && videoElement.currentTime !== 0 && videoElement.currentTime !== videoThumbTime))) {
            let canvas = document.createElement("canvas");
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            let context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight);
            const dataUrl = canvas.toDataURL();
            setVideoThumb(dataUrl);
            setVideoThumbFile(dataUrlToFile(dataUrl, "videoThumbnail.png"));
            setVideoThumbTime(videoElement.currentTime);
        }
    }, "");

        
    useEffect(() => {
        console.log(props.item);

        if (props.instance?.id && props.client?.clientGroupId) {
            Utils.loadClientGroupClips(props.instance.id, props.client.clientGroupId, props.client.id).then(response => response.json().then(data => {
                //setInstances(data);
                data.clients.map(client => {
                    client.__clips = data.clips.filter(clip => clip.clientId == client.id && !clip.clipGroupId);
                });
                setClientGroupClips(data.clients);
                console.log(data.clients);
                if (props.item.clipGroupId) {
                    let translated = [];
                    data.clips.filter(x => x.clipGroupId == props.item.clipGroupId && x.id != props.item.id).map(x => {
                        let clientClone = {...data.clients.find(client => client.id == x.clientId)};
                        let clipClone = {...x};
                        clientClone.clip = clipClone;
                        translated.push(clientClone);
                    });
                    setTranslatedClientClips(translated);
                }
            }).catch((err) => {
                if (response.status === 403) {
                    //setErrorModalText("Access denied:Please make sure the user you are logged in as has access to the requested resource.");
                } else {
                    //setErrorModalText(`Could not load data: ${err}`);
                }
            }));
        }

        // videoRef.current.addEventListener('loadeddata', handleLoadedData);
        // videoRef.current.addEventListener('timeupdate', handleTimeupdate);
        // return () => {
        //     videoRef.current.removeEventListener('loadeddata', handleLoadedData);
        //     videoRef.current.removeEventListener('timeupdate', handleTimeupdate);
        // };
    }, []);
     


    useEffect(() => {
        setSelectedPlaylistClips(selectedPlaylist?.__clips ?? []);
    }, [selectedPlaylist]);

    useEffect(() => {
        if (props?.item) { 
            //loadClip(props.item.id);
            setEditingItem(props.item);
            setSegments(props.item.segments);
            setSelectedProducts([...props.item.__products].sort((a, b) => a.order < b.order ? -1 : 1));
            setAvailableProducts([...props.client.__products]
                .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
                .filter(x => !props.item.__products.find(y => y.id === x.id))
            );

            if (props.item.existingVideoUrl) {
                // if (videoRef.current)
                //     videoRef.current.src = data.existingVideoUrl;
                setVideoSource(props.item.existingVideoUrl);
                setVideoThumbTime(props.item.videoThumbnailTime);
                setHasExistingVideo(true);
            }

            async function loadThumbs() {
                if (props.item.existingVideoThumbUrl) {
                    setVideoThumb(await loadAsDataUrl(`/render/image?url=${props.item.existingVideoThumbUrl}&width=1280`));
                }
    
                if (props.item.existingCustomLandscapeThumbUrl) {
                    setLandscapeThumb(await loadAsDataUrl(`/render/image?url=${props.item.existingCustomLandscapeThumbUrl}&width=1280`));
                }
    
                if (props.item.existingCustomPortraitThumbUrl) {
                    setPortraitThumb(await loadAsDataUrl(`/render/image?url=${props.item.existingCustomPortraitThumbUrl}&width=1280`));
                }
            }
            loadThumbs();
            

            

            //loadClientPlaylists();
            setPlaylists(props.client.__playlists);            
        }
    }, []);

    useEffect(() => {
        if (editingItem)
            editingItem.uploadedClipBlocks = props.uploadedClipBlocks;
    }, [props.uploadedClipBlocks]);


    useEffect(() => {
        if(editingItem)
            editingItem.products = selectedProducts;
    }, [selectedProducts]);

    useEffect(() => {
        if(editingItem)
            editingItem.segments = segments;
    }, [segments]);

    // useEffect(() => {
    //     if (editingItem && playlists) {
    //         let copy = _.cloneDeep(editingItem);
    //         copy.playlists = playlists.filter(x => memberOfPlaylists.indexOf(x.id) !== -1);
    //         setEditingItem(copy);
    //     }
    // }, [memberOfPlaylists, playlists]);

    useEffect(() => {
        if(videoFile) {
            setVideoSource(URL.createObjectURL(videoFile));
            //videoRef.current.src = URL.createObjectURL(videoFile);
            //if (!videoUploadUrl) {
                Utils.getClipUploadUrl(props.instance.id, props.client.id, editingItem.id).then(response => response.json().then(async data => {
                    editingItem.uploadedVideoFilename = data.filename;
                    props.onUploadVideo(editingItem.id.replace(/_new_/, ''), videoFile, data);
                }));
            //}
        } else {
            setVideoThumb(null);
            //videoRef.current.src = "";
            setVideoSource('');
        }
    }, [videoFile]);

    useEffect(() => {
        if (videoThumbTime && editingItem) {
            editingItem.videoThumbnailTime = videoThumbTime;
        }
    }, [videoThumbTime]);

    useEffect(() => {
        if (videoWidth && editingItem) {
            editingItem.videoWidth = videoWidth;
        }
    }, [videoWidth]);

    useEffect(() => {
        if (videoHeight && editingItem) {
            editingItem.videoHeight = videoHeight;
        }
    }, [videoHeight]);

    useEffect(() => {
        if (videoDuration && editingItem) {
            editingItem.videoDuration = videoDuration;
        }
    }, [videoDuration]);

    useEffect(() => {
        if(landscapeThumbFile) {
            const reader = new FileReader();
            reader.addEventListener("load", function() {
                setLandscapeThumb(reader.result);
            });
            reader.readAsDataURL(landscapeThumbFile);
        } else {
            setLandscapeThumb(null);
        }
    }, [landscapeThumbFile]);

    useEffect(() => {
        if(portraitThumbFile) {
            const reader = new FileReader();
            reader.addEventListener("load", function() {
                setPortraitThumb(reader.result);
            });
            reader.readAsDataURL(portraitThumbFile);
        } else {
            setPortraitThumb(null);
        }
    }, [portraitThumbFile]);

    useEffect(() => {
        if(editingItem) {
            editingItem.videoFileRemoved = videoFileRemoved;
            editingItem.videoThumbFileRemoved = videoThumbFileRemoved;
            editingItem.landscapeThumbFileRemoved = landscapeThumbFileRemoved;
            editingItem.portraitThumbFileRemoved = portraitThumbFileRemoved;
        }
    }, [videoFileRemoved, videoThumbFileRemoved, landscapeThumbFileRemoved, portraitThumbFileRemoved]);

    // useEffect(() => {
    //     if(playlists && selectedPlaylists) {
    //         setAvailablePlaylists(playlists.filter(x => !selectedPlaylists.find(y => x)));
    //     }
    // }, [playlists, selectedPlaylists]);

    useEffect(() => {
        if(playlists && editingItem && (!selectedPlaylists && !availablePlaylists)) {
            const clipPlaylistIds = editingItem.__playlists.map(x => x.id);
            const selPLs = playlists.filter(x => clipPlaylistIds.indexOf(x.id) !== -1);
            setSelectedPlaylists(selPLs);
            if (selPLs.length > 0) setSelectedPlaylist(selPLs[0]);

            setAvailablePlaylists(playlists.filter(x => clipPlaylistIds.indexOf(x.id) === -1));
        }
    }, [playlists, editingItem]);

    useEffect(() => {
        if (availablePlaylists && editingItem) {
            let listCopy = [...availablePlaylists];
            let found = false;
            listCopy.map(pl => {
                const clipIndex = pl.__clips.findIndex(x => x.id === editingItem.id);
                if (clipIndex !== -1) {
                    let cliplistCopy = [...pl.__clips];
                    cliplistCopy.splice(clipIndex, 1);
                    pl.__clips = cliplistCopy;
                    found = true;
                    setSelectedPlaylist(null);
                }
            });
            if (found) {
                setAvailablePlaylists(listCopy);
            }
        }
    }, [availablePlaylists]);

    useEffect(() => {
        if (selectedPlaylists && editingItem) {
            let listCopy = [...selectedPlaylists];
            let found = false;
            listCopy.map(pl => {
                const clipIndex = pl.__clips.findIndex(x => x.id === editingItem.id);
                if (clipIndex === -1) {
                    let cliplistCopy = [...pl.__clips];
                    cliplistCopy.push(editingItem);
                    pl.__clips = cliplistCopy;
                    found = true;
                    setSelectedPlaylist(selectedPlaylists.find(x => x.id === pl.id));
                }
            });
            if (found) {
                setSelectedPlaylists(listCopy);
                editingItem.__playlists = listCopy;
            } else {
                editingItem.__playlists = selectedPlaylists;
            }
        }
    }, [selectedPlaylists]);

    useEffect(() => {
        if (editingItem && selectedPlaylist) {
            let copy = _.cloneDeep(selectedPlaylist);
            copy.clips = selectedPlaylistClips;
            let listCopy = [...selectedPlaylists];
            const plIndex = listCopy.findIndex(x => x.id === selectedPlaylist.id);
            listCopy.splice(plIndex, 1, copy);
            setSelectedPlaylists(listCopy);
         }
    }, [selectedPlaylistClips]);


    
    async function loadAsDataUrl(url) {
        let blob = await fetch(url).then(r => r.blob());
        let dataUrl = await new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
        return dataUrl;
    }

    function dataUrlToFile(dataUrl, filename) {
        var arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]); 
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while(n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    // function loadClip(clipId) {
    //     if(!props.instance || !props.client) return;

    //     Utils.loadClip(props.instance.id, props.client.id, clipId ?? "_new_").then(response => response.json().then(async data => {
    //         setEditingItem(_.cloneDeep(data));
    //         setSegments(data.segments);
    //         setSelectedProducts([...data.products].sort((a, b) => a.order < b.order ? -1 : 1));
    //         setAvailableProducts([...data.clientProducts].sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})).filter(x => !data.products.find(y => y.id === x.id)));

    //         if (data.existingVideoUrl) {
    //             // if (videoRef.current)
    //             //     videoRef.current.src = data.existingVideoUrl;
    //             setVideoSource(data.existingVideoUrl);
    //             setVideoThumbTime(data.videoThumbnailTime);
    //             setHasExistingVideo(true);
    //         }
            
    //         if (data.existingVideoThumbUrl) {
    //             setVideoThumb(await loadAsDataUrl(`/render/image?url=${data.existingVideoThumbUrl}&width=1280`));
    //         }

    //         if (data.existingCustomLandscapeThumbUrl) {
    //             setLandscapeThumb(await loadAsDataUrl(`/render/image?url=${data.existingCustomLandscapeThumbUrl}&width=1280`));
    //         }

    //         if (data.existingCustomPortraitThumbUrl) {
    //             setPortraitThumb(await loadAsDataUrl(`/render/image?url=${data.existingCustomPortraitThumbUrl}&width=1280`));
    //         }

    //         //const playlistIds = data.playlists.map(x => x.id);
    //         //setSelectedPlaylists(data.playlists);
    //         //setMemberOfPlaylists(playlistIds);
    //     }));
    // }

    // function loadClientPlaylists() {
    //     if(!props.instance || !props.client) return;
    //     Utils.loadPlaylists(props.instance.id, props.client.id).then(response => response.json().then(data => {
    //         setPlaylists(data);
    //     }));
    // }

    function saveClip(clip, saveAndExit = false) {
        if (clip.name.trim() == '') {
            alert("Please provide a name for the video.");
            return;
        }

        //const isCreating = !clip?.id;
        const isCreating = clip?.id.indexOf("_new_") === 0;
        const url = isCreating ? Utils.getClipsUrl(props.instance.id, props.client.id) : Utils.getClipUrl(props.instance.id, props.client.id, clip.id);

        let model = Utils.sanitizeObject(clip);
        model.segments = clip.segments;
        model.playlistDTOs = selectedPlaylists.map(playlist => { 
            return {
                id: playlist.id,
                clipIds: playlist.__clips.map(x => x.id)
            }
        });
        model.productDTOs = selectedProducts.map(product => {
            return {
                id: product.id
            }
        });

        if (translatedClientClips.length > 0) {
            model.clipGroups = translatedClientClips.map(x => {
                return { 
                    clientId: x.id, 
                    itemId: x.clip.id 
                };
            });
            model.clipGroups.push({ 
                clientId: props.client.id, 
                itemId: props.item.id 
            });
        }

        // let model = {
        //     id: clip.id,
        //     name: clip.name,
        //     titleOnThumbnailHidden: clip.titleOnThumbnailHidden,
        //     description: clip.description,
        //     videoDuration: clip.videoDuration,
        //     videoWidth: clip.videoWidth,
        //     videoHeight: clip.videoHeight,
        //     videoThumbnailTime: clip.videoThumbnailTime,
        //     existingVideoThumbUrl: clip.existingVideoThumbUrl,
        //     existingCustomLandscapeThumbUrl: clip.existingCustomLandscapeThumbUrl,
        //     existingCustomPortraitThumbUrl: clip.existingCustomPortraitThumbUrl,
        //     segments: clip.segments,
        //     uploadedVideoFilename: clip.uploadedVideoFilename,
        //     playlists: selectedPlaylists.map(playlist => { 
        //         return {
        //             id: playlist.id,
        //             clips: playlist.clips.map(clip => clip.id)
        //         }
        //     })
        // }

        // let clipCopy = _.cloneDeep(clip);
        // debugger;

        // for(let pl = 0; pl < clipCopy.__playlists.length; pl++) {
        //     let playlist = clipCopy.__playlists[pl];
        //     delete playlist.__client;

        //     for(let cl = 0; cl < playlist.__clips.length; cl++) {
        //         let clip = playlist.__clips[cl];
        //         delete clip.__client;
        //         delete clip.__playlists;
        //         delete clip.__products;
        //         debugger;
        //     }
        // }

        // for(let pl = 0; pl < clipCopy.__playlists.length; pl++) {
        //     let playlist = clipCopy.__playlists[pl];

        //     for(let cl = 0; cl < playlist.__clips.length; cl++) {
        //         let clip = _.clone(playlist.__clips[cl]);
        //         debugger; 
        //         //clip.__playlists = [];
        //         playlist.__clips.splice(cl, 1, clip);
        //         clip.playlists = clip.__playlists;
        //         delete clip.__playlists;
        //         delete clip.__client;
        //         delete clip.__products;
        //     }
        //     playlist.clips = playlist.__clips;
        //     delete playlist.__clips;
        //     delete playlist.__client;
        // }
        // clipCopy.playlists = clipCopy.__playlists;
        // delete clipCopy.__playlists;
        // delete clipCopy.__products;
        // delete clipCopy.__client;


        let data = new FormData();
        data.append("json", JSON.stringify(model));
        if (videoThumbFile) data.append("videoThumb", videoThumbFile); 
        if (landscapeThumbFile) data.append("landscapeThumb", landscapeThumbFile); 
        if (portraitThumbFile) data.append("portraitThumb", portraitThumbFile); 
        //if (videoFile) data.append("video", videoFile); 

        setIsBusy(true);
        fetch(url, {
            method: isCreating ? 'POST' : 'PUT',
            body: data
        }).then(response => response.json()).then((response) => {
            setIsBusy(false);
            props.onSaved(response, saveAndExit);
        });
    }
//.then(response => response.json()
    function deleteClip(clip) {
        if (window.confirm("Are you sure?")) {
            setIsBusy(true);
            fetch(Utils.getClipUrl(props.instance.id, props.client.id, clip.id), Utils.createRequest('DELETE')).then(() => {
                setIsBusy(false);
                props.onDeleted(clip);
            });
        }
    }

    function resetViewToBrowse() {
        props.onDone();
    }

    function addNewSegment() {
        if (!newSegmentName) { alert("Segment name is required."); return; }
        if (!newSegmentMinutes || !newSegmentSeconds) { alert("Segment start time (minutes and seconds) is required."); return; }

        let seg = [...segments];
        seg.push({ title: newSegmentName ?? 'segment', startTime: parseInt(newSegmentMinutes) * 60 + parseInt(newSegmentSeconds) });
        setSegments(seg);

        setNewSegmentName('');
        setNewSegmentMinutes('');
        setNewSegmentSeconds('');
    }

    function setSegmentDetails(segment, title, minutes, seconds) {
        const idx = segments.indexOf(segment);
        if (idx !== -1) {
            let seg = _.cloneDeep(segments[idx]);
            const ttl = seg.title;
            const min = Math.trunc(seg.startTime / 60);
            const sec = seg.startTime % 60;

            seg.title = title ?? ttl;
            seg.startTime = parseInt(minutes ?? min) * 60 + parseInt(seconds ?? sec);

            let segmentsCopy = [...segments];
            segmentsCopy.splice(idx, 1, seg);
            setSegments(segmentsCopy);
        }
    }

    function deleteSegment(segment) {
        const idx = segments.indexOf(segment);
        if (idx !== -1) {
            let segmentsCopy = [...segments];
            segmentsCopy.splice(idx, 1);
            setSegments(segmentsCopy);
        }
    }

    function addNewProduct() {
        if (newProductName && newProductName != '' && !availableProducts.find(x => x.content === newProductName) && !selectedProducts.find(x => x.content === newProductName))  {
            let data = new FormData();
            data.append("json", JSON.stringify({ name: newProductName }));
    
            fetch(Utils.getProductsUrl(props.instance.id, props.client.id), {
                method: 'POST',
                body: data
            }).then(response => response.json()).then((response) => {
                let products = [...selectedProducts];
                products.push(response);
                setSelectedProducts(products);
            });
        }
        setNewProductName('');
    }

    function addNewPlaylist() {
        if (newPlaylistName && newPlaylistName != '' && !playlists.find(x => x.name === newPlaylistName))  {
            setIsBusy(true);
            fetch(Utils.getPlaylistsUrl(props.instance.id, props.client.id), Utils.createRequest('POST', { name: newPlaylistName })).then(response => response.json().then(data => {
                setIsBusy(false);
                let pl = [...selectedPlaylists];
                let playlist = data.playlists.find(x => x.name === newPlaylistName);
                playlist = Utils.processPlaylist(playlist, props.client, props.client.__clips);
                pl.push(playlist);
                setSelectedPlaylists(pl);
                setNewPlaylistName('');
                props.onPlaylistCreated(data);
            }));
        }
    }

    function moveClipInPlaylist(clipId, direction) {
        let lists = _.cloneDeep(playlists);
        const plIndex = lists.findIndex(x => x.id == selectedPlaylist.id);

        let clips = [...lists[plIndex].clips];
        let idx = clips.findIndex(x => x.id === clipId);
        if (direction === -1)
            [clips[idx - 1], clips[idx]] = [clips[idx], clips[idx - 1]];
        else
            [clips[idx + 1], clips[idx]] = [clips[idx], clips[idx + 1]];
        lists[plIndex].clips = clips;
        let selPlaylistCopy = _.cloneDeep(selectedPlaylist);
        selPlaylistCopy.clips = clips;
        setSelectedPlaylist(selPlaylistCopy);
        setPlaylists(lists);
    }

    function updateEditingItem(field, value) {
        let obj = editingItem;
        if (field.indexOf('.') === -1) {
            editingItem[field] = value;
        } else {
            let fields = field.split('.');
            let existTest = obj;
            fields.map(x => {
                if (existTest[x] === undefined) existTest[x] = {};
                existTest = existTest[x];
            });
            const lastField = fields[fields.length - 1];
            fields = _.take(fields, fields.length - 1);
            fields.map(x => obj = obj[x]);
            obj[lastField] = value;
        }
        forceUpdate();
    }

    function showAddProductModal() {
        setNewProductEditingItem({ name: '', comments: ''});
        setAddProductModalOpen(true);
    }

    function newProductAdded(product) {
        let prods = [...selectedProducts];
        prods.push(product);
        setSelectedProducts(prods);
        setAddProductModalOpen(false);
    }

    function toggleTagSelected(tag)
    {
        let availProds = [...availableProducts];
        let selProds = [...selectedProducts];
        let availIdx = availableProducts.indexOf(tag);
        if (availIdx !== -1) {
            availProds.splice(availIdx, 1);
            selProds.push(tag);
        } else {
            selProds.splice(selProds.indexOf(tag), 1);
            availProds.push(tag);
        }
        setAvailableProducts(availProds);
        setSelectedProducts(selProds);
    }

    function togglePlaylistSelected(playlist)
    {
        let availPlLists = [...availablePlaylists];
        let selPlLists = [...selectedPlaylists];
        let availIdx = availablePlaylists.indexOf(playlist);

        if (availIdx !== -1) {
            availPlLists.splice(availIdx, 1);
            selPlLists.push(playlist);
        } else {
            selPlLists.splice(selPlLists.indexOf(playlist), 1);
            availPlLists.push(playlist);
        }
        setAvailablePlaylists(availPlLists);
        setSelectedPlaylists(selPlLists);
    }

    function toggleTranslatedClientClip(client, clip) {
        let clone = _.cloneDeep(translatedClientClips);
        const idx = clone.findIndex(x => x.id == client.id);

        if (idx != -1) {
            clone.splice(idx, 1);
        }
        if (clip) {
            let clientClone = {...client};
            clientClone.clip = {...clip};
            clone.push(clientClone);
        }

        setTranslatedClientClips(clone);
    }

    function isClientGroupClipSelected(client, clip) {
        const clp = translatedClientClips.find(x => x.id == client.id);
        return clp?.clip?.id == clip.id;
    }


    return (
        <>
            <div className={`${editingItem && !isBusy ? '' : 'opacity-30 pointer-events-none'} py-6 bg-gray-100`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">{props.view === 'createClipView' ? "Create video" : "Edit video"}</h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-5">

                    {useTabs &&
                        <Tabs tabs={tabs} activeTab={activeTab} onSetActiveTab={(tab) => setActiveTab(tab)} />
                    }
                    


                    <form className="space-y-8 sm:space-y-5 divide-y divide-gray-200" onSubmit={(ev) => { ev.preventDefault(); return false; }}>
                        
                        
                        
                        {(!useTabs || activeTab === tabVideo) &&
                            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                {!useTabs &&
                                    <h3 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">{tabVideo.name}</h3>
                                }

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Video
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">



                                        <div className="grid grid-flow-row grid-cols-2 gap-4">
                                            <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                                <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                                    Upload video
                                                </div>

                                                <Dropzone onDrop={(files) => setVideoFile(files[0])}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <div {...getRootProps()} className="flex justify-center px-6 pt-5 pb-6 m-3 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <FontAwesomeIcon icon={['fas', 'file-video']} size="2x" className="text-gray-400" />
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label
                                                                        // htmlFor="video-upload"
                                                                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                    >
                                                                        <span>Upload a video</span>
                                                                    </label>
                                                                    <input {...getInputProps()} id="video-upload" name="video-upload" type="file" accept="video/mp4" className="sr-only" />
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">MP4</p>
                                                            </div>
                                                        </div>                                                    
                                                    )}                                            
                                                </Dropzone>

                                                {(videoFile || hasExistingVideo) &&
                                                    <button
                                                        type="button"
                                                        onClick={() => { setHasExistingVideo(false); setVideoFile(null); setVideoFileRemoved(true); setVideoThumbFileRemoved(true); }}
                                                        className="block mt-2 mx-auto bg-white py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none"
                                                    >
                                                        <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                                        Remove file
                                                    </button>
                                                }
                                            </div>
                                            <div className="shadow border border-gray-200 sm:rounded-lg w-full flex flex-col">
                                                <div className="bg-white px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                                    Video preview
                                                </div>
                                                <video ref={videoRef} id="video" controls="controls" crossOrigin="anonymous" className="sm:rounded-b-lg max-h-96" onLoadedData={handleLoadedData} onTimeUpdate={handleTimeupdate} src={videoSource}>
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Segments
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Start time
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Segment name
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {[...segments].sort((a, b) => a.startTime < b.startTime ? -1 : 1).map((seg) => (
                                                    <tr key={`index_${seg.startTime}`}>
                                                        <td className="px-6 py-2 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <select
                                                                    className="block w-full pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                                    value={Math.trunc(seg.startTime / 60)}
                                                                    onChange={e => setSegmentDetails(seg, null, e.target.value) }
                                                                >
                                                                    {minutes.map(x => 
                                                                        <option key={x}>{x}</option>
                                                                    )}
                                                                </select>
                                                                <div className="px-2">
                                                                    :
                                                                </div>
                                                                <select
                                                                    className="block w-full pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                                    value={String(seg.startTime % 60).padStart(2, '0')}
                                                                    onChange={e => setSegmentDetails(seg, null, null, e.target.value) }
                                                                >
                                                                    {seconds.map(x => 
                                                                        <option key={x}>{x}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td className="pl-6 py-2 whitespace-nowrap">
                                                            <input
                                                                type="text"
                                                                className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                                value={seg.title}
                                                                onChange={e => setSegmentDetails(seg, e.target.value) }
                                                            />
                                                        </td>
                                                        <td className="pr-6 py-2 whitespace-nowrap text-right">
                                                            <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" onClick={() => deleteSegment(seg)} className="text-red-600 cursor-pointer" />
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <div className="flex sm:col-span-2">
                                                <div className="relative flex items-stretch flex-grow focus-within:z-10 border-t border-gray-300">
                                                    <div className="flex items-center">
                                                        <select
                                                            id="newsegmentminutes"
                                                            name="newsegmentminutes"
                                                            className="block pl-3 pr-10 py-2 text-base border-none sm:text-sm h-full sm:rounded-bl-md"
                                                            value={newSegmentMinutes}
                                                            onChange={(ev) => setNewSegmentMinutes(ev.target.value) }
                                                        >
                                                            <option value="" disabled>Min</option>
                                                            {minutes.map(x => 
                                                                <option key={x}>{x}</option>
                                                            )}
                                                        </select>
                                                        <div className="px-2">
                                                            :
                                                        </div>
                                                        <select
                                                            id="newsegmentseconds"
                                                            name="newsegmentseconds"
                                                            className="block pl-3 pr-10 py-2 text-base border-none sm:text-sm h-full"
                                                            value={newSegmentSeconds}
                                                            onChange={(ev) => setNewSegmentSeconds(ev.target.value) }
                                                        >
                                                            <option value="" disabled>Sec</option>
                                                            {seconds.map(x => 
                                                                <option key={x}>{x}</option>
                                                            )}
                                                        </select>
                                                    </div>

                                                    <input
                                                        type="text"
                                                        placeholder="Segment name"
                                                        name="newsegmentname"
                                                        id="newsegmentname"
                                                        className="block w-full rouned-none sm:rounded-bl-lg sm:text-sm border-none"
                                                        value={newSegmentName}
                                                        onChange={e => setNewSegmentName(e.target.value) }
                                                        onKeyPress={(ev) => { if(ev.key === 'Enter') addNewSegment() }}
                                                    />
                                                </div>

                                                <button
                                                    type="button"
                                                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border-t border-l border-gray-300 text-sm font-medium rounded-br-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                    >
                                                    <FontAwesomeIcon icon={['fas', 'plus']} size="lg" onClick={() => addNewSegment()} className="" />
                                                </button>
                                            </div>




                                        </div>
                                    </div>
                                </div>





                                
                                
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Thumbnail
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="grid grid-flow-row grid-cols-5 gap-4">
                                            <div className="col-span-3">
                                                <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                                    <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                                        Landscape
                                                    </div>
                                                    <div className="aspect-w-16 aspect-h-9 bg-gray-100">
                                                        <div className="w-full h-full">
                                                            <img src={landscapeThumb ?? videoThumb} className="object-cover w-full max-h-full my-auto" alt="" />
                                                        </div>
                                                    </div>

                                                    {landscapeThumb &&
                                                        <button
                                                            type="button"
                                                            onClick={() => { setLandscapeThumb(null); setLandscapeThumbFile(null); setLandscapeThumbFileRemoved(true); }}
                                                            className="block mt-2 mx-auto bg-white py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none"
                                                        >
                                                            <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                                            Remove file
                                                        </button>
                                                    }

                                                    <Dropzone onDrop={(files) => setLandscapeThumbFile(files[0])}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <div {...getRootProps()} className="flex justify-center px-6 pt-5 pb-6 m-3 border-2 border-gray-300 border-dashed rounded-md">
                                                                <div className="space-y-1 text-center">
                                                                    <FontAwesomeIcon icon={['fas', 'image']} size="2x" className="text-gray-400" />
                                                                    <div className="flex text-sm text-gray-600">
                                                                        <label
                                                                            // htmlFor="landscape-thumb-upload"
                                                                            className="relative bg-white rounded-md"
                                                                        >
                                                                            <span className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload an image</span>
                                                                            <span className="pl-1">or drag and drop</span>
                                                                        </label>
                                                                        
                                                                    </div>
                                                                    <p className="text-xs text-gray-500">PNG, JPG or GIF</p>
                                                                </div>                                                            
                                                                <input {...getInputProps()} id="landscape-thumb-upload" name="landscape-thumb-upload" type="file" accept=".gif,.jpg,.jpeg,.png" className="sr-only" />
                                                            </div>                                                    
                                                        )}                                            
                                                    </Dropzone>
                                                </div>
                                            </div>
                                            <div className="col-span-2">
                                                <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                                    <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                                        Portrait / small screens
                                                    </div>
                                                    <div className="aspect-w-3 aspect-h-4 bg-gray-100">
                                                        <div className="w-full h-full">
                                                            <img src={portraitThumb ?? videoThumb} className="object-cover max-w-full h-full mx-auto" alt="" />
                                                        </div>
                                                    </div>

                                                    {portraitThumb &&
                                                        <button
                                                            type="button"
                                                            onClick={() => { setPortraitThumb(null); setPortraitThumbFile(null); setPortraitThumbFileRemoved(true); }}
                                                            className="block mt-2 mx-auto bg-white py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none"
                                                        >
                                                            <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                                            Remove file
                                                        </button>
                                                    }

                                                    <Dropzone onDrop={(files) => setPortraitThumbFile(files[0])}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <div {...getRootProps()} className="flex justify-center px-6 pt-5 pb-6 m-3 border-2 border-gray-300 border-dashed rounded-md">
                                                                <div className="space-y-1 text-center">
                                                                    <FontAwesomeIcon icon={['fas', 'image']} size="2x" className="text-gray-400" />
                                                                    <div className="flex text-sm text-gray-600">
                                                                        <label
                                                                            // htmlFor="portrait-thumb-upload"
                                                                            className="relative bg-white rounded-md"
                                                                        >
                                                                            <span className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload an image</span>
                                                                            <span className="pl-1">or drag and drop</span>
                                                                        </label>
                                                                    </div>
                                                                    <p className="text-xs text-gray-500">PNG, JPG or GIF</p>
                                                                </div>
                                                                <input {...getInputProps()} id="portrait-thumb-upload" name="portrait-thumb-upload" type="file" accept=".gif,.jpg,.jpeg,.png" className="sr-only" />
                                                            </div>                                                    
                                                        )}                                            
                                                    </Dropzone>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(!useTabs || activeTab === tabGeneral) &&
                            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                {!useTabs &&
                                    <h3 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">{tabGeneral.name}</h3>
                                }
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Video URL
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="flex">
                                            <p className="flex-1 block w-full min-w-0 text-gray-500 font-mono">
                                                {editingItem?.url}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="clipname" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Video name
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm sm:col-span-2">
                                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                            <input
                                                type="text"
                                                name="clipname"
                                                id="clipname"
                                                className="block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                                                value={editingItem?.name ?? ''}
                                                onChange={e => updateEditingItem('name', e.target.value) }
                                            />
                                        </div>

                                        <div className="relative flex -ml-px inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50">
                                            <div className="flex items-center h-5">
                                                <input
                                                    id="hidetitle"
                                                    name="hidetitle"
                                                    type="checkbox"
                                                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                    checked={editingItem?.titleOnThumbnailHidden ?? false}
                                                    onChange={e => updateEditingItem('titleOnThumbnailHidden', e.target.checked) }
                                                    />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="hidetitle" className="font-medium text-gray-700">
                                                    <FontAwesomeIcon icon={['fas', 'eye-slash']} size="lg" className="text-gray-400" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="title" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Title
                                    </label>


                                    <div className="mt-1 flex rounded-md shadow-sm sm:col-span-2">
                                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                            <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                className="block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                                                value={editingItem?.title ?? ''}
                                                onChange={e => updateEditingItem('title', e.target.value) }
                                            />
                                        </div>

                                        <div className="relative flex -ml-px inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50">
                                            <div className="flex items-center h-5">
                                                <input
                                                    id="hidetitle"
                                                    name="hidetitle"
                                                    type="checkbox"
                                                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                    checked={editingItem?.titleOnThumbnailHidden ?? false}
                                                    onClick={e => updateEditingItem('titleOnThumbnailHidden', e.target.checked) }
                                                    />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="hidetitle" className="font-medium text-gray-700">
                                                    <FontAwesomeIcon icon={['fas', 'eye-slash']} size="lg" className="text-gray-400" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                </div> */}

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Description
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <textarea
                                            id="description"
                                            name="description"
                                            rows={3}
                                            className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                            value={editingItem?.description ?? ''}
                                            onChange={e => updateEditingItem('description', e.target.value) }
                                        />
                                    </div>
                                </div>   

                            </div>
                        }

                        {(!useTabs || activeTab === tabPublish) &&
                            <div>
                                {!useTabs &&
                                    <h3 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">{tabPublish.name}</h3>
                                }
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="ispublished" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Video is published
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <Toggle value={editingItem?.isPublished ?? false} onChange={(isOn) => updateEditingItem('isPublished', isOn)} />
                                        {/* <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                name="ispublished"
                                                id="ispublished"
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                checked={editingItem?.isPublished ?? false}
                                                onChange={e => updateEditingItem('isPublished', e.target.checked) }
                                            />
                                        </div> */}
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Publish dates
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm sm:col-span-2">


                                        <fieldset className="w-full">
                                            <div className="flex rounded-md shadow-sm sm:col-span-2">
                                                <div className="relative flex inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-tl-md text-gray-700 bg-gray-50 w-20">
                                                    <label htmlFor="datetime-local" className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        From
                                                    </label>
                                                </div>

                                                <div className="-ml-px relative flex items-stretch flex-grow">
                                                    <input
                                                        type="datetime-local"
                                                        name="publish-from"
                                                        id="publish-from"
                                                        className="block w-full rounded-none rounded-tr-md sm:text-sm border-gray-300"
                                                        value={editingItem?.datePublishedFrom ?? ''}
                                                        onChange={e => updateEditingItem('datePublishedFrom', e.target.value) }
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex rounded-md shadow-sm sm:col-span-2 -mt-px">
                                                <div className="relative flex inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-bl-md text-gray-700 bg-gray-50 w-20">
                                                    <label htmlFor="datetime-local" className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        To
                                                    </label>
                                                </div>

                                                <div className="-ml-px relative flex items-stretch flex-grow">
                                                    <input
                                                        type="datetime-local"
                                                        name="publish-to"
                                                        id="publish-to"
                                                        className="block w-full rounded-none rounded-br-md sm:text-sm border-gray-300"
                                                        value={editingItem?.datePublishedTo ?? ''}
                                                        onChange={e => updateEditingItem('datePublishedTo', e.target.value) }
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>


                                    </div>
                                </div>

                            </div>
                        }
                        {(!useTabs || activeTab === tabProducts) &&
                            <div>
                                {!useTabs &&
                                    <h3 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">{tabProducts.name}</h3>
                                }
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    {/* <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Products
                                    </label> */}
                                    <div className="mt-1 sm:mt-0 sm:col-span-3">
                                        <div className="grid grid-flow-row grid-cols-2 gap-4">
                                            <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                                <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                                    Available
                                                </div>
                                                <ul className="h-64 overflow-y-scroll">
                                                    {availableProducts.map(x => {
                                                        return (
                                                            <li key={x.id} className="bg-gray-100 border border-gray-200 text-gray-600 px-3 py-1 rounded m-1 flex flex-cols space-x-3 items-center cursor-pointer">
                                                                <div className="flex-1">
                                                                    {x.name}
                                                                </div>
                                                                <div onClick={() => toggleTagSelected(x)}>
                                                                    <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} size="lg" className="text-gray-400" />
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                                {/* <div className="h-64">
                                                    <ProductsDraggableAreaFrom
                                                        tags={availableProducts}
                                                        className="p-3"
                                                        render={({tag, index}) => (
                                                        <div onClick={() => toggleTagSelected(tag)} className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 text-center cursor-drag">
                                                            {tag.name}
                                                        </div>
                                                        )}
                                                        onChange={products => setAvailableProducts(products)}
                                                    />
                                                </div> */}
                                            </div>
                                            <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                                <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                                    Selected
                                                </div>
                                                <ul className="h-64 overflow-y-scroll">
                                                    {selectedProducts.map(x => {
                                                        return (
                                                            <li key={x.id} className="bg-gray-100 border border-gray-200 text-gray-600 px-3 py-1 rounded m-1 flex flex-cols space-x-3 items-center cursor-pointer">
                                                                <div onClick={() => toggleTagSelected(x)}>
                                                                    <FontAwesomeIcon icon={['fas', 'arrow-circle-left']} size="lg" className="text-gray-400" />
                                                                </div>
                                                                <div className="flex-1">
                                                                    {x.name}
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>

                                                {/* <div className="h-64">
                                                    <ProductsDraggableAreaTo
                                                        tags={selectedProducts}
                                                        className="p-3"
                                                        render={({tag, index}) => (
                                                        <div onClick={() => toggleTagSelected(tag)} className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 text-center cursor-drag">
                                                            {tag.name}
                                                        </div>
                                                        )}
                                                        onChange={products => setSelectedProducts(products)}
                                                    />
                                                </div> */}
                                                <div className="text-left text-xs font-medium">
                                                    {/* <div className="flex sm:col-span-2">
                                                        <div className="relative flex items-stretch flex-grow focus-within:z-10 border-t border-gray-300">
                                                            <input
                                                                type="text"
                                                                placeholder="Add product..."
                                                                name="addproduct"
                                                                id="addproduct"
                                                                className="block w-full rounded-none sm:rounded-bl-lg sm:text-sm border-none"
                                                                value={newProductName}
                                                                onChange={e => setNewProductName(e.target.value) }
                                                                onKeyPress={(ev) => { if(ev.key === 'Enter') addNewProduct(); }}
                                                            />
                                                        </div> */}

                                                        <button
                                                            type="button"
                                                            // onClick={() => { showAddProductModal(); addNewProduct();}} 
                                                            className="w-full flex items-center justify-center space-x-2 px-4 py-2 border-t border-l border-gray-300 text-sm font-medium rounded-br-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                            >
                                                            <FontAwesomeIcon icon={['fas', 'plus']} size="lg" className="" />
                                                            <p className="ml-2">Create new product (disabled)</p>
                                                        </button>
                                                    {/* </div> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                        {(!useTabs || activeTab === tabPlaylists) &&
                            <div>
                                {!useTabs &&
                                    <h3 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">{tabPlaylists.name}</h3>
                                }
                                <div className="grid grid-flow-row grid-cols-3 gap-4 mt-8">
                                    <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                        <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                            Not included in
                                        </div>
                                         <ul className="h-64 overflow-y-scroll">
                                            {(availablePlaylists || []).map(x => {
                                                return (
                                                    <li key={x.id} className="border border-gray-200 text-gray-600 rounded m-1 flex flex-cols items-center cursor-pointer divide-x divide-gray-200">
                                                        <div className="bg-gray-50 flex-1 px-3 py-1 space-x-3">
                                                            <FontAwesomeIcon icon={['fas', 'list']} />
                                                            <span>{x.name}</span>
                                                        </div>
                                                        <div onClick={() => togglePlaylistSelected(x)} className="bg-gray-100 px-3 py-1">
                                                            <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} size="lg" className="text-gray-400" />
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>

                                        {/* <div className="h-64">
                                            <PlaylistsDraggableAreaFrom isList
                                                tags={availablePlaylists || []}
                                                className="p-3"
                                                render={({tag, index}) => (
                                                <div className="m-1 rounded-md text-sm py-0 px-2 text-gray-600 bg-gray-200 border border-gray-300 text-center cursor-drag">
                                                    {tag.name}
                                                </div>
                                                )}
                                                onChange={playlists => setAvailablePlaylists(playlists)}
                                            />
                                        </div> */}


                                        <div className="text-left text-xs font-medium">
                                            <div className="flex sm:col-span-2">
                                                <div className="relative flex items-stretch flex-grow focus-within:z-10 border-t border-gray-300">
                                                    <input
                                                        type="text"
                                                        placeholder="Playlist name..."
                                                        name="addplaylist"
                                                        id="addplaylist"
                                                        className="block w-full rounded-none sm:rounded-bl-lg sm:text-sm border-none"
                                                        value={newPlaylistName}
                                                        onChange={e => setNewPlaylistName(e.target.value) }
                                                        onKeyPress={(ev) => { if(ev.key === 'Enter') addNewPlaylist() }}
                                                    />
                                                </div>

                                                <button
                                                    type="button"
                                                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border-t border-l border-gray-300 text-sm font-medium rounded-br-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                    >
                                                    <FontAwesomeIcon icon={['fas', 'plus']} size="lg" onClick={() => addNewPlaylist()} className="" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                        <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                            Included in
                                        </div>

                                        <ul className="h-64 overflow-y-scroll">
                                            {(selectedPlaylists || []).map(x => {
                                                return (
                                                    <li key={x.id} className={`${x.id === selectedPlaylist?.id ? 'border-blue-300 divide-blue-300' : 'border-gray-200 divide-gray-200'} border text-gray-600 rounded m-1 flex flex-cols items-center cursor-pointer divide-x`}>
                                                        <div onClick={() => togglePlaylistSelected(x)} className={`${x.id === selectedPlaylist?.id ? 'bg-blue-200' : 'bg-gray-100'} px-3 py-1`}>
                                                            <FontAwesomeIcon icon={['fas', 'arrow-circle-left']} size="lg" className={`${x.id === selectedPlaylist?.id ? 'text-blue-400' : 'text-gray-400'}`} />
                                                        </div>
                                                        <div onClick={() => setSelectedPlaylist(x)} className={`${x.id === selectedPlaylist?.id ? 'bg-blue-100' : 'bg-gray-50'} flex-1 px-3 py-1 space-x-3`}>
                                                            <FontAwesomeIcon icon={['fas', 'list']} />
                                                            <span>{x.name}</span>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        {/* <div className="h-64">
                                            <PlaylistsDraggableAreaTo isList
                                                tags={selectedPlaylists || []}
                                                className="p-3"
                                                render={({tag, index}) => (
                                                <div onClick={() => { setSelectedPlaylist(tag);}} 
                                                    className={`${tag === selectedPlaylist ? 'bg-blue-200 ' : 'bg-gray-200 '} m-1 rounded-md text-sm py-0 px-2 text-gray-600 border border-gray-300 text-center cursor-drag`}>
                                                    {tag.name}
                                                </div>
                                                )}
                                                onChange={(playlists) => setSelectedPlaylists(playlists)}
                                            />
                                        </div> */}
                                    </div>

                                    <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                        <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                            Videos in selected playlist
                                        </div>

                                        {/* <div className="p-3 h-64 overflow-y-scroll">
                                            <SortableDragList 
                                                items={selectedPlaylistClips} 
                                                idProperty="id"
                                                textProperty="name"
                                                showIndex={true}
                                                firstItemIndex={1}
                                                onChanged={updatedList => setSelectedPlaylistClips(updatedList) } 
                                            />
                                        </div> */}

                                        <div className="text-left font-medium">
                                            <DraggableArea
                                                isList
                                                tags={selectedPlaylistClips}
                                                className="h-64 overflow-y-scroll"
                                                render={({tag, index}) => (
                                                <div className={`${tag.id === editingItem.id ? 'bg-blue-100 border-blue-300 divide-blue-300' : 'bg-gray-50 border-gray-200 divide-gray-200'} mx-1 mt-1 flex justify-between items-center rounded-md text-gray-600 border cursor-drag divide-x`}>
                                                    <div className={`${tag.id === editingItem.id ? 'bg-blue-200' : 'bg-gray-100'} px-3 py-1`}>
                                                        <div className="bg-gray-400 text-gray-50 text-xs rounded-full w-5 h-5 flex items-center justify-center">
                                                            {index + 1}
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 px-3 py-1 space-x-3">
                                                        <FontAwesomeIcon icon={['fas', 'video']} />
                                                        <span>{tag.name}</span>
                                                    </div>
                                                </div>
                                                )}
                                                onChange={clips => setSelectedPlaylistClips(clips)}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>




















                        }

                        {(!useTabs || activeTab === tabPlaylists) && props.client.clientGroupId && clientGroupClips.length > 0 &&
                            <div>
                                {!useTabs &&
                                    <h3 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">{tabTranslations.name}</h3>
                                }
                                <div className="grid grid-flow-row grid-cols-3 gap-4 mt-8">

                                    <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                        <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                            Other clients in group
                                        </div>
                                        <ul className="h-64 overflow-y-scroll">
                                            {clientGroupClips.map(client => {
                                                return (
                                                    <li key={client.id} className={`${client.id === selectedClientGroupClient?.id ? 'bg-blue-200 border-blue-300 divide-blue-300' : 'bg-gray-100 border-gray-200 divide-gray-200'} text-gray-600 border rounded m-1 flex flex-cols items-center cursor-pointer divide-x`}>
                                                        <div className={`${client.id === selectedClientGroupClient?.id ? 'bg-blue-100' : 'bg-gray-50'} flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center`}>
                                                            <FontAwesomeIcon icon={['fas', 'users']} />
                                                            <span className="truncate">{client.name} ({client.culture})</span>
                                                        </div>
                                                        <div onClick={() => setSelectedClientGroupClient(client)} className={`${client.id === selectedClientGroupClient?.id ? 'text-blue-400' : 'text-gray-400'} px-3 py-1`}>
                                                            <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} size="lg" />
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                        <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                            Videos in client
                                        </div>
                                        <ul className="h-64 overflow-y-scroll">
                                            {selectedClientGroupClient && [...selectedClientGroupClient.__clips].sort((a, b) => a.name < b.name ? -1 : 1).map(clip => {
                                                return (
                                                    <li key={clip.id} className={`${isClientGroupClipSelected(selectedClientGroupClient, clip) ? 'bg-blue-200 border-blue-300 divide-blue-300' : 'bg-gray-100 border-gray-200 divide-gray-200'} text-gray-600 border rounded m-1 flex flex-cols items-center cursor-pointer divide-x`}>
                                                        <div className={`${isClientGroupClipSelected(selectedClientGroupClient, clip) ? 'bg-blue-100' : 'bg-gray-50'} flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center`}>
                                                            <FontAwesomeIcon icon={['fas', 'video']} />
                                                            <span className="truncate">{clip.name}</span>
                                                        </div>
                                                        <div onClick={() => toggleTranslatedClientClip(selectedClientGroupClient, clip)} className={`${isClientGroupClipSelected(selectedClientGroupClient, clip) ? 'text-blue-400' : 'text-gray-400'} px-3 py-1`}>
                                                            <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} size="lg" />
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>                                    
                                    
                                    <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                        <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                            Selected translations
                                        </div>
                                        <ul className="h-64 overflow-y-scroll">
                                            {translatedClientClips.map(client => {
                                                return (
                                                    <li key={client.id} className="border border-gray-200 text-gray-600 rounded m-1 flex flex-cols items-center cursor-pointer divide-x divide-gray-200">
                                                        <div onClick={() => toggleTranslatedClientClip(client, null)} className="bg-gray-100 px-3 py-1">
                                                            <FontAwesomeIcon icon={['fas', 'arrow-circle-left']} size="lg" className="text-gray-400" />
                                                        </div>
                                                        <div className="bg-gray-50 flex-1 px-2 py-1 space-x-2 min-w-0 flex flex-cols items-center">
                                                            <FontAwesomeIcon icon={['fas', 'video']} />
                                                            <span className="truncate">({client.culture}) {client.clip.name}</span>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>                                    
                                </div>
                            </div>
                        }

                        <div className="pt-5">
                            <div className={`flex ${props.view === 'createClipView' ? "justify-end" : "justify-between"}`}>
                                {props.view === 'editClipView' &&
                                    <button
                                        type="button"
                                        onClick={() => !isBusy && deleteClip(editingItem)}
                                        className={`bg-red-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-700 focus:outline-none`}
                                        disabled={isBusy}
                                    >
                                        <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'trash-alt' ]} spin={isBusy} size="lg" className="mr-2" />
                                        Delete
                                    </button>
                                }
                                <div className="flex content-start">
                                    <button
                                        type="button"
                                        onClick={() => !isBusy && resetViewToBrowse()}
                                        className={`bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none`}
                                        disabled={isBusy}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => !isBusy && saveClip(editingItem)}
                                        className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                        disabled={isBusy}
                                    >
                                        <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => !isBusy && saveClip(editingItem, true)}
                                        className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                        disabled={isBusy}
                                    >
                                        <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                        Save and exit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Modal className="w-full max-w-6xl max-h-full overflow-y-scroll" isOpen={addProductModalOpen} onClosed={() => alert('onClosed')}>
                <ProductEditForm instanceId={props.instance.id} clientId={props.client.id} item={newProductEditingItem} view={'createProductView'} hideClips={true} onSaved={(item) => newProductAdded(item)} onDeleted={(item) => props.deleteProduct(item)} onDone={() => setAddProductModalOpen(false)} />
                {/* <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setAddProductModalOpen(false)}
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} size="lg" className="mr-2" />
                        Add product
                    </button>
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setAddProductModalOpen(false)}
                    >
                        Cancel
                    </button>
                </div> */}

            </Modal>
        </>
    )
}
