import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Item from './Item';

export default (props) => {
    const [searchTerms, setSearchTerms] = useState('');
    const [filteredResults, setFilteredResults] = useState();
    const [selectedCategory, setSelectedCategory] = useState('clips');

    useEffect(() => {
        if (props.client) {
            setSearchTerms('');
        }
    }, [props.client]);

    useEffect(() => {
        if (!searchTerms || searchTerms.trim() === '') {
            setFilteredResults({ clips: props.client.clips, playlists: props.client.playlists, products: props.client.products });
            return;
        }

        const terms = searchTerms.toLowerCase();

        let clips = props.client.clips.filter(x => {
            const str = `${x.name} ${x.description}`.toLowerCase();
            return str.indexOf(terms)> -1;
        });
        let playlists = props.client.playlists.filter(x => {
            const str = `${x.name} ${x.description}`.toLowerCase();
            return str.indexOf(terms)> -1;
        });

        let products = props.client.products.filter(x => {
            const str = `${x.name} ${x.description} ${x.articleNumber}`.toLowerCase();
            return str.indexOf(terms)> -1;
        });

        setFilteredResults( { clips: clips, playlists: playlists, products: products });
    }, [searchTerms]);

    function getClipUrlPrefix() {
        if (props.customChannelUrl) return `/v`;
        return `/v/${props.client.pid}`;
    }

    function getPlaylistUrlPrefix() {
        if (props.customChannelUrl) return `/p`;
        return `/p/${props.client.pid}`;
    }

    function getProdUrlPrefix() {
        if (props.customChannelUrl) return `/prod`;
        return `/prod/${props.client.pid}`;
    }

    return (
        <>

            <div className="flex-shrink-0 flex flex-col space-y-6">
                <div className="relative rounded-md shadow-sm flex-shrink-0">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={['fas', 'search' ]} size="lg" className="howtofy-text-color-inverse opacity-30" />
                    </div>
                    <input
                        type="text"
                        autoFocus
                        value={searchTerms}
                        onChange={(ev) => setSearchTerms(ev.target.value)}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder="Type to search..."
                    />
                </div>
                {filteredResults &&
                    <div className="grid grid-cols-3 relative z-0 inline-flex shadow-sm rounded-md">
                        <button
                            type="button"
                            onClick={() => setSelectedCategory('clips')}
                            className={`${selectedCategory === 'clips' ? 'howtofy-text-color howtofy-background-color' : 'text-gray-700 bg-white '} relative inline-flex items-center justify-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none`}
                        >
                            Videos
                            <span className="text-sm font-light ml-1">({filteredResults.clips.length})</span>
                        </button>
                        <button
                            type="button"
                            onClick={() => setSelectedCategory('playlists')}
                            className={`${selectedCategory === 'playlists' ? 'howtofy-text-color howtofy-background-color' : 'text-gray-700 bg-white '} -ml-px relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none`}
                        >
                            Playlists
                            <span className="text-sm font-light ml-1">({filteredResults.playlists.length})</span>
                        </button>
                        <button
                            type="button"
                            onClick={() => setSelectedCategory('products')}
                            className={`${selectedCategory === 'products' ? 'howtofy-text-color howtofy-background-color' : 'text-gray-700 bg-white '} -ml-px relative inline-flex items-center justify-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none`}
                        >
                            Products
                            <span className="text-sm font-light ml-1">({filteredResults.products.length})</span>
                        </button>
                    </div>
                }
            </div>

            <div className="overflow-y-auto">
                {filteredResults &&
                    <>
                        
                        
                        
                        
                        
                        {selectedCategory === 'clips' &&
                            <ul role="list" className="grid grid-cols-2 gap-3">
                                {filteredResults.clips.map(clip => {
                                    return (
                                        <li key={clip.pid}>
                                            <a href={`${getClipUrlPrefix()}/${clip.pid}`}>
                                                <Item 
                                                    title={clip.name}
                                                    thumbUrl={clip.portraitThumbUrl}
                                                    duration={clip.duration}
                                                    showPlayIcon={true}
                                                    aspect="portrait"
                                                />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>        
                        }






                        {selectedCategory === 'playlists' &&
                            <ul className="space-y-5">
                                {filteredResults.playlists.map(playlist => {
                                    return (
                                        <li key={playlist.pid} className="space-y-2">
                                            <div className="flex items-center space-x-2 relative">
                                                <FontAwesomeIcon icon={['fas', 'clipboard-list' ]} size="lg" className="howtofy-text-color absolute -top-0.5" />
                                                <p className="text-sm uppercase howtofy-text-color pl-4">
                                                    {playlist.name}
                                                </p>
                                            </div>


                                            <div className="overflow-x-scroll">
                                                <ul role="list" className="flex space-x-3 pb-3">
                                                    {playlist.clips.map(clip => {
                                                        return (
                                                            <li key={clip.pid} className="flex">
                                                                <div className="w-36">
                                                                    <a href={`${getPlaylistUrlPrefix()}/${playlist.pid}/${clip.pid}`}>
                                                                        <Item 
                                                                            title={clip.name}
                                                                            thumbUrl={clip.portraitThumbUrl}
                                                                            duration={clip.duration}
                                                                            showPlayIcon={true}
                                                                            aspect="portrait"
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>



                                        </li>
                                    );
                                })}
                            </ul>
                        }





                        {selectedCategory === 'products' &&
                            <ul role="list" className="grid grid-cols-2 gap-3">
                                {filteredResults.products.map(product => {
                                        return (
                                        <li key={product.pid}>
                                            {/* <a href={`/prod/${props.client.pid}/${product.pid}`}> */}
                                            <a target="_blank" href={product.moreInfoUrl ?? product.shopUrl ?? `${getProdUrlPrefix()}/${product.pid}`}>
                                                <Item 
                                                    title={product.name}
                                                    thumbUrl={product.thumbnailUrl}
                                                    showPlayIcon={false}
                                                    aspect="square"
                                                />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>        
                        }
                    </>
                }
            </div>            

        </>
    )
}

