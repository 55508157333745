import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone'; //https://github.com/react-dropzone/react-dropzone
import _ from "lodash";
import Utils from '../Utils/Utils';
import Tabs from './Tabs';
import ImageUpload from './ImageUpload';
import PropertiesEditor from "./PropertiesEditor";

const videoRef = React.createRef();

const tabGeneral = { name: 'General' };
const tabDesign = { name: 'Design' };
const tabContact = { name: 'Contact Information' };
const tabSocialMedia = { name: 'Social Media' };
const tabLegal = { name: 'Legal' };
const tabs = [tabGeneral, tabDesign, tabContact, tabSocialMedia, tabLegal];
const socMediaTypes = ['Instagram', 'LinkedIn', 'Facebook', 'Twitter', 'Pinterest'];

export default (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [editingItem, setEditingItem] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [logo, setLogo] = useState(null);
    //const [hasExistingLogo, setHasExistingLogo] = useState(null);
    const [logoFileRemoved, setLogoFileRemoved] = useState(null);
    const [activeTab, setActiveTab] = useState(tabGeneral);
    const [isBusy, setIsBusy] = useState(false);
    const [heroVideoFile, setHeroVideoFile] = useState(null);
    const [heroVideoFileRemoved, setHeroVideoFileRemoved] = useState(false);
    const [hasExistingHeroVideo, setHasExistingHeroVideo] = useState(false);
    const [heroVideoSource, setHeroVideoSource] = useState('');    
        
    const [socMediaChannels, setSocMediaChannels] = useState([]);
    const [newSocMediaChannelType, setNewSocMediaChannelType] = useState('');
    const [newSocMediaChannelName, setNewSocMediaChannelName] = useState('');
    const [newSocMediaChannelUrl, setNewSocMediaChannelUrl] = useState('');

    
    
    useEffect(() => {
        if (props?.item) {
            if (props.item.id) {
                setEditingItem(props.item);
                setSocMediaChannels(props.item.socMediaChannels ?? []);

                async function loadExistingLogo() {
                    setLogo(await loadAsDataUrl(props.item.existingLogoUrl));
                }
                if (props.item.existingLogoUrl) {
                    loadExistingLogo();
                }

                if (props.item.existingHeroVideoUrl) {
                    setHeroVideoSource(props.item.existingHeroVideoUrl);
                    setHasExistingHeroVideo(true);
                }
            } else {
                loadNewClientTemplate();
            }
        }
    }, []);

    function loadNewClientTemplate() {
        Utils.loadNewClientTemplate(props.instance.id).then(response => response.json().then(async data => {
            setEditingItem(data);
        }));
    }


    async function loadAsDataUrl(url) {
        let blob = await fetch(url).then(r => r.blob());
        let dataUrl = await new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
        return dataUrl;
    }

    useEffect(() => {
        if(logoFile) {
            const reader = new FileReader();
            reader.addEventListener("load", function() {
                setLogo(reader.result);
            });
            reader.readAsDataURL(logoFile);
        } else {
            setLogo(null);
        }
    }, [logoFile]);

    useEffect(() => {
        if(heroVideoFile) {
            setHeroVideoSource(URL.createObjectURL(heroVideoFile));
            Utils.getClientHeroVideoUploadUrl(props.instance.id, editingItem.id).then(response => response.json().then(async data => {
                editingItem.uploadedVideoFilename = data.filename;
                props.onUploadVideo(editingItem.id.replace(/_new_/, ''), heroVideoFile, data);
            }));

        } 
        // else {
        //     setHeroVideoSource('');
        // }
    }, [heroVideoFile]);



    useEffect(() => {
        if(editingItem) {
            editingItem.logoFileRemoved = logoFileRemoved;
        }
    }, [logoFileRemoved]);

    useEffect(() => {
        if(editingItem) {
            editingItem.socMediaChannels = socMediaChannels;
        }
    }, [socMediaChannels]);



    function saveClient(client, saveAndExit = false) {
        if (client.name.trim() == '') {
            alert("Please provide a name for the client.");
            return;
        }

        if (client.customUrl.trim() == '') {
            alert("Please provide a url for the client.");
            return;
        }

        const isCreating = !client?.id || client?.id.indexOf("_new_") === 0;
        const url = !isCreating ? Utils.getClientUrl(props.instance.id, client.id) : Utils.getClientsUrl(props.instance.id);

        debugger;
        let model = Utils.sanitizeObject(client);

        let data = new FormData();
        data.append("json", JSON.stringify(model));
        if (logoFile) data.append("logo", logoFile); 
        //if (heroVideoFile) data.append("herovideo", heroVideoFile); 

        setIsBusy(true);
        fetch(url, {
            method: !isCreating ? 'PUT' : 'POST',
            body: data
        }).then(response => response.json()).then(response => {
            setIsBusy(false);
            props.onSaved(response, saveAndExit);
        });

        // const request = {
        //     method: client.id ? 'PUT' : 'POST',
        //     headers: {
        //       'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(client)
        // }
        // fetch(url, request).then(response => response.json().then(data => {
        //     props.onSaved(data);
        // }));
    }

    function deleteClient(client) {
        if (window.confirm("Are you sure?")) {
            fetch(Utils.getClientUrl(props.instance.id, client.id), Utils.createRequest('DELETE')).then(() => {
                props.onDeleted(client);
            });
        }
    }

    function resetViewToBrowse() {
        props.onDone();
    }

    function updateEditingItem(field, value) {
        let obj = editingItem;
        if (field.indexOf('.') === -1) {
            editingItem[field] = value;
        } else {
            let fields = field.split('.');
            let existTest = obj;
            fields.map(x => {
                if (existTest[x] === undefined) existTest[x] = {};
                existTest = existTest[x];
            });
            const lastField = fields[fields.length - 1];
            fields = _.take(fields, fields.length - 1);
            fields.map(x => obj = obj[x]);
            obj[lastField] = value;
        }
        forceUpdate();
    }

    function addNewSocMediaChannel() {
        if (!newSocMediaChannelName) { alert("Name is required."); return; }
        if (!newSocMediaChannelUrl) { alert("Url is required."); return; }

        let smc = [...socMediaChannels];
        smc.push({ type: newSocMediaChannelType, name: newSocMediaChannelName, url: newSocMediaChannelUrl });
        setSocMediaChannels(smc);

        setNewSocMediaChannelType('');
        setNewSocMediaChannelName('');
        setNewSocMediaChannelUrl('');
    }

    function setSocMediaChannelDetails(channel, type, name, url) {
        const idx = socMediaChannels.indexOf(channel);
        if (idx !== -1) {
            let smc = _.cloneDeep(socMediaChannels[idx]);

            smc.type = type ?? smc.type;
            smc.name = name ?? smc.name;
            smc.url = url ?? smc.url;

            let channelsCopy = [...socMediaChannels];
            channelsCopy.splice(idx, 1, smc);
            setSocMediaChannels(channelsCopy);
        }
    }

    function deleteSocMediaChannel(channel) {
        const idx = socMediaChannels.indexOf(channel);
        if (idx !== -1) {
            let channelsCopy = [...socMediaChannels];
            channelsCopy.splice(idx, 1);
            setSocMediaChannels(channelsCopy);
        }
    }





    return (
        <div className="py-6 bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">{props.view === 'createClientView' ? "Create client" : "Edit client"}</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-5">

                <Tabs tabs={tabs} activeTab={activeTab} onSetActiveTab={(tab) => setActiveTab(tab)} />

                <form className="space-y-8  sm:space-y-5 divide-y divide-gray-200" onSubmit={(ev) => { ev.preventDefault(); return false; }}>
                    {activeTab === tabGeneral &&
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="clientname" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Client name
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="clientname"
                                            id="clientname"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.name ?? ''}
                                            onChange={e => updateEditingItem('name', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="clientname" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Client culture (2 letter ISO code)
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="clientculture"
                                            id="clientculture"
                                            maxLength="2"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.culture ?? ''}
                                            onChange={e => updateEditingItem('culture', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="clientname" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Custom domain
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="clientname"
                                            id="clientname"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.customDomain ?? ''}
                                            onChange={e => updateEditingItem('customDomain', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="customurl" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Custom URL
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="text"
                                        name="customurl"
                                        id="customurl"
                                        className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                        value={editingItem?.customUrl ?? ''}
                                        onChange={e => updateEditingItem('customUrl', e.target.value) }
                                    />
                                </div>
                            </div>
                            
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="customurl" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Properties
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <PropertiesEditor keyvalues={editingItem?.properties} onUpdated={(keyvalues) => editingItem && updateEditingItem('properties', keyvalues)} />
                                </div>
                            </div>
                            
                        </div>
                    }

                    {activeTab === tabDesign &&
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="logo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Logo
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2 bg-white">
                                    <ImageUpload image={logo} 
                                        onSetImage={(img) => setLogo(img)} 
                                        onSetImageFileRemoved={() => setLogoFileRemoved()} 
                                        onSetImageFile={(file) => setLogoFile(file)}
                                    />
                                    
                                    {/* <div className="shadow border border-gray-200 sm:rounded-lg w-full">
                                        <div className="">
                                            <img src={logo} className="object-cover mx-auto max-h-48" alt="" />
                                        </div>

                                        {logo &&
                                            <button
                                                type="button"
                                                onClick={() => { setLogo(null); setLogoFileRemoved(true); }}
                                                className="block mt-2 mx-auto bg-white py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none"
                                            >
                                                <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                                Remove uploaded file
                                            </button>
                                        }

                                        <Dropzone onDrop={(files) => setLogoFile(files[0])}>
                                            {({getRootProps, getInputProps}) => (
                                                <div {...getRootProps()} className="flex justify-center px-6 pt-5 pb-6 m-3 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <FontAwesomeIcon icon={['fas', 'image']} size="2x" className="text-gray-400" />
                                                        <div className="flex text-sm text-gray-600">
                                                            <label
                                                                className="relative bg-white rounded-md"
                                                            >
                                                                <span className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload an image file</span>
                                                                <span className="pl-1">or drag and drop</span>
                                                            </label>
                                                            
                                                        </div>
                                                        <p className="text-xs text-gray-500">PNG, JPG, GIF or SVG</p>
                                                    </div>                                                            
                                                    <input {...getInputProps()} id="landscape-thumb-upload" name="landscape-thumb-upload" type="file" accept=".gif,.jpg,.jpeg,.png,.svg" className="sr-only" />
                                                </div>                                                    
                                            )}                                            
                                        </Dropzone>
                                    </div> */}
                                </div>
                            </div>



                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Hero video
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">



                                    <div className="grid grid-flow-row grid-cols-2 gap-4">
                                        <div className="shadow border border-gray-200 sm:rounded-lg w-full bg-white">
                                            <div className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                                Upload video
                                            </div>

                                            <Dropzone onDrop={(files) => setHeroVideoFile(files[0])}>
                                                {({getRootProps, getInputProps}) => (
                                                    <div {...getRootProps()} className="flex justify-center px-6 pt-5 pb-6 m-3 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <FontAwesomeIcon icon={['fas', 'file-video']} size="2x" className="text-gray-400" />
                                                            <div className="flex text-sm text-gray-600">
                                                                <label
                                                                    // htmlFor="video-upload"
                                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                >
                                                                    <span>Upload a video file</span>
                                                                </label>
                                                                <input {...getInputProps()} id="video-upload" name="video-upload" type="file" accept="video/*" className="sr-only" />
                                                                <p className="pl-1">or drag and drop</p>
                                                            </div>
                                                            <p className="text-xs text-gray-500">MP4 or WEBM</p>
                                                        </div>
                                                    </div>                                                    
                                                )}                                            
                                            </Dropzone>

                                            {(heroVideoFile || hasExistingHeroVideo) &&
                                                <button
                                                    type="button"
                                                    onClick={() => { setHasExistingHeroVideo(false); setHeroVideoFile(null); setHeroVideoFileRemoved(true); setHeroVideoSource(''); }}
                                                    className="block mt-2 mx-auto bg-white py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 hover:text-white hover:bg-red-700 focus:outline-none"
                                                >
                                                    <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                                    Remove uploaded file
                                                </button>
                                            }
                                        </div>
                                        <div className="shadow border border-gray-200 sm:rounded-lg w-full flex flex-col">
                                            <div className="bg-white px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 sm:rounded-t-lg">
                                                Video preview
                                            </div>
                                            <video ref={videoRef} id="video" controls="controls" crossOrigin="anonymous" className="sm:rounded-b-lg max-h-96" src={heroVideoSource}>
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>                            

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="channelpageheadline" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Channel page headline
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="channelpageheadline"
                                            id="channelpageheadline"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.channelPageHeadline ?? ''}
                                            onChange={e => updateEditingItem('channelPageHeadline', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="channelpagesubhead" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Channel page subhead
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="channelpagesubhead"
                                            id="channelpagesubhead"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.channelPageSubhead ?? ''}
                                            onChange={e => updateEditingItem('channelPageSubhead', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="textcolor" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Primary foreground color (texts)
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="color"
                                        id="textcolor"
                                        name="textcolor"
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.textColor ?? ''}
                                        onChange={e => updateEditingItem('textColor', e.target.value) }
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="secondarytextcolor" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Secondary foreground color (headlines, buttons, ...)
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="color"
                                        id="secondarytextcolor"
                                        name="secondarytextcolor"
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.secondaryTextColor ?? ''}
                                        onChange={e => updateEditingItem('secondaryTextColor', e.target.value) }
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="backgroundcolor" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Background color
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="color"
                                        id="backgroundcolor"
                                        name="backgroundcolor"
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.backgroundColor ?? ''}
                                        onChange={e => updateEditingItem('backgroundColor', e.target.value) }
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="bannerbackgroundcolor" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Banner background color
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="color"
                                        id="bannerbackgroundcolor"
                                        name="bannerbackgroundcolor"
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.bannerBackgroundColor ?? ''}
                                        onChange={e => updateEditingItem('bannerBackgroundColor', e.target.value) }
                                    />
                                </div>
                            </div>




                        </div>
                    }

                    {activeTab === tabContact &&
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Address
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        id="address"
                                        name="address"
                                        rows={3}
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.contact?.address ?? ''}
                                        onChange={e => updateEditingItem('contact.address', e.target.value) }
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="url" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Website
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="url"
                                            name="url"
                                            id="url"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.contact?.url ?? ''}
                                            onChange={e => updateEditingItem('contact.url', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Email
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.contact?.email ?? ''}
                                            onChange={e => updateEditingItem('contact.email', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Phone
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="tel"
                                            name="phone"
                                            id="phone"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.contact?.phone ?? ''}
                                            onChange={e => updateEditingItem('contact.phone', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    }

                    {activeTab === tabSocialMedia &&
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">


                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Social media channels
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Social media
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Name
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Url
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {socMediaChannels.map((channel, index) => (
                                                <tr key={index}>
                                                    <td className="px-6 py-2 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <select
                                                                className="block w-full pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                                value={channel.type}
                                                                onChange={e => setSocMediaChannelDetails(channel, e.target.value) }
                                                            >
                                                                {socMediaTypes.map(x => 
                                                                    <option key={x}>{x}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td className="pl-6 py-2 whitespace-nowrap">
                                                        <input
                                                            type="text"
                                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                            value={channel.name}
                                                            onChange={e => setSocMediaChannelDetails(channel, null, e.target.value) }
                                                        />
                                                    </td>
                                                    <td className="pl-6 py-2 whitespace-nowrap">
                                                        <input
                                                            type="url"
                                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                            value={channel.url}
                                                            onChange={e => setSocMediaChannelDetails(channel, null, null, e.target.value) }
                                                        />
                                                    </td>
                                                    <td className="pr-6 py-2 whitespace-nowrap text-right">
                                                        <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" onClick={() => deleteSocMediaChannel(channel)} className="text-red-600 cursor-pointer" />
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <div className="flex sm:col-span-2">
                                            <div className="relative flex items-stretch flex-grow focus-within:z-10 border-t border-gray-300 p-3 space-x-3">
                                                <div className="flex items-center">
                                                    <select
                                                        className="block pl-3 pr-10 py-2 text-base border-none sm:text-sm h-full sm:rounded-bl-md"
                                                        value={newSocMediaChannelType}
                                                        onChange={(ev) => { setNewSocMediaChannelType(ev.target.value); setNewSocMediaChannelName(ev.target.value); } }
                                                    >
                                                        <option value="" disabled>Type</option>
                                                        {socMediaTypes.map(x => 
                                                            <option key={x}>{x}</option>
                                                        )}
                                                    </select>
                                                </div>

                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    className="block w-full sm:text-sm border-none"
                                                    value={newSocMediaChannelName}
                                                    onChange={e => setNewSocMediaChannelName(e.target.value) }
                                                />
                                                <input
                                                    type="url"
                                                    placeholder="Url"
                                                    className="block w-full sm:text-sm border-none"
                                                    value={newSocMediaChannelUrl}
                                                    onChange={e => setNewSocMediaChannelUrl(e.target.value) }
                                                />
                                            </div>

                                            <button
                                                type="button"
                                                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border-t border-l border-gray-300 text-sm font-medium rounded-br-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                >
                                                <FontAwesomeIcon icon={['fas', 'plus']} size="lg" onClick={() => addNewSocMediaChannel()} className="" />
                                            </button>
                                        </div>




                                    </div>
                                </div>
                            </div>


                            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="instagram" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Instagram
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="url"
                                            name="instagram"
                                            id="instagram"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.socialMedia?.instagram ?? ''}
                                            onChange={e => updateEditingItem('socialMedia.instagram', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    LinkedIn
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="lex rounded-md shadow-sm">
                                        <input
                                            type="url"
                                            name="linkedin"
                                            id="linkedin"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.socialMedia?.linkedIn ?? ''}
                                            onChange={e => updateEditingItem('socialMedia.linkedIn', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="facebook" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Facebook
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="url"
                                            name="facebook"
                                            id="facebook"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.socialMedia?.facebook ?? ''}
                                            onChange={e => updateEditingItem('socialMedia.facebook', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="twitter" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Twitter
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="url"
                                            name="twitter"
                                            id="twitter"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.socialMedia?.twitter ?? ''}
                                            onChange={e => updateEditingItem('socialMedia.twitter', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="pinterest" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Pinterest
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <input
                                            type="url"
                                            name="pinterest"
                                            id="pinterest"
                                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                            value={editingItem?.socialMedia?.pinterest ?? ''}
                                            onChange={e => updateEditingItem('socialMedia.pinterest', e.target.value) }
                                        />
                                    </div>
                                </div>
                            </div>
                             */}
                        </div>
                    }

                    {activeTab === tabLegal &&
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="termsandconditions" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Terms & Conditions
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        id="termsandconditions"
                                        name="termsandconditions"
                                        rows={3}
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.legal?.termsAndConditions ?? ''}
                                        onChange={e => updateEditingItem('legal.termsAndConditions', e.target.value) }
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="cookieterms" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Cookie Terms
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        id="cookieterms"
                                        name="cookieterms"
                                        rows={3}
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.legal?.cookieTerms ?? ''}
                                        onChange={e => updateEditingItem('legal.cookieTerms', e.target.value) }
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="privacyterms" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Privacy Terms
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        id="privacyterms"
                                        name="privacyterms"
                                        rows={3}
                                        className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                                        value={editingItem?.legal?.privacyTerms ?? ''}
                                        onChange={e => updateEditingItem('legal.privacyTerms', e.target.value) }
                                    />
                                </div>
                            </div>
                            
                        </div>
                    }






                        
                    <div className="pt-5">
                        <div className={`flex ${props.view === 'createClientView' ? "justify-end" : "justify-between"}`}>
                            {props.view === 'editClientView' &&
                                <button
                                    type="button"
                                    onClick={() => deleteClient(editingItem)}
                                    className="bg-red-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-700 focus:outline-none"
                                >
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                    Delete
                                </button>
                            }
                            <div className="flex content-start">
                                <button
                                    type="button"
                                    onClick={() => !isBusy && resetViewToBrowse()}
                                    className={`${isBusy ? 'opacity-50' : ''} bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && saveClient(editingItem)}
                                    className={`${isBusy ? 'opacity-50' : ''} ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && saveClient(editingItem, true)}
                                    className={`${isBusy ? 'opacity-50' : ''} ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save and exit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
