import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const logo = require("../images/howtofy_logo_black_RGB.png");
const video = require("../images/hemsida_intro.mp4");

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
        <div className="flex h-screen">
            <div className="m-auto w-screen lg:w-3/4">
                <video autoPlay muted>
                    <source src={video} type="video/mp4" />
                    <img src={logo} className="object-contain object-center w-96" alt="Howtofy" />
                </video>
                <div className="text-center mt-3">
                    <span className="mr-5">
                        <FontAwesomeIcon icon="phone" className="mr-1" /> +46 (0)70 726 32 79
                    </span>
                    <span className="mr-3">
                        <FontAwesomeIcon icon="envelope" className="mr-1" />nicklas@howtofy.se
                    </span>
                </div>
            </div>
        </div>
    );
  }
}
