import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";

import Utils from '../Utils/Utils';

export default (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [editingItem, setEditingItem] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        if (props?.item) setEditingItem(_.cloneDeep(props.item));
        console.log(Intl.getCanonicalLocales());
        debugger;
    }, [props]);


    function saveInstance(instance, saveAndExit = false) {
        if (instance.name.trim() == '') {
            alert("Please provide a name for the instance.");
            return;
        }

        const url = instance.id ? Utils.getInstanceUrl(instance.id) : Utils.getInstancesUrl();
        const request = Utils.createRequest(instance.id ? 'PUT' : 'POST', instance);
        setIsBusy(true);
        fetch(url, request).then(response => response.json().then(data => {
            setIsBusy(false);
            props.onSaved(data, saveAndExit);
        }));
    }

    function deleteInstance(instance) {
        if (window.confirm("Are you sure?")) {
            fetch(Utils.getInstanceUrl(instance.id), Utils.createRequest('DELETE')).then(() => {
                props.onDeleted(instance);
            });
        }
    }

    function resetViewToBrowse() {
        props.onDone();
    }

    function updateEditingItem(field, value) {
        let obj = editingItem;
        if (field.indexOf('.') === -1) {
            editingItem[field] = value;
        } else {
            let fields = field.split('.');
            const lastField = fields[fields.length - 1];
            fields = _.take(fields, fields.length - 1);
            fields.map(x => obj = obj[x]);
            obj[lastField] = value;
        }
        forceUpdate();
    }




    return (
        <div className="py-6 bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">{props.view === 'createInstanceView' ? "Create instance" : "Edit instance"}</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-5">



                <form className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div>
                            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="instancename" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Instance name
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="flex rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                name="instancename"
                                                id="instancename"
                                                className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                value={editingItem?.name}
                                                onChange={e => updateEditingItem('name', e.target.value) }
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className={`flex ${props.view === 'createInstanceView' ? "justify-end" : "justify-between"}`}>
                            {props.view === 'editInstanceView' &&
                                <button
                                    type="button"
                                    onClick={() => deleteInstance(editingItem)}
                                    className="bg-red-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-700 focus:outline-none"
                                >
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']} size="lg" className="mr-2" />
                                    Delete
                                </button>
                            }
                            <div className="flex content-start">
                                <button
                                    type="button"
                                    onClick={() => resetViewToBrowse()}
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && saveInstance(editingItem)}
                                    className={`${isBusy ? 'opacity-50' : ''} ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save
                                </button>
                                <button
                                    type="button"
                                    onClick={() => !isBusy && saveInstance(editingItem, true)}
                                    className={`${isBusy ? 'opacity-50' : ''} ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}
                                    disabled={isBusy}
                                >
                                    <FontAwesomeIcon icon={['fas', isBusy ? 'spinner' : 'save' ]} spin={isBusy} size="lg" className="mr-2" />
                                    Save and exit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
