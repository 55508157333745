import React, { useEffect, useState } from 'react';
export default (props) => {
    // const videoRef = React.useRef(null);
    // const playerRef = React.useRef(null);
    // const [player, setPlayer] = useState(null);
    
    if(!props.videoUrl) return null;

    // useEffect(() => {
    //     if(props.videoUrl) {
    //         if (!playerRef?.current && videoRef?.current && (props.client || props.videoUrl)) {
    //             const videoElement = videoRef.current;
    //             if (!videoElement) return;

    //             const videoJsOptions = {
    //                 autoplay: true,
    //                 controls: false,
    //                 responsive: true,
    //                 fluid: false,
    //                 sources: [{
    //                   src: props.client.streamUrls ? props.client.streamUrls[0] : props.videoUrl,
    //                   type: props.client.streamUrls ? 'application/x-mpegURL' : 'video/mp4'
    //                 }]
    //               }
                
    //             playerRef.current = videojs(videoElement, videoJsOptions, function() {
    //             });
    //             setPlayer(playerRef.current);                
    //         }            
    //     }
    // }, [props.videoUrl, props.client, videoRef]);

    // useEffect(() => {
    //     const player = playerRef.current;

    //     return () => {
    //     if (player) {
    //         player.dispose();
    //         playerRef.current = null;
    //     }
    //     };
    // }, [playerRef]);

    return (
        <div className="howtofy-background-color howtofy-banner-background-color w-full h-full">
            <video className={`object-cover w-full h-full opacity-fade-1500 ${props.faded ? 'opacity-0' : ''}`} playsInline autoPlay muted loop crossOrigin="anonymous" poster={props.posterUrl ?? ''}>
                <source src={props.videoUrl} />
            </video>
        </div>
        // <video ref={videoRef} className="object-cover w-full h-full" />
    )
}